import React, { ChangeEvent, useState } from 'react'
import { FlexContainer } from 'static'
import styled from 'styled-components'
import { TContainer } from 'types'
import { Checkbox, TextField } from '@rhythm/inputs'
import { ButtonGroup, Button, IconButton } from '@rhythm/buttons'
import { MinimalAlert } from '@rhythm/notifications'
import { LinkIcon, UnlinkIcon } from '@rhythm/svgs'
import { Tooltip } from '@rhythm/tooltips'
import { messageActions } from 'actions'
import { getFontWeight, getColor } from '@rhythm/theme'
import { useDispatch, useSelector } from 'react-redux'
import { cadenceQuestionsFetchingSelector, cadenceFetchingSelector } from 'selectors'

interface IProps {
  body: TContainer
  footer: TContainer
  onClose: () => void
  onApply: (
    answer: {
      name: string
      link: string | null
      id?: number
    },
    saveToLib: boolean
  ) => Promise<unknown>
  isMultiple?: boolean
  answer?: {
    id?: number
    name: string
    link: string | null
  }
  isReplace?: boolean
}

const Label = styled.div`
  padding: 0.4rem 1rem 0.4rem 0rem;
  font-size: 0.9rem;
  font-weight: ${getFontWeight('semibold')};
  color: ${getColor('greyDark')};
  &.link {
    padding-left: 1.355rem;
  }
`

const ModalContainer = styled(FlexContainer)`
  & > div:not(:last-child) {
    margin-bottom: 1.25rem;
  }
`

const AlertContainer = styled.div`
  margin-bottom: 0.9rem;
`

const CheckBoxContainer = styled(FlexContainer)`
  margin-left: 3.85rem;
`

const defaultAnswer = {
  name: '',
  link: '',
}

const CadenceAnswerAddModal = ({
  body: Body,
  footer: Footer,
  onClose,
  onApply,
  isReplace,
  isMultiple,
  answer: { name, link, id } = defaultAnswer,
}: IProps) => {
  const dispatch = useDispatch()
  const [saveToLib, setSaveToLib] = useState(true)
  const [showLink, setShowLink] = useState(!!link)
  const cadenceQuestionsFetching = useSelector(cadenceQuestionsFetchingSelector)
  const cadenceFetching = useSelector(cadenceFetchingSelector)
  const [answer, setAnswer] = useState({
    name,
    link,
  })
  const [isNameTaken, setIsNameTaken] = useState(false)
  const isChanged = link !== answer.link || name !== answer.name
  const applyFunc = (close?: boolean) =>
    onApply(
      {
        id,
        ...answer,
      },
      id ? isChanged && saveToLib : saveToLib
    )
      .then(() => close && onClose())
      .catch((error: Error) => {
        if (error.message === 'This name is already taken') {
          dispatch(messageActions.delete())
          setIsNameTaken(true)
        } else {
          onClose()
        }
      })
  const changeAnswer = (field: 'name' | 'link' = 'name') => ({
    currentTarget: { value },
  }: React.FormEvent<HTMLInputElement>) => {
    setAnswer((prevState) => ({
      ...prevState,
      [field]: value,
    }))
    setIsNameTaken(false)
  }
  const changeSaveToLib = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
    setSaveToLib(checked)

  return (
    <>
      <Body>
        <ModalContainer column>
          {isReplace && (
            <AlertContainer>
              <MinimalAlert
                title="Selected Answer will be replaced with Added one"
                variant="warning"
              />
            </AlertContainer>
          )}
          <FlexContainer alignItems="flex-start" justifyContent="space-between">
            <Label>Answer</Label>
            <TextField
              placeholder="Answer"
              value={answer.name}
              onChange={changeAnswer('name')}
              minLength={3}
              maxLength={200}
              width="23rem"
              hasError={isNameTaken}
              errorMessage="This name is already taken"
              data-testid="inpAnswer"
            />
            <Tooltip maxWidth="100%" isInline title={showLink ? 'Unlink' : 'Add Link'}>
              <IconButton
                icon={showLink ? <UnlinkIcon /> : <LinkIcon />}
                variant="neutral"
                appearance="ghost"
                data-testid="btnShowLink"
                onClick={() => {
                  setAnswer((prevState) => ({
                    ...prevState,
                    link: '',
                  }))
                  setShowLink(!showLink)
                }}
                aria-label="link and unlink button"
              />
            </Tooltip>
          </FlexContainer>
          <>
            {showLink && (
              <FlexContainer alignItems="flex-start">
                <Label className="link">URL</Label>
                <TextField
                  placeholder="http://"
                  value={answer.link}
                  onChange={changeAnswer('link')}
                  minLength={1}
                  maxLength={2100}
                  width="23rem"
                  data-testid="inpLink"
                />
              </FlexContainer>
            )}
          </>
          <CheckBoxContainer>
            <Checkbox disabled={id && !isChanged} checked={saveToLib} onChange={changeSaveToLib}>
              Add to Library
            </Checkbox>
          </CheckBoxContainer>
        </ModalContainer>
      </Body>
      <Footer>
        <ButtonGroup>
          <Button
            variant="neutral"
            onClick={onClose}
            data-testid="btnCancel"
            loading={cadenceQuestionsFetching || cadenceFetching}
          >
            Cancel
          </Button>
          <Button
            disabled={(!id && !isChanged) || answer.name.length === 0}
            onClick={() => applyFunc(true)}
            loading={cadenceQuestionsFetching || cadenceFetching}
            data-testid="btnAddAnswer"
          >
            {isReplace ? 'Replace Answer' : 'Add Answer'}
          </Button>
          {isMultiple && (
            <Button
              onClick={() => {
                setAnswer(defaultAnswer)
                applyFunc()
              }}
              loading={cadenceQuestionsFetching || cadenceFetching}
              disabled={!isChanged || answer.name.length === 0}
            >
              Add & Create Another
            </Button>
          )}
        </ButtonGroup>
      </Footer>
    </>
  )
}

export default CadenceAnswerAddModal
