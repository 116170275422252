import React, { useState } from 'react'
import styled from 'styled-components'
import { getColor, getFontWeight } from '@rhythm/theme'
import { Radio, TextInput } from '@rhythm/inputs'
import { FlexContainer } from 'static'

const CUSTOM_DURATION_COEFFICIENT = 0.6

const durationOptions = [
  { name: 'Shorter' },
  { name: 'Recommended' },
  { name: 'Longer' },
  { name: 'Custom' },
]

const intensityOptions = [
  { name: 'Lighter' },
  { name: 'Recommended' },
  { name: 'Heavier' },
  { name: 'Custom' },
]

const durationInputField = [{ title: 'Days', value: 'days' }]

const intensityInputField = [
  { title: 'Emails', value: 'emails' },
  { title: 'Calls', value: 'phones' },
  { title: 'LinkedIn', value: 'socials' },
]

const Container = styled.div`
  height: 260px;
  width: 380px;
  border: 1px solid ${getColor('greyLight')};
  border-radius: 2px;
  background-color: ${getColor('white')};
`

const ContainerTitle = styled(FlexContainer)`
  height: 56px;
  box-shadow: inset 0 -1px 0 0 ${getColor('greyLight')};
  color: ${getColor('greyDark')};
  font-size: 18px;
  font-weight: ${getFontWeight('semibold')};
  line-height: 22px;
`

const RadioWrapper = styled.div`
  margin-top: 27px;
`

const RadioOption = styled(Radio)`
  margin-bottom: 16px;
  margin-left: 28px;
  color: ${getColor('greyDark')};
`

const ConstraintsWrapper = styled(FlexContainer)`
  flex-direction: column;
  margin-top: 2px;
`

const Constraint = styled.span<{ isError: boolean }>`
  color: ${({ isError }) => (isError ? getColor('red') : getColor('greyDark'))};
  font-size: 12px;
  line-height: 16px;
  margin-left: 28px;
`

const TextInputContainer = styled(FlexContainer)`
  margin: 18px 0 0 auto;

  &:first-child {
    margin-top: 0;
  }
`

const TextInputsWrapper = styled.div<{ isDuration: boolean }>`
  margin: ${({ isDuration }) => (isDuration ? '19px' : '13px')} 38px 0 auto;
`

const TextInputTitle = styled.span`
  color: ${getColor('greyDark')};
  font-size: 14px;
  margin-right: 8px;
`

interface IProps {
  isDuration: boolean
  numberOfDays: number | string
  intensity: Record<string, string | number>
  sumOfTouches: number
  setNumberOfDays(numberOfDays: number | string): void
  setTouches(touches: number): void
  setIntensity(intensity: unknown): void
  handleChangeRadioOption(e: React.ChangeEvent<HTMLInputElement>): void
}

const DurationAndIntensityForm = ({
  isDuration,
  handleChangeRadioOption,
  numberOfDays,
  intensity,
  setNumberOfDays,
  setTouches,
  setIntensity,
  sumOfTouches,
}: IProps) => {
  const [selectedOption, setSelectedOption] = useState<string>('Recommended')
  const [options, inputFields] = isDuration
    ? [durationOptions, durationInputField]
    : [intensityOptions, intensityInputField]

  const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value)
    handleChangeRadioOption(e)
  }

  const handleChangeInput = (type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(e.target.value)
    const changedValue = e.target.value.length === 0 ? e.target.value : inputValue
    if (inputValue >= 0 && e.target.value.length <= 3) {
      if (isDuration) {
        setTouches(Math.ceil(CUSTOM_DURATION_COEFFICIENT * inputValue))
        setNumberOfDays(changedValue)
        return
      }
      setIntensity((prevState: Record<string, string>) => ({
        ...prevState,
        [type]: changedValue,
      }))
    }
  }

  const isInputHasError = (value: string) =>
    isDuration
      ? numberOfDays > 366 || numberOfDays < 1
      : !Number.isInteger(intensity[value]) || sumOfTouches > 330 || sumOfTouches < 1

  return (
    <Container>
      <ContainerTitle alignItems="center" justifyContent="center">
        {isDuration ? 'Duration' : 'Intensity'}
      </ContainerTitle>
      <FlexContainer>
        <RadioWrapper>
          {options.map((option) => (
            <RadioOption
              key={option.name}
              checked={selectedOption === option.name}
              onChange={handleChangeRadio}
              value={option.name}
            >
              {option.name}
            </RadioOption>
          ))}
        </RadioWrapper>
        <TextInputsWrapper isDuration={isDuration}>
          {inputFields.map((e) => (
            <TextInputContainer alignItems="baseline" justifyContent="flex-end" key={e.title}>
              <TextInputTitle>{e.title}</TextInputTitle>
              <TextInput
                disabled={selectedOption !== 'Custom'}
                onChange={handleChangeInput(e.value)}
                value={isDuration ? numberOfDays : intensity[e.value]}
                variant={isInputHasError(e.value) ? 'danger' : 'primary'}
                width="52px"
              />
            </TextInputContainer>
          ))}
        </TextInputsWrapper>
      </FlexContainer>
      <ConstraintsWrapper>
        <Constraint isError={isDuration ? numberOfDays < 1 : sumOfTouches === 0}>
          {isDuration ? '*Minimum duration is 1 day' : '*The sum of touches should not be 0'}
        </Constraint>
        <Constraint isError={isDuration ? numberOfDays > 366 : sumOfTouches > 330}>
          {isDuration
            ? '*Maximum duration is 366 days'
            : '*The sum of touches should not exceed 330'}
        </Constraint>
      </ConstraintsWrapper>
    </Container>
  )
}

export default DurationAndIntensityForm
