import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getColor, getFontWeight } from '@rhythm/theme'
import { H5, Body2 } from '@rhythm/typography'
import { Checkbox } from '@rhythm/inputs'
import { ExclamationCircleIcon } from '@rhythm/svgs'
import { ContainerWithMenu, Loader, FooterButtons } from 'components'
import { UserMenu } from 'containers'
import { FlexContainer } from 'static'
import { IMotion } from 'types'
import { useSelector } from 'react-redux'
import { cadenceFetchingSelector } from 'selectors'

import DurationAndIntensityForm from './DurationAndIntensityForm'

const DESCRIPTION =
  'We have preselected the recommended duration, intensity, and additional options given your motion choicies.'

const MESSAGING_DESCRIPTION =
  "We will ask you a series of questions so that you don't have to write paragraphs or even complete sentences. We will use your answers to develop messaging for your team that is unique, relevant, and effective. And, we will save your answers so that you can re-use them when you create additional cadences in the future."

const Title = styled(H5)`
  padding: 1.4rem 0rem 1rem 2.25rem;
  color: ${getColor('greyDarkest')};
  line-height: 1.15rem;
  margin: 0;
  box-shadow: inset 0 -1px 0 0 ${getColor('greyLightest')};
`

const Description = styled(Body2)`
  line-height: 16px;
  padding: 8px 0 8px 36px;
`

const BlockWrapper = styled(FlexContainer)`
  margin-left: 36px;
  flex-wrap: wrap;
  gap: 30px;
`

const MessagingWrapper = styled.div<{ isDisabled: boolean }>`
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  margin-left: 36px;
`

const MessagingTitle = styled(H5)`
  margin-top: 45px;
  margin-bottom: 18px;
  line-height: 24px;
`

const MessagingDescription = styled.div`
  max-width: 780px;
  color: ${getColor('greyDark')};
  font-size: 16px;
  line-height: 19px;
`

const MessagingCheckboxContainer = styled.div`
  margin-top: 2px;
`

const MessagingCheckboxLabel = styled(Body2)`
  white-space: normal;
`

const WarningContainer = styled(FlexContainer)`
  margin-bottom: 24px;
`

const WarningIcon = styled(ExclamationCircleIcon)`
  color: ${getColor('orangeDark')};
  height: 15px;
  min-width: 15px;
  margin-left: 36px;
`

const WarningMessage = styled.div`
  color: ${getColor('greyDark')};
  font-size: 16px;
  font-weight: ${getFontWeight('semibold')};
  line-height: 16px;
  margin-left: 5px;
`

function durationOptionsCoefficients(option: string) {
  let durationCoef = 1
  let touchesCoef = 1
  if (option === 'Shorter') {
    durationCoef = 0.5
    touchesCoef = 2 / 3
  }
  if (option === 'Longer') {
    durationCoef = 1.5
    touchesCoef = 5 / 3
  }
  return {
    durationCoef,
    touchesCoef,
  }
}

function intensityOptionsCoefficients(option: string) {
  let intensityCoef = 1
  if (option === 'Lighter') {
    intensityCoef = 0.5
  }
  if (option === 'Heavier') {
    intensityCoef = 1.5
  }
  return intensityCoef
}

export interface IProps {
  selectedMotion: IMotion | null
  handleRedirect(
    numberOfDays?: number,
    intensity?: Record<string, string | number>,
    isMessagingSelected?: boolean
  ): () => void
}

const DurationAndIntensityPage = ({ selectedMotion, handleRedirect }: IProps) => {
  const [isMessagingSelected, setMessagingSelected] = useState<boolean>(true)
  const [numberOfDays, setNumberOfDays] = useState<number | string>(0)
  const [intensity, setIntensity] = useState({
    emails: 0,
    phones: 0,
    socials: 0,
  })
  const cadenceFetching = useSelector(cadenceFetchingSelector)
  const [touches, setTouches] = useState<number>(0)
  const [intensityTypeCoef, setIntensityTypeValue] = useState<number>(1)
  const [isProcceedDisabled, setProceedDisabled] = useState<boolean>(false)
  const [isIntensityCustom, setIntensityCustom] = useState<boolean>(false)

  const sumOfTouches =
    Number(intensity.phones) + Number(intensity.emails) + Number(intensity.socials)

  useEffect(() => {
    if (selectedMotion) {
      setNumberOfDays(selectedMotion.duration)
      setTouches(selectedMotion.touches)
    }
  }, [selectedMotion])

  const updateIntensity = (intensityCoef: number) => {
    if (selectedMotion) {
      const [emailsCoef, , socialsCoef] = selectedMotion.emails_phones_socials
        .split('%/')
        .map(Number)
      if (!isIntensityCustom) {
        const touchesTypeValue = touches * intensityCoef
        const emails = Math.round(touchesTypeValue * emailsCoef)
        const socials = Math.round(touchesTypeValue * socialsCoef)
        const phones = Math.round(touchesTypeValue - socials - emails)
        setIntensity({
          emails,
          socials,
          phones: phones < 0 ? 0 : phones,
        })
      }
    }
  }

  useEffect(() => {
    updateIntensity(intensityTypeCoef)
  }, [touches, isIntensityCustom])

  useEffect(() => {
    setProceedDisabled(
      sumOfTouches > 330 ||
        sumOfTouches === 0 ||
        !Number.isInteger(intensity.emails) ||
        !Number.isInteger(intensity.phones) ||
        !Number.isInteger(intensity.socials) ||
        numberOfDays > 366 ||
        numberOfDays < 1
    )
  }, [intensity])

  function handleChangeRadioOptionDuration(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value !== 'Custom' && selectedMotion) {
      setNumberOfDays(
        Math.round(
          selectedMotion.duration * durationOptionsCoefficients(e.target.value).durationCoef
        )
      )
      setTouches(
        Math.round(selectedMotion.touches * durationOptionsCoefficients(e.target.value).touchesCoef)
      )
    }
  }

  function handleChangeRadioOptionIntensity(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value === 'Custom') {
      setIntensityCustom(true)
      return
    }
    setIntensityCustom(false)
    setIntensityTypeValue(intensityOptionsCoefficients(e.target.value))
    updateIntensity(intensityOptionsCoefficients(e.target.value))
  }

  if (!selectedMotion) {
    return <Loader />
  }

  return (
    <>
      <ContainerWithMenu menu={UserMenu}>
        <Title>Choose duration and intensity</Title>
        <Description>{DESCRIPTION}</Description>
        <WarningContainer>
          <WarningIcon />
          <WarningMessage>
            Be aware that the cadence with more than 50 touches (Intensity) will not be exported to
            Salesloft.
          </WarningMessage>
        </WarningContainer>
        <BlockWrapper>
          <DurationAndIntensityForm
            isDuration
            handleChangeRadioOption={handleChangeRadioOptionDuration}
            numberOfDays={numberOfDays}
            intensity={intensity}
            setNumberOfDays={setNumberOfDays}
            sumOfTouches={sumOfTouches}
            setTouches={setTouches}
            setIntensity={setIntensity}
          />
          <DurationAndIntensityForm
            isDuration={false}
            handleChangeRadioOption={handleChangeRadioOptionIntensity}
            numberOfDays={numberOfDays}
            intensity={intensity}
            setNumberOfDays={setNumberOfDays}
            sumOfTouches={sumOfTouches}
            setTouches={setTouches}
            setIntensity={setIntensity}
          />
        </BlockWrapper>
        <MessagingWrapper isDisabled={!intensity.emails}>
          <MessagingTitle>Messaging</MessagingTitle>
          <MessagingDescription>{MESSAGING_DESCRIPTION}</MessagingDescription>
          <MessagingCheckboxContainer>
            <Checkbox
              data-testid="messaging-checkbox"
              disabled={!intensity.emails}
              checked={!!intensity.emails && isMessagingSelected}
              onChange={() => setMessagingSelected(!isMessagingSelected)}
            >
              <MessagingCheckboxLabel>
                Help me build unique, relevant, and effective messaging (recommended)
              </MessagingCheckboxLabel>
            </Checkbox>
          </MessagingCheckboxContainer>
        </MessagingWrapper>
      </ContainerWithMenu>
      <FooterButtons
        handleBack={handleRedirect()}
        handleProceed={handleRedirect(Number(numberOfDays), intensity, isMessagingSelected)}
        isProcceedDisabled={isProcceedDisabled}
        loading={cadenceFetching}
      />
    </>
  )
}

export default DurationAndIntensityPage
