import { dynamicTagsActions } from 'actions'
import { IButtonApi, IDynamicTag, IEditor } from 'types'
import { Dispatch } from 'redux'
import { DynamicTagsIcon } from 'static'

type EditorCallback = (items: Array<{ type: string; text: string; onAction: () => void }>) => void

export const generateMenuItem = (editor: IEditor, tag: string) => ({
  type: 'menuitem',
  text: tag,
  onAction: () => editor.insertContent(tag),
})

export const getDynamicTags = (
  editor: IEditor,
  dispatch: Dispatch,
  callback: EditorCallback,
  company: string,
  cadenceId?: number
) => {
  dispatch(
    dynamicTagsActions.request({
      data: { company, cadenceId },
      successFunc: (tags: IDynamicTag[]) => {
        const items = tags.map((tag: IDynamicTag) => generateMenuItem(editor, tag.name))
        callback(items)
      },
    })
  )
}

export const configureDynamicFields = (
  dispatch: Dispatch,
  buttonName: string,
  iconName: string,
  tooltip: string,
  company: string,
  setTagsButton?: (buttonApi: IButtonApi) => void,
  cadenceId?: number
) => (editor: IEditor) => {
  editor.ui.registry.addMenuButton(buttonName, {
    icon: iconName,
    tooltip,
    fetch: (callback: EditorCallback) => {
      getDynamicTags(editor, dispatch, callback, company, cadenceId)
    },
    onSetup: (buttonApi: IButtonApi) => {
      setTagsButton?.(buttonApi)
    },
  })
}

export const addCadenceEndineTagsIcon = (editor: IEditor) => {
  editor.ui.registry.addIcon('dynamic-tags-icon', DynamicTagsIcon)
}
