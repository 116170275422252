import { createSelector } from 'reselect'
import { IAppState } from 'types'

const messageSelector = ({ appMessage }: IAppState) => appMessage

const messageDetailsSelector = createSelector(messageSelector, ({ message }) => message)

export {
  // eslint-disable-next-line import/prefer-default-export
  messageDetailsSelector,
}
