import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, ButtonGroup } from '@rhythm/buttons'
import { getColor } from '@rhythm/theme'
import { IDeleteStepModal } from 'types'
import { Modal, ModalBody, ModalFooter } from '@rhythm/modals'

const Message = styled.div`
  color: ${getColor('greyDarkest')};
  margin: 0.5rem 0rem;
`

const DeleteStepModal = ({ isOpen, handleCloseModal, handleDeleteStep }: IDeleteStepModal) => {
  const [isLoading, setLoading] = useState(false)

  const handleClickOnDeleteStep = () => {
    setLoading(true)
    handleDeleteStep()
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      title="Delete Step"
      minWidth={512}
      zIndex={2050}
    >
      {() => (
        <>
          <ModalBody>
            <Message>Are you sure you want to delete this step?</Message>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button variant="neutral" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button loading={isLoading} onClick={handleClickOnDeleteStep} variant="destructive">
                Confirm Delete
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </>
      )}
    </Modal>
  )
}

export default DeleteStepModal
