import { createAction } from 'utils'
import { IMotion, IObjective } from 'types'
import { MOTIONS, OBJECTIVES, DURATION_AND_INTENSITY } from './types'

const motionsActions = {
  request: () => createAction(MOTIONS.REQUEST),
  update: (motionsList: IObjective[]) => createAction(MOTIONS.UPDATE, { motionsList }),
  get: ({
    successFunc,
    errorFunc,
  }: {
    successFunc?: (motions: IMotion[]) => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      MOTIONS.GET,
      {
        fetching: true,
        success: false,
        error: null,
      },
      {},
      successFunc,
      errorFunc
    ),
  success: (motions: IMotion[]) =>
    createAction(MOTIONS.SUCCESS, {
      motions,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error: string) =>
    createAction(MOTIONS.FAILURE, {
      cadencesList: null,
      error,
      fetching: false,
      success: false,
    }),
}

const objectiveActions = {
  request: ({ data }: { data: { name?: string; searchMotions?: string } }) =>
    createAction(OBJECTIVES.REQUEST, data),
}

const durationAndIntensityActions = {
  request: ({ data }: { data: { id?: number } }) =>
    createAction(DURATION_AND_INTENSITY.REQUEST, data),
  update: (selectedMotion: IMotion) =>
    createAction(DURATION_AND_INTENSITY.UPDATE, { selectedMotion }),
}

export { motionsActions, objectiveActions, durationAndIntensityActions }
