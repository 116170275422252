import styled, { FlattenSimpleInterpolation } from 'styled-components'
import React from 'react'
import { FlexContainer, AppLogoDarkGreen } from 'static'
import { getTransparentizedColor, getColor } from '@rhythm/theme'

interface IProps {
  children: JSX.Element[] | JSX.Element
  styles?: FlattenSimpleInterpolation
}

const Container = styled(FlexContainer)<{ styles?: FlattenSimpleInterpolation }>`
  border-radius: 0.2rem;
  box-shadow: 0 0.3rem 0.5rem 0 ${getTransparentizedColor('black', 0.75)};
  border-top: 0.25rem solid ${getColor('green')};
  padding: 3.5rem 5rem;
  ${({ styles }) => styles}
`

const BlockContainer = ({ children, styles }: IProps) => (
  <Container column alignItems="center" styles={styles}>
    <img src={AppLogoDarkGreen} alt="Cadence Engine powered by Salesloft Labs" />
    {children}
  </Container>
)

export default BlockContainer
