import React, { useState } from 'react'
import styled from 'styled-components'
import { IOption, ISelectOptions } from 'types'
import { Select } from '@rhythm/inputs'

const SelectContainer = styled.div`
  padding-top: 20px;
  padding-right: 10px;
`

const Label = styled.label`
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 1px;
`

interface IProps {
  value: string
  label: string
  selectOptions: ISelectOptions[] | IOption[]
  updatePersonInformation(value: string): void
}

const SelectInput = ({ selectOptions, label, updatePersonInformation, value }: IProps) => {
  const [selectedValue, setSelectedValue] = useState({ name: value })

  return (
    <SelectContainer>
      <Label>{label}</Label>
      <Select
        getOptionLabel={(o: IOption) => o.name}
        options={selectOptions}
        onChange={(o: IOption) => {
          setSelectedValue(o)
          updatePersonInformation(o.name)
        }}
        value={selectedValue}
        width="100%"
      />
    </SelectContainer>
  )
}

export default SelectInput
