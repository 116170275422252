import { createSelector } from 'reselect'
import { IAppState } from 'types'

const modalReducerSelector = ({ appModal }: IAppState) => appModal

const modalDetailsSelector = createSelector(modalReducerSelector, ({ modal }) => modal)

export {
  // eslint-disable-next-line import/prefer-default-export
  modalDetailsSelector,
}
