import React, { useEffect } from 'react'
import { Menu, MenuBody, MenuDropdown, MenuItem, MenuToggle } from '@rhythm/menu'
import { UserIcon, ChevronSmDownIcon } from '@rhythm/svgs'
import { Button } from '@rhythm/buttons'
import { useDispatch, useSelector } from 'react-redux'
import { currentUserActions, signoutActions } from 'actions'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { IUser } from 'types'
import { PlaceholderBar } from '@rhythm/loading'
import { userCurrentUserSelector } from 'selectors'

const Link = styled.a`
  text-decoration: none;
`

const openButton = (userName: string) => ({ openMenu }: { openMenu: () => void }) =>
  userName ? (
    <Button onClick={openMenu} iconLeft={<UserIcon />} iconRight={<ChevronSmDownIcon />}>
      {userName}
    </Button>
  ) : (
    <PlaceholderBar h="20px" w="100px" mode="dark" />
  )

const UserMenu = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const currentUser = useSelector(userCurrentUserSelector) || ({} as IUser)

  useEffect(() => {
    if (!currentUser.name) {
      dispatch(currentUserActions.request({}))
    }
  }, [])

  const signout = () =>
    dispatch(
      signoutActions.request({
        successFunc: () => {
          history.push('/signin')
        },
      })
    )

  return (
    <Menu>
      <MenuToggle>{openButton(currentUser.name)}</MenuToggle>
      <MenuDropdown>
        <MenuBody>
          <MenuItem autoClose onClick={() => history.push('/profile')}>
            Profile
          </MenuItem>
          <Link
            aria-label="Go to Salesloft"
            href="https://salesloft.com/platform/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MenuItem autoClose>Go to Salesloft</MenuItem>
          </Link>
          <MenuItem onClick={signout}>Log Out</MenuItem>
        </MenuBody>
      </MenuDropdown>
    </Menu>
  )
}

export default UserMenu
