import { all, put, takeLatest } from 'redux-saga/effects'
import { customAxios, customAxiosCall } from 'utils'
import { DYNAMIC_TAGS, messageActions } from 'actions'
import { IAction, IActionDefaultPayload } from 'types'

const endpoints = {
  dynamicTags: '/api/v1/parameters',
}

export function* getDynamicTags({
  body: { company, cadenceId },
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<string>, { company: string; cadenceId?: number }>) {
  try {
    const { data } = yield customAxiosCall(customAxios.get, endpoints.dynamicTags, {
      params: {
        filter: {
          company,
          of_cadence: cadenceId,
        },
      },
    })
    successFunc?.(data)
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: statusText,
        },
      })
    )
    errorFunc?.(errors || statusText)
  }
}

function* dynamicTagsSagas() {
  yield all([takeLatest(DYNAMIC_TAGS.REQUEST, getDynamicTags)])
}

export default dynamicTagsSagas
