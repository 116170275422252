import React from 'react'
import {
  Field,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
  submit,
  FormSubmitHandler,
} from 'redux-form'
import { Asterisk, emailRegex, flex, flexCol, flexAlignItems, flexSize } from 'static'
import styled from 'styled-components'
import { Button } from '@rhythm/buttons'
import { IFeedbackForm } from 'types'
import { getColor, getFontWeight } from '@rhythm/theme'
import { messageActions } from 'actions'
import FormInput from '../FormInput/FormInput'
import FormTextArea from '../FormTextArea/FormTextArea'

interface IProps {
  fetching?: boolean
}

const Form = styled.form`
  ${flex};
  ${flexCol}
  ${flexSize(1)}
  ${flexAlignItems('flex-start')}
  max-width: 21.25rem;
  height: 100vh;
`

const SubmitButton = styled(Button)`
  margin-top: 1.25rem;
`

const Label = styled.span`
  font-size: 0.875rem;
  color: ${getColor('greyDark')};
  font-weight: ${getFontWeight('semibold')};
  margin-bottom: 0.25rem;
  ${Asterisk(true)}
`

const validate: FormSubmitHandler<IFeedbackForm, IProps, string> = (
  { email, name, feedback },
  dispatch
) => {
  const errors: IFeedbackForm = {}
  let error = 'All fields are required'
  if (!name) {
    errors.name = 'This field is required'
  }
  if (!feedback) {
    errors.feedback = 'This field is required'
  }
  if (!email) {
    errors.email = 'This field is required'
  } else if (!emailRegex.test(email)) {
    error = 'Invalid email address'
    errors.email = 'Invalid email address'
  }
  if (Object.keys(errors).length > 0) {
    dispatch(
      messageActions.update({
        message: {
          type: 'danger',
          body: error,
        },
      })
    )
    throw new SubmissionError({
      ...errors,
      _error: 'FeedBack failed!',
    })
  }
  dispatch(submit('feedback'))
}

const FeedbackForm = ({
  handleSubmit,
  submitting,
  fetching = false,
}: InjectedFormProps<IFeedbackForm, IProps> & IProps) => (
  <Form onSubmit={handleSubmit(validate)}>
    <Label>Name</Label>
    <Field
      name="name"
      component={FormInput}
      placeholder="Your Name"
      type="text"
      width="21.25rem"
      maxLength="120"
      inputSize="small"
      height="2rem"
      containerHeight="3.25rem"
      noSetCustomValidity
      withoutError
    />
    <Label>Email</Label>
    <Field
      name="email"
      component={FormInput}
      placeholder="Your Email"
      width="21.25rem"
      type="email"
      maxLength="120"
      inputSize="small"
      height="2rem"
      containerHeight="3.25rem"
      noSetCustomValidity
      withoutError
    />
    <Label>Feedback</Label>
    <Field
      name="feedback"
      component={FormTextArea}
      placeholder="Your Feedback"
      maxLength="5000"
      minRows={6}
      maxRows={6}
      noSetCustomValidity
    />
    <SubmitButton type="submit" loading={submitting || fetching}>
      Send Feedback
    </SubmitButton>
  </Form>
)

export default reduxForm<IFeedbackForm, IProps>({
  form: 'feedback',
})(FeedbackForm)
