import { GLOBAL_MESSAGE } from 'actions'
import { IAppMessageState } from 'types'

const initialState: IAppMessageState = {
  message: null,
}

export default function appMessageReducer(
  state = initialState,
  { type, payload }: { type: string; payload: Record<string, unknown> }
) {
  switch (type) {
    case GLOBAL_MESSAGE.UPDATE: {
      return {
        ...state,
        ...payload,
      }
    }
    case GLOBAL_MESSAGE.DELETE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
