import styled, { css } from 'styled-components'
import { getColor } from '@rhythm/theme'

const flex = css`
  display: flex;
`

const flexSize = (size: number) => css`
  flex: ${size};
`

const flexCol = css`
  flex-direction: column;
`

const flexAlignItems = (
  alignItems: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline'
) => css`
  align-items: ${alignItems};
`

const flexJustifyContent = (
  justifyContent: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around'
) => css`
  justify-content: ${justifyContent};
`

const FlexContainer = styled.div<{
  column?: boolean
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline'
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around'
  flexSize?: number
}>`
  ${flex}
  ${({ column }) => (column ? flexCol : '')}
  ${({ alignItems }) => (alignItems ? flexAlignItems(alignItems) : '')}
  ${({ justifyContent }) => (justifyContent ? flexJustifyContent(justifyContent) : '')}
  ${({ flexSize: size }) => (size ? flexSize(size) : '')}
`

const Asterisk = (required: boolean) =>
  required &&
  css`
    :after {
      color: ${getColor('red')};
      content: '*';
      font-size: 16px;
    }
  `

export { flex, flexSize, flexCol, flexAlignItems, flexJustifyContent, FlexContainer, Asterisk }
