import { messageActions } from 'actions'
import React, { ReactText } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup } from '@rhythm/buttons'
import { getColor } from '@rhythm/theme'
import { TContainer } from 'types'
import styled from 'styled-components'
import { cadenceFetchingSelector } from 'selectors'

interface IProps {
  body: TContainer
  footer: TContainer
  onClose: () => void
  onApply: ({
    data,
    successFunc,
  }: {
    data: {
      id: ReactText
    }
    successFunc: () => void
  }) => void
  id: ReactText
}

const Message = styled.span`
  color: ${getColor('greyDarkest')};
`

const DeleteCadenceModal = ({ body: Body, footer: Footer, onClose, onApply, id }: IProps) => {
  const dispatch = useDispatch()
  const cadenceFetching = useSelector(cadenceFetchingSelector)
  const onSend = () =>
    onApply({
      data: { id },
      successFunc: () => {
        dispatch(
          messageActions.update({
            message: {
              body: 'Cadence Deleted',
              type: 'confirm',
            },
          })
        )
        onClose()
      },
    })
  return (
    <>
      <Body>
        <Message>Are you sure you want to delete this? All data will be lost.</Message>
      </Body>
      <Footer>
        <ButtonGroup>
          <Button variant="neutral" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={cadenceFetching} onClick={onSend} variant="destructive">
            Delete
          </Button>
        </ButtonGroup>
      </Footer>
    </>
  )
}

export default DeleteCadenceModal
