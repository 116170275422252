import React from 'react'
import { Menu, MenuToggle, MenuDropdown } from '@rhythm/menu'
import {
  Manager,
  Reference,
  Popper,
  PopperChildrenProps,
  ReferenceChildrenProps,
} from 'react-popper'
import { Portal } from '@rhythm/portal'
import styled from 'styled-components'

interface IToggle {
  isOpen: boolean
  openMenu: () => void
  closeMenu: () => void
}

type TToggleView = ({ isOpen, openMenu, closeMenu }: IToggle) => JSX.Element

interface IBodyView {
  bodyView: () => JSX.Element
  horizontalPosition?: 'left' | 'right'
  verticalPosition?: 'down' | 'up'
}

interface IProps extends IBodyView {
  toggleView: TToggleView
}

const DropDownVisibilityChangerHack = styled.div`
  > [aria-expanded='true'] {
    z-index: 2053;
  }
  > div:first-child {
    z-index: 2052;
  }
`

const toggleWithRef = (toggleView: TToggleView) => ({ ref }: ReferenceChildrenProps) => (
  <MenuToggle>{(data: IToggle) => <div ref={ref}>{toggleView(data)}</div>}</MenuToggle>
)

const dropDownWithRef = ({
  bodyView: BodyView,
  horizontalPosition,
  verticalPosition,
}: IBodyView) => ({ ref, style }: PopperChildrenProps) => (
  <DropDownVisibilityChangerHack>
    <MenuDropdown
      ref={ref}
      style={style}
      horizontalPosition={horizontalPosition}
      verticalPosition={verticalPosition}
    >
      <BodyView />
    </MenuDropdown>
  </DropDownVisibilityChangerHack>
)

const MenuForTable = ({
  toggleView,
  bodyView,
  horizontalPosition = 'left',
  verticalPosition = 'down',
}: IProps) => (
  <Menu>
    <Manager>
      <Reference>{toggleWithRef(toggleView)}</Reference>
      <Portal>
        <Popper
          modifiers={[
            {
              name: 'offset',
              options: { offset: [100, 0] },
            },
          ]}
          placement="bottom"
        >
          {dropDownWithRef({
            bodyView,
            horizontalPosition,
            verticalPosition,
          })}
        </Popper>
      </Portal>
    </Manager>
  </Menu>
)

export default MenuForTable
