import { cadenceQuestionsActions, stepsActions } from 'actions'
import { ContainerWithMenu, FooterButtons, Loader, Question } from 'components'
import { UserMenu } from 'containers'
import React, { ReactText, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  cadenceFetchingSelector,
  cadenceQuestionsFetchingSelector,
  cadenceQuestionsSelector,
  cadenceQuestionsAnswersSelector,
} from 'selectors'

interface IProps {
  cadenceId: ReactText
}

const Questions = ({ cadenceId }: IProps) => {
  const dispatch = useDispatch()
  const questions = useSelector(cadenceQuestionsSelector)
  const answers = useSelector(cadenceQuestionsAnswersSelector)
  const fetching = useSelector(cadenceQuestionsFetchingSelector)
  const cadenceFetching = useSelector(cadenceFetchingSelector)
  let questionIndex = 0
  const currentQuestion = questions?.find(({ completed }, index) => {
    questionIndex = index + 1
    return !completed
  })

  const handleBack = () => {
    if (!currentQuestion || !questions) return
    if (questionIndex === 1) {
      dispatch(
        stepsActions.request({
          data: { id: cadenceId, action: 'back' },
        })
      )
    } else {
      dispatch(
        cadenceQuestionsActions.retreat({
          data: {
            cadenceId,
            questionId: questions[questionIndex - 2]?.id,
          },
          successFunc: () => {
            dispatch(cadenceQuestionsActions.get({ cadenceId }))
          },
        })
      )
    }
  }

  const handleProceed = () => {
    if (!currentQuestion || !questions) return
    dispatch(
      cadenceQuestionsActions.proceed({
        data: {
          cadenceId,
          questionId: currentQuestion.id,
        },
        successFunc: () => {
          if (questionIndex === questions.length) {
            dispatch(
              stepsActions.request({
                data: {
                  id: cadenceId,
                  action: 'proceed',
                },
              })
            )
          } else {
            dispatch(cadenceQuestionsActions.get({ cadenceId }))
          }
        },
      })
    )
  }

  useEffect(() => {
    dispatch(cadenceQuestionsActions.get({ cadenceId }))
  }, [cadenceId])

  return (
    <>
      <ContainerWithMenu menu={UserMenu}>
        {currentQuestion && questions ? (
          <Question
            currentQuestion={currentQuestion}
            questionIndex={questionIndex}
            questionsAmount={questions?.length}
            cadenceId={+cadenceId}
          />
        ) : (
          <Loader />
        )}
      </ContainerWithMenu>
      <FooterButtons
        handleBack={handleBack}
        handleProceed={handleProceed}
        isProcceedDisabled={answers?.length === 0}
        loading={fetching || cadenceFetching}
      />
    </>
  )
}

export default Questions
