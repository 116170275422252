import React from 'react'
import { FlexContainer, AppLogoDarkGreen } from 'static'
import { getColor, getTransparentizedColor } from '@rhythm/theme'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

export interface IProps {
  children: JSX.Element[] | JSX.Element
  menu?: () => JSX.Element
  isLogoLink?: boolean
}

const MenuContainer = styled(FlexContainer)`
  background-color: ${getColor('white')};
  padding: 0 1.25rem 0 0;
  flex-wrap: wrap;
  gap: 16px;
  box-shadow: 0 2px 4px ${getTransparentizedColor('black', 0.92)};
`

const Logo = styled.img<{ isNotCursor?: boolean }>`
  min-width: 13rem;
  width: 19rem;
  cursor: ${({ isNotCursor }) => (isNotCursor ? 'initial' : 'pointer')};
`

const ContainerWithMenu = ({ children, menu: Menu = () => <div />, isLogoLink = true }: IProps) => {
  const history = useHistory()
  return (
    <FlexContainer column flexSize={1}>
      <MenuContainer justifyContent="space-between" alignItems="center">
        <Logo
          aria-label="menu logo"
          src={AppLogoDarkGreen}
          isNotCursor={!isLogoLink}
          onClick={() => isLogoLink && history.push('/cadenceList')}
        />
        <Menu />
      </MenuContainer>
      {children}
    </FlexContainer>
  )
}

export default ContainerWithMenu
