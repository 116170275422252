import React from 'react'
import styled from 'styled-components'
import { getColor, getFontWeight } from '@rhythm/theme'
import { IStepName } from 'types'
import { Asterisk } from 'static'

const NameContainer = styled.div`
  padding: 4px 0 4px 17px;
`

const NameInput = styled.input<{ width: string }>`
  margin-left: 20px;
  border: none;
  width: ${({ width }) => width};
  color: ${getColor('greyDarkest')};
  font-size: 14px;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${getColor('grey')};
  }
`

const BoldTitle = styled.span<{ required: boolean }>`
  font-weight: ${getFontWeight('semibold')};
  color: ${getColor('greyDarkest')};
  font-size: 14px;

  ${({ required }) => Asterisk(required)}
`

const StepName = ({ name, type, width, handleChangeNameInput }: IStepName) => (
  <NameContainer>
    <BoldTitle required>Name</BoldTitle>
    <NameInput
      placeholder={`${type} Name`}
      value={name}
      width={width}
      onChange={handleChangeNameInput}
    />
  </NameContainer>
)

export default StepName
