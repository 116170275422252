import { all, fork } from 'redux-saga/effects'
import authSagas from './auth'
import motionsSagas from './motions'
import cadenceSagas from './cadences'
import stepsSagas from './steps'
import userSagas from './users'
import cadenceStepsSagas from './cadenceSteps'
import dynamicTagsSagas from './dynamicTags'

export default function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(cadenceSagas),
    fork(motionsSagas),
    fork(userSagas),
    fork(stepsSagas),
    fork(cadenceStepsSagas),
    fork(dynamicTagsSagas),
  ])
}
