import { all, put, takeLatest } from 'redux-saga/effects'
import { customAxios, customAxiosCall } from 'utils'
import { STEPS, messageActions, stepsActions } from 'actions'
import { IAction, IActionDefaultPayload } from 'types'

const endpoints = {
  proceed: (id: string | number) => `/api/v1/cadences/${id}/proceed`,
  retreat: (id: string | number) => `/api/v1/cadences/${id}/retreat`,
}

export function* updateCadenceState({
  body: { id, action, skip_questions },
  successFunc,
  errorFunc,
}: IAction<
  IActionDefaultPayload<string>,
  { id: number; action: string; skip_questions?: boolean }
>) {
  try {
    let response
    if (skip_questions) {
      response = yield customAxiosCall(customAxios.post, endpoints.proceed(id), {
        skip_questions: skip_questions?.toString(),
      })
    } else if (action === 'proceed') {
      response = yield customAxiosCall(customAxios.post, endpoints.proceed(id))
    } else if (action === 'back') {
      response = yield customAxiosCall(customAxios.post, endpoints.retreat(id))
    }
    yield put(stepsActions.success(response.data))
    if (successFunc) {
      successFunc(response.data)
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    yield put(stepsActions.failure(errors || statusText))
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: statusText,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

function* stepsSagas() {
  yield all([takeLatest(STEPS.REQUEST, updateCadenceState)])
}

export default stepsSagas
