import styled from 'styled-components'
import { LoadingSpinner } from '@rhythm/loading'
import { getColor } from '@rhythm/theme'

const Loader = styled(LoadingSpinner).attrs({
  'data-testid': 'loading-spinner',
})`
  display: block;
  min-height: 40px;

  &:before {
    border-color: ${getColor('green')};
  }
`

export default Loader
