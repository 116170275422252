import React from 'react'
import { TabContainer, Tab } from '@rhythm/tabs'
import { getColor } from '@rhythm/theme'
import styled from 'styled-components'
import { H5 } from '@rhythm/typography'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { ContainerWithMenu } from 'components'
import { CadencesList, UserMenu } from 'containers'
import { Helmet } from 'react-helmet'

const Title = styled(H5)`
  padding: 1.4rem 0rem 1rem 2.25rem;
  box-shadow: inset 0 -1px 0 0 ${getColor('greyLightest')};
  color: ${getColor('greyDarkest')};
  line-height: 1.15rem;
  margin: 0;
`

const Space = styled.div`
  padding: 0rem 0.8rem;
`

const CadencesListTabs = ({
  match: { path },
  history,
  location: { pathname },
}: RouteComponentProps) => {
  const goToUrl = (tab?: string) => () => history.push(tab ? `${path}/${tab}` : path)
  const isProgressTab = pathname.indexOf('progress') !== -1
  return (
    <ContainerWithMenu menu={UserMenu}>
      <Helmet>
        <title>{isProgressTab ? 'Cadences In Progress' : 'Cadences List'}</title>
      </Helmet>
      <Title>Cadences</Title>
      <TabContainer>
        <Space />
        <Tab text="Built Cadences" onClick={goToUrl()} active={!isProgressTab} />
        <Tab text="In Progress" onClick={goToUrl('progress')} active={isProgressTab} />
      </TabContainer>
      <Switch>
        <Route path={`${path}/`} exact render={(props) => <CadencesList {...props} />} />
        <Route
          path={`${path}/progress`}
          render={(props) => <CadencesList isProgressTab {...props} />}
        />
        <Route>
          <Redirect to={`${path}`} />
        </Route>
      </Switch>
    </ContainerWithMenu>
  )
}

export default CadencesListTabs
