import { createSelector } from 'reselect'
import { IAppState } from 'types'

const cadenceSelector = ({ cadence }: IAppState) => cadence

const cadenceFetchingSelector = createSelector(cadenceSelector, ({ fetching }) => fetching)

const cadenceErrorSelector = createSelector(cadenceSelector, ({ error }) => error)

const cadenceItemSelector = createSelector(cadenceSelector, ({ cadence }) => cadence)

const cadenceStepsSelector = createSelector(cadenceSelector, ({ cadenceSteps }) => cadenceSteps)

export { cadenceFetchingSelector, cadenceErrorSelector, cadenceItemSelector, cadenceStepsSelector }
