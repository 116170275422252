import { ICadenceState } from 'types'
import { CADENCE, CADENCE_EXPORT, CADENCE_STEPS, STEPS, CADENCE_STEP } from 'actions'

export const initialState: ICadenceState = {
  cadence: null,
  cadenceSteps: null,
  fetching: false,
  success: false,
  error: null,
}

export default function cadenceReducer(
  state = initialState,
  { type, payload }: { type: string; payload: Record<string, unknown> }
) {
  switch (type) {
    case CADENCE.REQUEST:
    case CADENCE.DELETE:
    case CADENCE.UPDATE:
    case CADENCE.SUCCESS:
    case CADENCE.GET:
    case CADENCE_STEPS.REQUEST:
    case CADENCE_STEPS.UPDATE:
    case CADENCE_STEPS.SUCCESS:
    case CADENCE_STEPS.FAILURE:
    case CADENCE_STEP.REQUEST:
    case CADENCE_STEP.UPDATE:
    case CADENCE_STEP.SUCCESS:
    case CADENCE_STEP.FAILURE:
    case STEPS.REQUEST:
    case STEPS.SUCCESS:
    case STEPS.FAILURE:
    case CADENCE_EXPORT.GET:
    case CADENCE_EXPORT.SUCCESS:
    case CADENCE_EXPORT.FAILURE:
    case CADENCE.FAILURE: {
      return {
        ...state,
        ...payload,
      }
    }
    default:
      return state
  }
}
