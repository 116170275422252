import React from 'react'
import {
  Field,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
  submit,
  FormSubmitHandler,
} from 'redux-form'
import { emailRegex, flex, flexCol, FlexContainer } from 'static'
import styled from 'styled-components'
import { Button } from '@rhythm/buttons'
import { IForgotPassword } from 'types'
import FormInput from '../FormInput/FormInput'

interface IProps {
  fetching?: boolean
}

const Form = styled.form`
  ${flex};
  ${flexCol}
`

const SubmitButton = styled(Button)`
  padding: 1.2rem 4rem;
  width: 100%;
  font-size: 1rem;
  margin-top: 0.5rem;
`

const validate: FormSubmitHandler<IForgotPassword, IProps, string> = ({ email }, dispatch) => {
  const error: IForgotPassword = {}
  if (!email) {
    error.email = 'This field is required'
  } else if (!emailRegex.test(email)) {
    error.email = 'Invalid email address'
  }
  if (Object.keys(error).length > 0) {
    throw new SubmissionError({
      ...error,
      _error: 'Login failed!',
    })
  } else {
    dispatch(submit('forgotPassword'))
  }
}

const ForgotPasswordForm = ({
  handleSubmit,
  submitting,
  fetching = false,
}: InjectedFormProps<IForgotPassword, IProps> & IProps) => (
  <Form onSubmit={handleSubmit(validate)}>
    <FlexContainer column>
      <Field
        name="email"
        component={FormInput}
        placeholder="Email"
        type="text"
        maxLength="120"
        noSetCustomValidity
      />
      <SubmitButton type="submit" loading={submitting || fetching}>
        Get New Password
      </SubmitButton>
    </FlexContainer>
  </Form>
)

export default reduxForm<IForgotPassword, IProps>({
  form: 'forgotPassword',
})(ForgotPasswordForm)
