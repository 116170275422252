import { createSelector } from 'reselect'
import { IAppState } from 'types'

const userSelector = ({ user }: IAppState) => user

const userFetchingSelector = createSelector(userSelector, ({ fetching }) => fetching)

const userSuccessSelector = createSelector(userSelector, ({ success }) => success)

const userErrorSelector = createSelector(userSelector, ({ error }) => error)

const userAuthTokenSelector = createSelector(userSelector, ({ authToken }) => authToken)

const userUsersSharedSelector = createSelector(userSelector, ({ usersShared }) => usersShared)

const userCurrentUserSelector = createSelector(userSelector, ({ user }) => user)

export {
  userFetchingSelector,
  userErrorSelector,
  userSuccessSelector,
  userAuthTokenSelector,
  userUsersSharedSelector,
  userCurrentUserSelector,
}
