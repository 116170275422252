import { IMotionsState } from 'types'
import { MOTIONS, DURATION_AND_INTENSITY } from 'actions'

export const initialState: IMotionsState = {
  motionsList: null,
  selectedMotion: null,
  motions: null,
  fetching: false,
  success: false,
  error: null,
}

function motionsReducer(
  state = initialState,
  { type, payload }: { type: string; payload: Record<string, unknown> }
) {
  switch (type) {
    case MOTIONS.FAILURE:
    case MOTIONS.SUCCESS:
    case MOTIONS.REQUEST:
    case MOTIONS.GET:
    case MOTIONS.UPDATE: {
      return {
        ...state,
        ...payload,
      }
    }
    case DURATION_AND_INTENSITY.UPDATE: {
      return {
        ...state,
        ...payload,
      }
    }
    default:
      return state
  }
}

export default motionsReducer
