import { IFeedbackForm, ISharedUser, IUser } from 'types'
import { createAction } from 'utils'
import { USER, CURRENT_USER } from 'actions'
import { FEEDBACK } from './types'

const usersSharedActions = {
  request: ({
    successFunc,
    errorFunc,
  }: {
    successFunc?: (usersShared: ISharedUser[]) => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      USER.REQUEST,
      {
        fetching: true,
        success: false,
        error: null,
      },
      {},
      successFunc,
      errorFunc
    ),
  success: (usersShared: ISharedUser[]) =>
    createAction(USER.SUCCESS, {
      usersShared,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error: string) =>
    createAction(USER.FAILURE, {
      error,
      fetching: false,
      success: false,
    }),
}

const currentUserActions = {
  request: ({
    successFunc,
    errorFunc,
  }: {
    successFunc?: (currentUser: IUser) => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CURRENT_USER.REQUEST,
      {
        fetching: true,
        success: false,
        error: null,
      },
      {},
      successFunc,
      errorFunc
    ),
  get: ({
    successFunc,
    errorFunc,
  }: {
    successFunc?: (isOauthInSalesloft: boolean) => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CURRENT_USER.GET,
      {
        fetching: true,
        success: false,
        error: null,
      },
      {},
      successFunc,
      errorFunc
    ),
  success: (user: IUser) =>
    createAction(CURRENT_USER.SUCCESS, {
      user,
      fetching: false,
      success: true,
      error: null,
    }),
  update: ({
    data: { firstName, lastName, show_export_sign_in_modal },
    successFunc,
    errorFunc,
  }: {
    data: {
      firstName?: string
      lastName?: string
      show_export_sign_in_modal?: boolean
    }
    successFunc?: () => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CURRENT_USER.UPDATE,
      {
        fetching: true,
        success: false,
        error: null,
      },
      {
        firstName,
        lastName,
        show_export_sign_in_modal,
      },
      successFunc,
      errorFunc
    ),
  failure: (error: string) =>
    createAction(CURRENT_USER.FAILURE, {
      error,
      fetching: false,
      success: false,
    }),
}

const feedbackActions = {
  request: ({
    data,
    successFunc,
    errorFunc,
  }: {
    data: IFeedbackForm
    successFunc?: () => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      FEEDBACK.REQUEST,
      {
        fetching: true,
        success: false,
        error: null,
      },
      data,
      successFunc,
      errorFunc
    ),
  success: () =>
    createAction(FEEDBACK.SUCCESS, {
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error: string) =>
    createAction(FEEDBACK.FAILURE, {
      error,
      fetching: false,
      success: false,
    }),
}

export { usersSharedActions, currentUserActions, feedbackActions }
