import { ReactText } from 'react'
import {
  CADENCES,
  CADENCE,
  CADENCE_PERSONA,
  CADENCE_QUESTIONS,
  CADENCE_QUESTION_ANSWERS,
  CADENCE_EXPORT,
} from 'actions'
import {
  ICadenceFilters,
  ICadencesList,
  ICadence,
  ICadenceQuestion,
  ICadenceQuestionAnswer,
} from 'types'
import { createAction } from 'utils'

const cadencesActions = {
  request: ({
    data,
    successFunc,
    errorFunc,
  }: {
    data: ICadenceFilters
    successFunc?: (list: ICadencesList) => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCES.REQUEST,
      {
        fetching: true,
        success: false,
        error: null,
      },
      data,
      successFunc,
      errorFunc
    ),
  success: (cadencesList: ICadencesList) =>
    createAction(CADENCES.SUCCESS, {
      cadencesList,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error: string) =>
    createAction(CADENCES.FAILURE, {
      cadencesList: null,
      error,
      fetching: false,
      success: false,
    }),
}

const cadenceExportActions = {
  get: ({
    cadenceId,
    successFunc,
    errorFunc,
  }: {
    cadenceId: number
    successFunc?: (link: string) => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE_EXPORT.GET,
      {
        fetching: true,
        success: false,
        error: null,
      },
      {
        cadenceId,
      },
      successFunc,
      errorFunc
    ),
  success: () =>
    createAction(CADENCE_EXPORT.SUCCESS, {
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error: string) =>
    createAction(CADENCE_EXPORT.FAILURE, {
      error,
      fetching: false,
      success: false,
    }),
}

const cadenceActions = {
  request: ({
    data: { cadenceName: name, allowDuplicate },
    successFunc,
    errorFunc,
  }: {
    data: {
      cadenceName: string
      allowDuplicate?: boolean
    }
    successFunc?: (cadence: ICadence) => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE.REQUEST,
      {
        fetching: true,
        success: false,
        error: null,
      },
      {
        name,
        allowDuplicate,
      },
      successFunc,
      errorFunc
    ),
  update: ({
    data: { cadenceName: name, allowDuplicate, id, motion_id, duration, emails, phones, socials },
    successFunc,
    errorFunc,
  }: {
    data: {
      cadenceName: string
      allowDuplicate?: boolean
      id: string | number
      motion_id?: number
      duration?: number
      emails?: number
      phones?: number
      socials?: number
    }
    successFunc?: () => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE.UPDATE,
      {
        fetching: true,
        success: false,
        error: null,
      },
      {
        name,
        allowDuplicate,
        id,
        motion_id,
        duration,
        emails,
        phones,
        socials,
      },
      successFunc,
      errorFunc
    ),
  delete: ({
    id,
    successFunc,
    errorFunc,
  }: {
    id: string | number
    successFunc?: () => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE.DELETE,
      {
        fetching: true,
        success: false,
        error: null,
        cadence: null,
      },
      {
        id,
      },
      successFunc,
      errorFunc
    ),
  success: (cadence?: ICadence | null) =>
    createAction(CADENCE.SUCCESS, {
      cadence,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error: string | null) =>
    createAction(CADENCE.FAILURE, {
      error,
      fetching: false,
      success: false,
    }),
  get: ({
    data: { id },
    successFunc,
    errorFunc,
  }: {
    data: {
      id: number
    }
    successFunc?: () => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE.GET,
      {
        fetching: true,
        success: false,
        error: null,
      },
      {
        id,
      },
      successFunc,
      errorFunc
    ),
}

const cadenceQuestionsActions = {
  get: ({
    cadenceId,
    successFunc,
    errorFunc,
  }: {
    cadenceId: ReactText
    successFunc?: (questions: ICadenceQuestion[]) => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE_QUESTIONS.GET,
      {
        fetching: true,
        success: false,
        error: null,
      },
      {
        cadenceId,
      },
      successFunc,
      errorFunc
    ),
  proceed: ({
    data,
    successFunc,
    errorFunc,
  }: {
    data: {
      cadenceId: ReactText
      questionId: ReactText
    }
    successFunc?: (question: ICadenceQuestion) => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE_QUESTIONS.UPDATE,
      {
        fetching: true,
        success: false,
        error: null,
      },
      data,
      successFunc,
      errorFunc
    ),
  retreat: ({
    data,
    successFunc,
    errorFunc,
  }: {
    data: {
      cadenceId: ReactText
      questionId: ReactText
    }
    successFunc?: (questions: ICadenceQuestion[]) => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE_QUESTIONS.DELETE,
      {
        fetching: true,
        success: false,
        error: null,
      },
      data,
      successFunc,
      errorFunc
    ),
  success: (questions: ICadenceQuestion[]) =>
    createAction(CADENCE_QUESTIONS.SUCCESS, {
      questions,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error: string) =>
    createAction(CADENCE_QUESTIONS.FAILURE, {
      error,
      fetching: false,
      success: false,
    }),
}

const cadenceQuestionAnswersActions = {
  get: ({
    data,
    successFunc,
    errorFunc,
  }: {
    data: {
      cadenceId: ReactText
      questionId: ReactText
      isLibAnswers?: boolean
    }
    successFunc?: (answers: ICadenceQuestionAnswer[]) => void
    errorFunc?: (error: string) => void
  }) => {
    const body: {
      fetching: boolean
      success: boolean
      error: null
      libAnswers?: null
      answers?: null
    } = {
      fetching: true,
      success: false,
      error: null,
    }
    if (data.isLibAnswers) {
      body.libAnswers = null
    } else {
      body.answers = null
    }
    return createAction(CADENCE_QUESTION_ANSWERS.GET, body, data, successFunc, errorFunc)
  },
  create: ({
    data,
    successFunc,
    errorFunc,
  }: {
    data: {
      cadenceId: ReactText
      questionId: ReactText
      answer: {
        name: string
        link: string | null
      }
      isCreateLib?: boolean
    }
    successFunc?: (question: ICadenceQuestionAnswer) => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE_QUESTION_ANSWERS.REQUEST,
      {
        fetching: true,
        success: false,
        error: null,
      },
      data,
      successFunc,
      errorFunc
    ),
  delete: ({
    data,
    successFunc,
    errorFunc,
  }: {
    data: {
      cadenceId: ReactText
      questionId: ReactText
      answerId: ReactText
    }
    successFunc?: () => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE_QUESTION_ANSWERS.DELETE,
      {
        fetching: true,
        success: false,
        error: null,
      },
      data,
      successFunc,
      errorFunc
    ),
  success: (answers: {
    answers?: ICadenceQuestionAnswer[]
    libAnswers?: ICadenceQuestionAnswer[]
  }) =>
    createAction(CADENCE_QUESTION_ANSWERS.SUCCESS, {
      ...answers,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error: string) =>
    createAction(CADENCE_QUESTION_ANSWERS.FAILURE, {
      error,
      fetching: false,
      success: false,
    }),
}

const cadencePersonaActions = {
  request: ({
    data: { id, company_headcount, geography, customer_type, industry, job_role },
    successFunc,
    errorFunc,
  }: {
    data: {
      id: string | number
      company_headcount?: string
      geography?: string
      customer_type?: string
      industry?: string
      job_role?: string
    }
    successFunc?: () => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE_PERSONA.REQUEST,
      {
        success: false,
        error: null,
      },
      {
        id,
        company_headcount,
        geography,
        customer_type,
        industry,
        job_role,
      },
      successFunc,
      errorFunc
    ),
}

export {
  cadenceActions,
  cadencesActions,
  cadencePersonaActions,
  cadenceQuestionsActions,
  cadenceQuestionAnswersActions,
  cadenceExportActions,
}
