import { all, put, select, takeLatest } from 'redux-saga/effects'
import {
  messageActions,
  USER,
  usersSharedActions,
  CURRENT_USER,
  currentUserActions,
  FEEDBACK,
  feedbackActions,
} from 'actions'
import { IAction, IActionDefaultPayload, IFeedbackForm } from 'types'
import { customAxios, customAxiosCall } from 'utils'
import { userCurrentUserSelector } from 'selectors'

const endpoints = {
  usersShared: 'api/v1/user/users_shared',
  currentUser: (request: string) => `api/v1/user/${request}`,
  oauthTokens: `api/v1/user_oauth_tokens/exists?token_provider=salesloft`,
  feedback: 'api/v1/feedback',
}

export function* getUsersShared({
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<string>, never>) {
  try {
    const { data } = yield customAxiosCall(customAxios.get, endpoints.usersShared)
    yield put(usersSharedActions.success(data))
    if (successFunc) {
      successFunc(data)
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    yield put(usersSharedActions.failure(errors || statusText))
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: statusText,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* getCurrentUser({
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<string>, never>) {
  try {
    const { data } = yield customAxiosCall(customAxios.get, endpoints.currentUser('me'))

    yield put(currentUserActions.success(data))
    if (successFunc) {
      successFunc(data)
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    yield put(currentUserActions.failure(errors || statusText))
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: statusText,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* updateCurrentUser({
  body: { firstName, lastName, show_export_sign_in_modal },
  successFunc,
  errorFunc,
}: IAction<
  IActionDefaultPayload<string>,
  {
    firstName?: string
    lastName?: string
    show_export_sign_in_modal?: boolean
  }
>) {
  try {
    const { data } = yield customAxiosCall(customAxios.put, endpoints.currentUser('update'), {
      first_name: firstName,
      last_name: lastName,
      show_export_sign_in_modal:
        show_export_sign_in_modal === undefined
          ? show_export_sign_in_modal
          : `${show_export_sign_in_modal}`,
    })
    yield put(currentUserActions.success(data))
    if (successFunc) {
      successFunc(data)
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    yield put(currentUserActions.failure(errors || statusText))
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: statusText,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* getOauthTokens({
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<string>>) {
  try {
    const { data } = yield customAxiosCall(customAxios.get, endpoints.oauthTokens)
    const user = yield select(userCurrentUserSelector)
    yield put(currentUserActions.success(user))
    if (successFunc) {
      successFunc(data)
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    yield put(currentUserActions.failure(errors || statusText))
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: statusText,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* sendFeedback({
  body,
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<string>, IFeedbackForm>) {
  try {
    yield customAxiosCall(customAxios.post, endpoints.feedback, body)
    yield put(feedbackActions.success())
    if (successFunc) {
      successFunc()
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    yield put(feedbackActions.failure(errors || statusText))
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: statusText,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

function* userSagas() {
  yield all([
    takeLatest(USER.REQUEST, getUsersShared),
    takeLatest(CURRENT_USER.REQUEST, getCurrentUser),
    takeLatest(CURRENT_USER.GET, getOauthTokens),
    takeLatest(CURRENT_USER.UPDATE, updateCurrentUser),
    takeLatest(FEEDBACK.REQUEST, sendFeedback),
  ])
}

export default userSagas
