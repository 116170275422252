import React, { ChangeEvent } from 'react'
import { TContainer } from 'types'
import { Button, ButtonGroup } from '@rhythm/buttons'
import styled from 'styled-components'
import { Checkbox } from '@rhythm/inputs'
import { getColor } from '@rhythm/theme'
import { userCurrentUserSelector, userFetchingSelector } from 'selectors'
import { useDispatch, useSelector } from 'react-redux'
import { Skeleton } from '@rhythm/loading'
import { FlexContainer } from 'static'
import { currentUserActions } from 'actions'

interface IProps {
  body: TContainer
  footer: TContainer
  onClose: () => void
  onApply: () => void
}

const Message = styled.span`
  color: ${getColor('greyDarkest')};
  font-size: 0.875rem;
  padding-bottom: 1rem;
`

const MessageWithCheck = ({ onApply, onClose, body: Body, footer: Footer }: IProps) => {
  const user = useSelector(userCurrentUserSelector)
  const fetching = useSelector(userFetchingSelector)
  const dispatch = useDispatch()
  const onSend = () => {
    onApply()
    onClose()
  }
  const changeExportFlag = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
    dispatch(
      currentUserActions.update({
        data: {
          show_export_sign_in_modal: !checked,
        },
      })
    )
  return user ? (
    <>
      <Body>
        <FlexContainer column>
          <Message>
            To complete exporting you have to be authorized to your Salesloft account.
          </Message>
          <Checkbox
            disabled={fetching}
            data-testid="chkNotShow"
            checked={!user.show_export_sign_in_modal}
            onChange={changeExportFlag}
          >
            Don’t show this window again
          </Checkbox>
        </FlexContainer>
      </Body>
      <Footer>
        <ButtonGroup>
          <Button data-testid="btnClose" loading={fetching} variant="neutral" onClick={onClose}>
            Cancel
          </Button>
          <Button data-testid="btnLoginToSL" loading={fetching} onClick={onSend}>
            Login to Salesloft
          </Button>
        </ButtonGroup>
      </Footer>
    </>
  ) : (
    <Skeleton />
  )
}

export default MessageWithCheck
