import React from 'react'
import styled, { css } from 'styled-components'
import { BlockContainer } from 'components'
import { Button } from '@rhythm/buttons'
import { Link } from 'react-router-dom'
import { FlexContainer } from 'static'
import { getColor, getFontWeight } from '@rhythm/theme'
import { Helmet } from 'react-helmet'

const Text = styled.span`
  margin-top: 4rem;
  margin-bottom: 6rem;
  font-size: 0.9rem;
  color: ${getColor('greyDark')};
`

const CloseButton = styled(Button)`
  padding: 1.2rem 0;
  width: 100%;
  font-size: 1rem;
  font-weight: ${getFontWeight('medium')};
`

const CustomLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
  margin-bottom: 4rem;
  width: 100%;
`
const Container = styled(FlexContainer)`
  margin-top: 12rem;
`

const BlockContainerStyles = css`
  max-width: 26rem;
  padding: 3.5rem 2.5rem;
`

const Check = () => (
  <Container justifyContent="center">
    <Helmet>
      <title>Confirmation</title>
    </Helmet>
    <BlockContainer styles={BlockContainerStyles}>
      <Text>
        You&apos;re almost there! Check your email or spam folder for a link to activate your
        account
      </Text>
      <CustomLink to="/signin">
        <CloseButton>Close Tab</CloseButton>
      </CustomLink>
    </BlockContainer>
  </Container>
)

export default Check
