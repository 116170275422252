import React from 'react'
import styled from 'styled-components'
import { getColor, getTransparentizedColor, getFontWeight } from '@rhythm/theme'
import { FlexContainer } from 'static'
import { ActionIcon } from 'components'
import { IStepType } from 'types'

const TypeContainer = styled(FlexContainer)<{ selected: boolean }>`
  box-sizing: border-box;
  height: 48px;
  width: 190px;
  border: 1px solid;
  border-color: ${({ selected }) => getTransparentizedColor('greyLight', selected ? 1 : 0.5)};
  border-radius: 4px;
  padding-left: 16px;
  color: ${getColor('greyDarkest')};
  font-size: 14px;
  font-weight: ${getFontWeight('semibold')};
  gap: 8px;
  cursor: pointer;
  background-color: ${({ selected }) =>
    selected ? getTransparentizedColor('greyLight', 0.5) : getColor('white')};

  &:hover {
    background-color: ${getTransparentizedColor('greyLight', 0.5)};
    border: 1px solid ${getTransparentizedColor('greyLight', 1)};
  }
`

const StepType = ({ selectedType, type, handleClickOnType }: IStepType) => {
  const lowerCaseType = type.toLowerCase()

  return (
    <TypeContainer
      alignItems="center"
      selected={selectedType === type}
      onClick={() => handleClickOnType(type)}
    >
      <ActionIcon type={lowerCaseType} />
      {type}
    </TypeContainer>
  )
}

export default StepType
