import React from 'react'
import styled from 'styled-components'
import { getColor, getFontWeight } from '@rhythm/theme'
import { Button } from '@rhythm/buttons'
import { Helmet } from 'react-helmet'
import { FlexContainer, AppLogoDarkGreen, LandingPageSvgGreen } from 'static'
import { useHistory } from 'react-router-dom'
import { AppLink } from 'components'

const DESCRIPTION =
  'Cadence Engine gets you up and running fast with a library of multi-channel cadence templates for sales, marketing, and customer success. Build Cadences with data-optimized cadence structures and content tailored for what you sell and who you sell to.'

const DESCRIPTION_LIST = [
  'Create 40 cadences to generate demand, build pipeline, win opportunities, drive customer success, and manage accounts',
  'Use our recommended cadence intensities and durations or customize to suit your use cases',
  'Export cadences directly to Salesloft or to a variety of file formats.',
]

const Logo = styled.img`
  width: 353px;
  height: 115px;
  margin-top: 21px;
  margin-right: 17px;
`

const LandingBackground = styled(FlexContainer)`
  position: absolute;
  top: 50%;
  background-color: #054a2e;
  height: 300px;
  width: 100%;
  margin-top: -186px;
  gap: 52px;
  padding-right: 2px;
  padding-top: 32px;
`

const Description = styled.div`
  color: ${getColor('white')};
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  width: 520px;
  min-width: 520px;
  font-family: 'Hind', sans-serif;
`

const LandingPicture = styled.img`
  width: 608px;
  height: 345px;
`

const ListHeader = styled.div`
  padding: 5px 0;
  font-family: 'Hind', sans-serif;
`

const List = styled.ul`
  margin: 0;
  padding-bottom: 5px;
  padding-left: 25px;
  list-style-position: inside;
`

const StyledButton = styled(Button)`
  margin-top: 50px;
  background: #b5d626;
  color: #0a2417;
  border-color: #b5d626;
  padding: 19px 16px;
  &:focus:not([disabled]),
  &:active:not([disabled]),
  &:hover:not([disabled]) {
    background: #b5d626;
    color: #0a2417;
    border-color: #b5d626;
  }
`

const ListItem = styled.span`
  margin-left: -10px;
  font-family: 'Hind', sans-serif;
`

const FeedbackFooter = styled(FlexContainer)`
  position: absolute;
  bottom: 0;
  color: #303236;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 34px;
  font-family: 'Hind', sans-serif;
`

const StyledLink = styled(AppLink)`
  font-weight: ${getFontWeight('semibold')};
  color: #19191a;
  text-decoration: none;
  &:focus:not([disabled]),
  &:active:not([disabled]),
  &:hover:not([disabled]) {
    color: #19191a;
    text-decoration: underline;
  }
  font-family: 'Hind', sans-serif;
`

const LandingPage = () => {
  const history = useHistory()

  return (
    <>
      <Helmet>
        <title>Landing</title>
      </Helmet>
      <FlexContainer justifyContent="center">
        <Logo src={AppLogoDarkGreen} />
      </FlexContainer>
      <LandingBackground justifyContent="center">
        <LandingPicture aria-label="Landing Picture" src={LandingPageSvgGreen} />
        <Description>
          {DESCRIPTION}
          <ListHeader>Here is why we love Cadence Engine and we think you will too:</ListHeader>
          <List>
            {DESCRIPTION_LIST.map((item: string) => (
              <li key={item}>
                <ListItem>{item}</ListItem>
              </li>
            ))}
          </List>
          Oh, and more more thing… it&apos;s completely free!
          <StyledButton onClick={() => history.push('/signin')}>Let’s Get Started</StyledButton>
        </Description>
      </LandingBackground>
      <FlexContainer justifyContent="center">
        <FeedbackFooter>
          Need help or have feedback? Please complete our&nbsp;
          <StyledLink
            aria-label="Go to Feedback"
            to="feedback"
            target="_blank"
            rel="noopener noreferrer"
          >
            Support Form
          </StyledLink>
          . To learn more,&nbsp;
          <StyledLink
            useHref
            aria-label="Learn More"
            to="https://salesloft.com/company/"
            target="_blank"
            rel="noopener noreferrer"
          >
            click here
          </StyledLink>
          !
        </FeedbackFooter>
      </FlexContainer>
    </>
  )
}

export default LandingPage
