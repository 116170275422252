import React from 'react'
import { reduxForm, Field, InjectedFormProps } from 'redux-form'
import { Button } from '@rhythm/buttons'
import { IUserSignup } from 'types'
import { FlexContainer, flex, flexCol } from 'static'
import styled from 'styled-components'
import { getColor, getFontWeight } from '@rhythm/theme'
import { AppLink, PasswordFiledVisualValidation, FormInput } from 'components'

interface IProps {
  fetching?: boolean
}

const Form = styled.form`
  ${flex};
  ${flexCol}
`
const SubmitButton = styled(Button)`
  padding: 1.3rem 3.9rem;
  font-weight: ${getFontWeight('semibold')};
  font-size: 1rem;
`
const LinkBlock = styled.div`
  color: ${getColor('grey')};
`
const BlockContainer = styled(FlexContainer)<{ marginR?: number; marginL?: number }>`
  margin: 0 ${({ marginR }) => (marginR ? `${marginR}rem` : 0)} 2rem
    ${({ marginL }) => (marginL ? `${marginL}rem` : 0)};
`

const SignupForm = ({
  handleSubmit,
  submitting,
  fetching = false,
}: InjectedFormProps<IUserSignup, IProps> & IProps) => (
  <Form onSubmit={handleSubmit}>
    <FlexContainer>
      <BlockContainer column marginR={1}>
        <Field
          name="first_name"
          component={FormInput}
          placeholder="First Name"
          type="text"
          maxLength="60"
          noSetCustomValidity
        />
        <Field
          name="last_name"
          component={FormInput}
          placeholder="Last Name"
          type="text"
          maxLength="60"
          noSetCustomValidity
        />
        <Field
          name="email"
          component={FormInput}
          placeholder="Email"
          type="text"
          maxLength="120"
          noSetCustomValidity
        />
      </BlockContainer>
      <BlockContainer column marginL={1} justifyContent="space-between">
        <Field
          name="password"
          component={PasswordFiledVisualValidation}
          placeholder="Password"
          type="password"
          maxLength="120"
          noSetCustomValidity
        />
        <Field
          name="password_confirmation"
          component={FormInput}
          placeholder="Confirm Password"
          type="password"
          maxLength="120"
          noSetCustomValidity
        />
      </BlockContainer>
    </FlexContainer>
    <FlexContainer alignItems="center" justifyContent="space-between">
      <LinkBlock>
        Already have an account? <AppLink to="/signin">Sign in</AppLink>
      </LinkBlock>
      <SubmitButton type="submit" loading={submitting || fetching}>
        Submit
      </SubmitButton>
    </FlexContainer>
  </Form>
)

export default reduxForm<IUserSignup, IProps>({
  form: 'signup',
  initialValues: {
    email: '',
    first_name: '',
    last_name: '',
    password_confirmation: '',
  },
})(SignupForm)
