import { IAction } from 'types'

type ActionType = 'GET' | 'REQUEST' | 'UPDATE' | 'DELETE' | 'SUCCESS' | 'FAILURE'

const defaultActionTypes: Record<ActionType, string> = {
  GET: '',
  REQUEST: '',
  UPDATE: '',
  DELETE: '',
  SUCCESS: '',
  FAILURE: '',
}

export const createActionTypes = (base: string) =>
  Object.keys(defaultActionTypes).reduce(
    (types, type) => ({
      ...types,
      [type]: `${base}_${type}`,
    }),
    defaultActionTypes
  )

export const createAction = <P = Record<string, never>, B = Record<string, never>>(
  type: string,
  payload: P | Record<string, never> = {},
  body: B | Record<string, never> = {},
  successFunc?: (data?: any) => void,
  errorFunc?: (error?: any) => void
): IAction<P, B> => ({
  type,
  payload,
  body,
  successFunc,
  errorFunc,
})
