import { initialState } from 'static'
import { Action, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { IAppState } from 'types'
import { SIGNOUT } from 'actions'
import userReducer from './user'
import appMessageReducer from './appMessage'
import motionsReducer from './motions'
import cadencesReducer from './cadences'
import cadenceReducer from './cadence'
import appModalReducer from './appModal'
import cadenceQuestionsReducer from './cadenceQuestions'

const combinedReducer = combineReducers({
  user: userReducer,
  appMessage: appMessageReducer,
  motions: motionsReducer,
  cadences: cadencesReducer,
  appModal: appModalReducer,
  form: formReducer,
  cadence: cadenceReducer,
  cadenceQuestions: cadenceQuestionsReducer,
})

const rootReducer = () => (state: IAppState = initialState, action: Action) => {
  let newState = state
  if (action.type === 'CLEAN' || action.type === SIGNOUT.SUCCESS) {
    newState = initialState
  }
  return combinedReducer(newState, action)
}

export default rootReducer
