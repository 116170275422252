import React, { useMemo } from 'react'
import styled from 'styled-components'
import { CheckmarkIcon, ChevronSmDownIcon } from '@rhythm/svgs'
import { Button } from '@rhythm/buttons'
import { Menu, MenuBody, MenuDropdown, MenuItem, MenuToggle } from '@rhythm/menu'
import { IMotionsFilter } from 'types'
import { queryToJson } from 'utils'
import { useLocation } from 'react-router-dom'

type Objective = {
  name: string
  id: number
}

type MenuContextProps = {
  openMenu: () => void
}

const FilterContainer = styled.div`
  box-sizing: border-box;
  padding: 8px 0;
  padding-right: 24px;
`

const objectives = [
  {
    name: 'Generate Demand',
    id: 1,
  },
  {
    name: 'Build Pipeline',
    id: 2,
  },
  {
    name: 'Manage & Close Opportunities',
    id: 3,
  },
  {
    name: 'Drive Success',
    id: 4,
  },
  {
    name: 'Account Management',
    id: 5,
  },
]

const MotionsFilter = ({ handleChangeObjective }: IMotionsFilter) => {
  const { search } = useLocation()
  const { name } = useMemo(() => queryToJson(search), [search])

  return (
    <FilterContainer>
      <Menu>
        <MenuToggle>
          {({ openMenu }: Partial<MenuContextProps>) => (
            <Button
              onClick={openMenu}
              iconLeft={<ChevronSmDownIcon />}
              aria-label="filter icon button"
            >
              Objective: {name || 'All Types'}
            </Button>
          )}
        </MenuToggle>
        <MenuDropdown horizontalPosition="left" verticalPosition="down">
          <MenuBody maxWidth={255} maxHeight={152}>
            <MenuItem
              onClick={() => handleChangeObjective()}
              highlighted={!name}
              icon={!name ? CheckmarkIcon : undefined}
              autoClose
            >
              All Types
            </MenuItem>
            {objectives.map((objective: Objective) => (
              <MenuItem
                key={objective.id}
                // @ts-expect-error
                onClick={handleChangeObjective(objective.name)}
                highlighted={name === objective.name}
                icon={name === objective.name ? CheckmarkIcon : undefined}
                autoClose
              >
                {objective.name}
              </MenuItem>
            ))}
          </MenuBody>
        </MenuDropdown>
      </Menu>
    </FilterContainer>
  )
}

export default MotionsFilter
