import { CADENCE_QUESTION_ANSWERS } from 'actions/types'
import { ICadenceQuestionsState } from 'types'
import { CADENCE_QUESTIONS } from 'actions'

export const initialState: ICadenceQuestionsState = {
  fetching: false,
  success: false,
  error: null,
  questions: null,
  answers: null,
  question: null,
  libAnswers: null,
}

export default function cadenceQuestionsReducer(
  state = initialState,
  { type, payload }: { type: string; payload: Record<string, unknown> }
) {
  switch (type) {
    case CADENCE_QUESTIONS.REQUEST:
    case CADENCE_QUESTIONS.UPDATE:
    case CADENCE_QUESTIONS.GET:
    case CADENCE_QUESTIONS.SUCCESS:
    case CADENCE_QUESTIONS.FAILURE:
    case CADENCE_QUESTION_ANSWERS.GET:
    case CADENCE_QUESTION_ANSWERS.FAILURE:
    case CADENCE_QUESTION_ANSWERS.SUCCESS:
    case CADENCE_QUESTION_ANSWERS.REQUEST:
    case CADENCE_QUESTION_ANSWERS.DELETE: {
      return {
        ...state,
        ...payload,
      }
    }
    default:
      return state
  }
}
