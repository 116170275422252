import React, { InputHTMLAttributes, useCallback } from 'react'
import styled from 'styled-components'
import { WrappedFieldProps } from 'redux-form'
import { TextField } from '@rhythm/inputs'
import { FlexContainer } from 'static'

interface IProps {
  noSetCustomValidity?: boolean
  inputSize?: 'small' | 'default' | 'large'
  containerHeight?: string
  withoutError?: boolean
}

const InputContainer = styled(FlexContainer)<{
  containerHeight?: string
}>`
  height: ${({ containerHeight }) => containerHeight};
`

const FormInput = ({
  meta: { touched, error },
  input: { onBlur, ...input },
  noSetCustomValidity = false,
  withoutError = false,
  inputSize = 'large',
  width = '23rem',
  height = '3rem',
  containerHeight = '4.5rem',
  ...props
}: WrappedFieldProps & InputHTMLAttributes<HTMLInputElement> & IProps) => {
  const onBlurCallback = useCallback(
    (event) => {
      if (!noSetCustomValidity) {
        event.target.setCustomValidity(error || '')
      }
      onBlur(event)
    },
    [error, onBlur, noSetCustomValidity]
  )
  return (
    <InputContainer containerHeight={containerHeight}>
      <TextField
        {...props}
        {...input}
        hasError={touched && error}
        errorMessage={!withoutError && error}
        onBlur={onBlurCallback}
        /* @ts-ignore */
        size={inputSize}
        width={width}
        height={height}
      />
    </InputContainer>
  )
}

export default FormInput
