import {
  ISigninForm,
  IUserSignup,
  IUserSignupError,
  IForgotPassword,
  IPasswordRecovery,
  TPasswordRecoveryError,
} from 'types'
import { createAction } from 'utils'
import {
  SIGNUP,
  SIGNIN,
  SIGNOUT,
  FORGOT_PASSWORD,
  PASSWORD_RECOVERY,
  SIGNIN_SALESLOFT,
} from './types'

const signupActions = {
  request: ({
    user,
    successFunc,
    errorFunc,
    historyPush,
  }: {
    user: IUserSignup
    successFunc?: () => void
    errorFunc?: (error: IUserSignupError) => void
    historyPush: (to: string) => void
  }) =>
    createAction(
      SIGNUP.REQUEST,
      {
        fetching: true,
        success: false,
        error: null,
      },
      {
        user,
        historyPush,
      },
      successFunc,
      errorFunc
    ),
  success: () =>
    createAction(SIGNUP.SUCCESS, {
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error: IUserSignupError) =>
    createAction(SIGNUP.FAILURE, {
      error,
      fetching: false,
      success: false,
    }),
}

const signinActions = {
  request: ({
    signinInfo,
    successFunc,
    errorFunc,
  }: {
    signinInfo: ISigninForm
    successFunc?: () => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      SIGNIN.REQUEST,
      {
        fetching: true,
        success: false,
        error: null,
        user: null,
      },
      {
        user: signinInfo,
      },
      successFunc,
      errorFunc
    ),
  success: (authToken: string) =>
    createAction(SIGNIN.SUCCESS, {
      authToken,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error: string) =>
    createAction(SIGNIN.FAILURE, {
      error,
      fetching: false,
      success: false,
      user: null,
    }),
}

const signoutActions = {
  request: ({
    successFunc,
    errorFunc,
  }: {
    successFunc?: () => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      SIGNOUT.REQUEST,
      {
        fetching: true,
        success: false,
        error: null,
      },
      {},
      successFunc,
      errorFunc
    ),
  success: () =>
    createAction(SIGNOUT.SUCCESS, {
      authToken: null,
      fetching: false,
      success: true,
      error: null,
      user: null,
    }),
  failure: (error: string) =>
    createAction(SIGNOUT.FAILURE, {
      error,
      fetching: false,
      success: false,
    }),
}

const forgotPasswordActions = {
  request: ({
    value: user,
    successFunc,
    errorFunc,
  }: {
    value: IForgotPassword
    successFunc?: () => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      FORGOT_PASSWORD.REQUEST,
      {
        fetching: true,
        success: false,
        error: null,
      },
      {
        user,
      },
      successFunc,
      errorFunc
    ),
  success: () =>
    createAction(FORGOT_PASSWORD.SUCCESS, {
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error: string) =>
    createAction(FORGOT_PASSWORD.FAILURE, {
      error,
      fetching: false,
      success: false,
    }),
}

const passwordRecoveryActions = {
  request: ({
    value: user,
    successFunc,
    errorFunc,
  }: {
    value: IPasswordRecovery
    successFunc?: () => void
    errorFunc?: (error: TPasswordRecoveryError) => void
  }) =>
    createAction(
      PASSWORD_RECOVERY.REQUEST,
      {
        fetching: true,
        success: false,
        error: null,
      },
      {
        user,
      },
      successFunc,
      errorFunc
    ),
  success: () =>
    createAction(PASSWORD_RECOVERY.SUCCESS, {
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error: TPasswordRecoveryError) =>
    createAction(PASSWORD_RECOVERY.FAILURE, {
      error,
      fetching: false,
      success: false,
    }),
}

const signinSalesLoftActions = {
  request: () =>
    createAction(SIGNIN_SALESLOFT.REQUEST, {
      fetching: true,
      success: false,
      error: null,
    }),
  success: () =>
    createAction(SIGNIN_SALESLOFT.SUCCESS, {
      fetching: false,
      success: true,
      error: null,
    }),
  failure: () => createAction(SIGNIN_SALESLOFT.FAILURE, { fetching: false, success: false }),
}

export {
  signinActions,
  signupActions,
  signoutActions,
  forgotPasswordActions,
  passwordRecoveryActions,
  signinSalesLoftActions,
}
