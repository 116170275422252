import React from 'react'
import { BlockContainer, SignupForm } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { signupActions } from 'actions'
import { FlexContainer } from 'static'
import styled, { css } from 'styled-components'
import { userFetchingSelector } from 'selectors'
import { IUserSignup } from 'types'
import { Helmet } from 'react-helmet'
import { getColor } from '@rhythm/theme'
import { change, stopAsyncValidation } from 'redux-form'
import { RouteComponentProps } from 'react-router-dom'
import { parseErrorsToFormErrors } from 'utils'

const Container = styled(FlexContainer)`
  margin-top: 12rem;
`

const Title = styled.span`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
  color: ${getColor('greyDark')};
`

const BlockContainerStyles = css`
  padding: 4rem 5rem;
`

const Signup = ({ history }: RouteComponentProps) => {
  const dispatch = useDispatch()
  const userFetching = useSelector(userFetchingSelector)
  const signup = (user: IUserSignup) => {
    dispatch(
      signupActions.request({
        user,
        successFunc: () => history.push('check'),
        errorFunc: (errors) => {
          if (errors.password_confirmation || errors.password) {
            dispatch(change('signup', 'password_confirmation', ''))
            if (errors.password) {
              dispatch(change('signup', 'password', ''))
            }
          }
          dispatch(stopAsyncValidation('signup', parseErrorsToFormErrors({ ...errors })))
        },
        historyPush: history.push,
      })
    )
  }

  return (
    <Container justifyContent="center">
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <BlockContainer styles={BlockContainerStyles}>
        <Title>Create your Cadence Engine Account</Title>
        <SignupForm onSubmit={signup} fetching={userFetching} />
      </BlockContainer>
    </Container>
  )
}

export default Signup
