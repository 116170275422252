import React from 'react'
import { reduxForm, Field, InjectedFormProps } from 'redux-form'
import { Button } from '@rhythm/buttons'
import { getFontWeight } from '@rhythm/theme'
import { IChangePasswordForm } from 'types'
import { flex, flexCol } from 'static'
import styled from 'styled-components'
import FormInput from '../FormInput/FormInput'
import PasswordFiledVisualValidation from '../PasswordFiledVisualValidation/PasswordFiledVisualValidation'

interface IProps {
  fetching?: boolean
}

const Form = styled.form`
  ${flex};
  ${flexCol}
`
const SubmitButton = styled(Button)`
  padding: 1.3rem 3.9rem;
  font-weight: ${getFontWeight('semibold')};
  font-size: 1rem;
`

const FormFieldContainer = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
`

const PasswordRecoveryForm = ({
  handleSubmit,
  submitting,
  fetching = false,
}: InjectedFormProps<IChangePasswordForm, IProps> & IProps) => (
  <Form onSubmit={handleSubmit}>
    <Field
      name="password"
      component={PasswordFiledVisualValidation}
      placeholder="New Password"
      type="password"
      maxLength="120"
      noSetCustomValidity
    />
    <FormFieldContainer>
      <Field
        name="password_confirmation"
        component={FormInput}
        placeholder="Confirm Password"
        type="password"
        maxLength="120"
        noSetCustomValidity
      />
    </FormFieldContainer>
    <SubmitButton type="submit" loading={submitting || fetching}>
      Reset Password
    </SubmitButton>
  </Form>
)

export default reduxForm<IChangePasswordForm, IProps>({
  form: 'passwordRecovery',
  initialValues: {
    password_confirmation: '',
  },
})(PasswordRecoveryForm)
