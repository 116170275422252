import Qs from 'qs'
import axios, { AxiosResponse } from 'axios'
import { call, put } from 'redux-saga/effects'
import { localStorageAuthToken } from 'utils'

const customAxios = axios.create({
  baseURL: window.origin,
})

customAxios.interceptors.request.use(
  (config) => {
    const token = localStorageAuthToken.get()
    const newConfig = {
      ...config,
    }
    if (token) {
      newConfig.headers = {
        ...newConfig.headers,
        Authorization: token,
      }
    }
    if (newConfig.params) {
      newConfig.paramsSerializer = (params) => Qs.stringify(params, { arrayFormat: 'brackets' })
    }
    return newConfig
  },
  (error) => Promise.reject(error)
)

customAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorageAuthToken.remove()
    }
    return Promise.reject(
      !error.response
        ? ({
            response: {
              data: {},
              statusText: 'Network Error',
              status: '',
            },
            ...new Error('Network Error'),
          } as Error)
        : error
    )
  }
)

// eslint-disable-next-line @typescript-eslint/no-shadow
function* customAxiosCall<F extends (...args: never[]) => Promise<AxiosResponse>>(
  func: F,
  ...args: Parameters<F>
) {
  try {
    return yield call(func, ...args)
  } catch (error) {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.config.url !== '/api/v1/users/sign_in'
    ) {
      localStorageAuthToken.remove()
      yield put({ type: 'CLEAN' })
    }
    if (
      error.response &&
      error.response.status !== '' &&
      error.response.status !== 401 &&
      (!error.response.data || !error.response.data.errors)
    ) {
      switch (error.response.status) {
        case 404:
          window.location.href = '/404'
          break
        case 501:
          window.location.href = '/501'
          break
        default:
          window.location.href = '/error'
          break
      }
    }
    throw error
  }
}

export { customAxios, customAxiosCall }
