import React from 'react'
import { Modal, ModalBody, ModalFooter, setAppElementForModal } from '@rhythm/modals'
import { Skeleton } from '@rhythm/loading'
import { modalDetailsSelector } from 'selectors'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from 'actions/common'
import styled from 'styled-components'
import { FlexContainer } from 'static'

if (process.env.NODE_ENV !== 'test') {
  setAppElementForModal('#root')
}

const BodyContainer = styled(FlexContainer)`
  padding: 0.5rem 0rem;
`

const ModalHandler = () => {
  const modal = useSelector(modalDetailsSelector)
  const dispatch = useDispatch()
  const onClose = () => {
    if (modal?.onClose) {
      modal.onClose()
    }
    dispatch(modalActions.delete())
  }
  return modal ? (
    <Modal
      isOpen
      onRequestClose={onClose}
      title={modal.title}
      zIndex={2050}
      loading={modal.loading}
      minWidth={modal.width}
    >
      {(data: { loading: boolean }) => {
        if (data.loading) {
          return (
            <>
              <ModalBody>
                <Skeleton />
              </ModalBody>
              <ModalFooter>
                <Skeleton />
              </ModalFooter>
            </>
          )
        }
        return modal.body({
          body: (args) => (
            <ModalBody>
              <BodyContainer>{args.children}</BodyContainer>
            </ModalBody>
          ),
          footer: ModalFooter,
          onClose,
        })
      }}
    </Modal>
  ) : null
}

export default ModalHandler
