import { compose, createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { IAppState } from './types'
import rootReducer from './reducers'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const sagaMiddleware = createSagaMiddleware()

const configureStore = (initialState: IAppState) => {
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose

  const middlewares = [sagaMiddleware]

  const enhancers = [applyMiddleware(...middlewares)]

  const store = createStore(rootReducer(), initialState, composeEnhancers(...enhancers))

  return {
    ...store,
    runSaga: sagaMiddleware.run,
    close: () => store.dispatch({ type: 'CLEAN' }),
  }
}

export default configureStore
