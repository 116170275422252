import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Check, ErrorPage, CadencesListTabs, LandingPage, ProfilePage } from 'components'
import {
  Signin,
  Signup,
  ForgotPassword,
  PasswordRecovery,
  AuthHandler,
  Cadence,
  Feedback,
} from 'containers'

const App = () => (
  <Switch>
    <Route path="/" exact component={AuthHandler(LandingPage, true)} />
    <Route path="/signup" component={AuthHandler(Signup, true)} />
    <Route path="/signin" component={AuthHandler(Signin, true)} />
    <Route path="/check" component={Check} />
    <Route path="/cadenceList" component={AuthHandler(CadencesListTabs)} />
    <Route path="/forgotPassword" component={AuthHandler(ForgotPassword, true)} />
    <Route path="/passwordRecovery" component={AuthHandler(PasswordRecovery, true, true)} />
    <Route path="/cadences/:id" component={AuthHandler(Cadence)} />
    <Route path="/profile" component={AuthHandler(ProfilePage)} />
    <Route path="/feedback" component={AuthHandler(Feedback, true)} />
    <Route path="/404" render={(props) => <ErrorPage errorType="404" {...props} />} />
    <Route path="/501" render={(props) => <ErrorPage errorType="501" {...props} />} />
    <Route path="/error" render={(props) => <ErrorPage errorType="universal" {...props} />} />
    <Route>
      <Redirect to="/404" />
    </Route>
  </Switch>
)

export default App
