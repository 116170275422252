import { all, put, takeLatest } from 'redux-saga/effects'
import {
  SIGNUP,
  signupActions,
  messageActions,
  SIGNIN,
  SIGNOUT,
  FORGOT_PASSWORD,
  PASSWORD_RECOVERY,
  forgotPasswordActions,
  passwordRecoveryActions,
  signinActions,
  signoutActions,
} from 'actions'
import {
  IAction,
  IForgotPassword,
  IPasswordRecovery,
  IUserSignup,
  IUserSignupError,
  IActionDefaultPayload,
  ISigninForm,
} from 'types'
import { change, reset } from 'redux-form'
import { customAxios, customAxiosCall, localStorageAuthToken, parseBackendErrors } from 'utils'

const endpoints = {
  users: '/api/v1/users',
  signin: '/api/v1/users/sign_in',
  signout: '/api/v1/users/sign_out',
  forgetPassword: '/api/v1/users/password',
}

export function* handleSignup({
  body,
  successFunc,
  errorFunc,
}: IAction<
  IActionDefaultPayload<IUserSignupError | null>,
  { user: IUserSignup; historyPush: (to: string) => void }
>) {
  try {
    yield customAxiosCall(customAxios.post, endpoints.users, { user: body.user })
    yield put(signupActions.success())
    yield put(reset('signin'))
    if (successFunc) {
      successFunc()
    }
  } catch ({
    response: {
      data: { errors },
      message,
    },
  }) {
    let parsedErrors = { ...errors }
    let messageBody = ''
    let actionText = ''
    let actionCallback
    parsedErrors = parseBackendErrors(parsedErrors, ({ required }) => {
      if (required && !messageBody) {
        messageBody = 'All fields are required'
      }
    })
    if (!body.user.password_confirmation) {
      parsedErrors.password_confirmation = {
        required: 'Required',
      }
      messageBody = 'All fields are required'
    }
    if (parsedErrors.email?.not_unique && !messageBody) {
      messageBody = 'The account with this email already exist.'
      actionText = 'Sign in'
      actionCallback = () => body.historyPush('/signin')
    }
    yield put(signupActions.failure(parsedErrors))
    if (errorFunc) {
      errorFunc(parsedErrors)
    }
    if (messageBody || message) {
      yield put(
        messageActions.update({
          message: {
            type: 'danger',
            body: messageBody || 'Something went wrong',
            actionText,
            actionCallback,
          },
        })
      )
    }
  }
}

export function* handleSignin({
  body,
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<null>, { user: ISigninForm }>) {
  try {
    const data = yield customAxiosCall(customAxios.post, endpoints.signin, body)
    localStorageAuthToken.set(data.headers.authorization)
    yield put(signinActions.success(data.headers.authorization))
    yield put(reset('signin'))
    if (successFunc) {
      successFunc()
    }
  } catch ({
    response: {
      data: { error },
      message,
    },
  }) {
    yield put(change('signin', 'password', '', false))
    yield put(signinActions.failure(message))
    if (error.indexOf('confirm') === -1) {
      yield put(
        messageActions.update({
          message: {
            body: error,
            type: 'danger',
          },
        })
      )
    }
    if (errorFunc) {
      errorFunc(error)
    }
  }
}

export function* handleSignout({
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<null>, Record<string, never>>) {
  try {
    yield customAxiosCall(customAxios.delete, endpoints.signout)
    localStorageAuthToken.remove()
    yield put(signoutActions.success())
    if (successFunc) {
      successFunc()
    }
  } catch ({
    response: {
      data: { error },
      message,
    },
  }) {
    yield put(signoutActions.failure(message))
    if (errorFunc) {
      errorFunc(error)
    }
  }
}

export function* handleForgetPassword({
  body,
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<null>, { user: IForgotPassword }>) {
  try {
    yield customAxiosCall(customAxios.post, endpoints.forgetPassword, body)
    yield put(forgotPasswordActions.success())
    if (successFunc) {
      successFunc()
    }
  } catch ({
    response: {
      data: { error },
      message,
    },
  }) {
    yield put(forgotPasswordActions.failure(message))
    if (errorFunc) {
      errorFunc(error)
    }
  }
}

export function* handlePasswordRecovery({
  body,
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<null>, { user: IPasswordRecovery }>) {
  try {
    yield customAxiosCall(customAxios.put, endpoints.forgetPassword, body)
    yield put(passwordRecoveryActions.success())
    if (successFunc) {
      successFunc()
    }
    yield put(
      messageActions.update({
        message: {
          type: 'confirm',
          body: 'Your password has been successfully reset!',
        },
      })
    )
  } catch ({
    response: {
      data: { errors },
      message,
    },
  }) {
    let parsedErrors = { ...errors }
    let messageBody: string | JSX.Element = ''
    parsedErrors = parseBackendErrors(parsedErrors, ({ required }) => {
      if (required && !messageBody) {
        messageBody = 'All fields are required'
      }
    })
    if (!body.user.password_confirmation || !body.user.password) {
      if (!body.user.password_confirmation) {
        parsedErrors.password_confirmation = {
          required: 'Required',
        }
      }
      if (!body.user.password) {
        parsedErrors.password = {
          required: 'Required',
        }
      }
      messageBody = 'All fields are required'
    }
    yield put(passwordRecoveryActions.failure(parsedErrors))
    if (messageBody || message) {
      yield put(
        messageActions.update({
          message: {
            type: 'danger',
            body: messageBody || 'Something went wrong',
          },
        })
      )
    }
    if (errorFunc) {
      errorFunc(parsedErrors)
    }
  }
}

function* authSagas() {
  yield all([
    takeLatest(SIGNUP.REQUEST, handleSignup),
    takeLatest(SIGNIN.REQUEST, handleSignin),
    takeLatest(SIGNOUT.REQUEST, handleSignout),
    takeLatest(FORGOT_PASSWORD.REQUEST, handleForgetPassword),
    takeLatest(PASSWORD_RECOVERY.REQUEST, handlePasswordRecovery),
  ])
}

export default authSagas
