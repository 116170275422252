import { createSelector } from 'reselect'
import { IAppState } from 'types'

const selector = ({ cadenceQuestions }: IAppState) => cadenceQuestions

const cadenceQuestionsFetchingSelector = createSelector(selector, ({ fetching }) => fetching)

const cadenceQuestionsErrorSelector = createSelector(selector, ({ error }) => error)

const cadenceQuestionsSelector = createSelector(selector, ({ questions }) => questions)

const cadenceQuestionsAnswersSelector = createSelector(selector, ({ answers }) => answers)

const cadenceQuestionsLibAnswersSelector = createSelector(selector, ({ libAnswers }) => libAnswers)

export {
  cadenceQuestionsSelector,
  cadenceQuestionsAnswersSelector,
  cadenceQuestionsErrorSelector,
  cadenceQuestionsFetchingSelector,
  cadenceQuestionsLibAnswersSelector,
}
