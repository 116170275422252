import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal, ModalBody, ModalFooter } from '@rhythm/modals'
import { ButtonGroup, Button } from '@rhythm/buttons'
import { ICreateStepModal } from 'types'
import { useDispatch, useSelector } from 'react-redux'
import { cadenceStepsActions, cadenceStepActions, cadenceActions } from 'actions'
import { cadenceFetchingSelector } from 'selectors'

import CreateEmailStep from './CreateEmailStep'
import CreateCallSocialStep from './CreateCallSocialStep'

const StyledModalBody = styled(ModalBody)`
  padding: 0;
`

const CreateStepModal = ({
  isOpen,
  cadenceId,
  type,
  selectedDay,
  name,
  description,
  stepId,
  subject,
  order,
  handleCloseModal,
  handleCloseAddStepModal,
}: ICreateStepModal) => {
  const [stepName, setStepName] = useState(name || '')
  const [stepDescription, setStepDescription] = useState(description || '')
  const [stepType, setStepType] = useState(type)
  const [emailSubject, setEmailSubject] = useState(subject || '')
  const dispatch = useDispatch()
  const cadenceFetching = useSelector(cadenceFetchingSelector)

  useEffect(() => {
    if (isOpen === true) {
      setStepName(name || '')
      setStepDescription(description || '')
      setStepType(type)
      setEmailSubject(subject || '')
    }
  }, [isOpen])

  const handleChangeInput = (maxCharactersAmount: number, setData: (value: string) => void) => (
    e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value.length <= maxCharactersAmount) {
      setData(e.target.value)
    }
  }

  const lowerCaseStepType = stepType === 'Call' ? 'phone' : stepType.toLowerCase()

  const successFunc = (func: () => void) => {
    dispatch(
      cadenceStepsActions.request({
        data: { id: cadenceId },
        successFunc: () => {
          dispatch(
            cadenceActions.get({
              data: { id: cadenceId },
              successFunc: func,
            })
          )
        },
      })
    )
  }

  const closeModals = () => {
    handleCloseModal()
    handleCloseAddStepModal?.()
  }

  const requestData = {
    id: cadenceId,
    name: stepName,
    stepable_type: lowerCaseStepType,
    publish_at: Number(selectedDay),
    [lowerCaseStepType === 'email' ? 'body' : 'description']: stepDescription,
    subject: lowerCaseStepType === 'email' ? emailSubject : undefined,
  }

  const handleAddStep = () => {
    dispatch(
      cadenceStepActions.request({
        data: requestData,
        successFunc: () => {
          successFunc(closeModals)
        },
      })
    )
  }

  const handleAddStepAndCreateAnother = () => {
    dispatch(
      cadenceStepActions.request({
        data: requestData,
        successFunc: () => {
          successFunc(handleCloseModal)
        },
      })
    )
  }

  const handleUpdateStep = () => {
    dispatch(
      cadenceStepActions.update({
        data: {
          ...requestData,
          order,
          stepId,
        },
        successFunc: () => {
          successFunc(closeModals)
        },
      })
    )
  }

  const isNameEmpty = !stepName.trim()

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={handleCloseModal}
        title={`${name ? 'Edit' : 'Create'} ${stepType}`}
        minWidth={640}
        zIndex={2050}
      >
        {() => (
          <>
            <StyledModalBody>
              {stepType === 'Email' ? (
                <CreateEmailStep
                  name={stepName}
                  type={stepType}
                  emailSubject={emailSubject}
                  emailBody={stepDescription}
                  cadenceId={cadenceId}
                  handleChangeEmailName={handleChangeInput(200, setStepName)}
                  handleChangeEmailSubject={handleChangeInput(200, setEmailSubject)}
                  handleChangeEmailBody={setStepDescription}
                />
              ) : (
                <CreateCallSocialStep
                  name={stepName}
                  type={stepType}
                  instructions={stepDescription}
                  handleChangeName={handleChangeInput(200, setStepName)}
                  handleChangeInstructions={handleChangeInput(2000, setStepDescription)}
                />
              )}
            </StyledModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button variant="neutral" loading={cadenceFetching} onClick={handleCloseModal}>
                  Cancel
                </Button>
                {name ? (
                  <Button
                    disabled={isNameEmpty}
                    loading={cadenceFetching}
                    onClick={handleUpdateStep}
                  >
                    Save
                  </Button>
                ) : (
                  <>
                    <Button
                      disabled={isNameEmpty}
                      loading={cadenceFetching}
                      onClick={handleAddStepAndCreateAnother}
                    >
                      Add &#38; Create Another
                    </Button>
                    <Button
                      disabled={isNameEmpty}
                      loading={cadenceFetching}
                      onClick={handleAddStep}
                    >
                      Add Step
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  )
}

export default CreateStepModal
