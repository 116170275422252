import { runSaga, Saga } from 'redux-saga'
import { initialState } from 'static'
import { IAction, IAppState } from 'types'
import { customAxios } from 'utils'

const debounce = <F extends (...args: never[]) => unknown>(func: F, wait: number) => {
  let timeout: number | undefined
  return (...args: Parameters<F>) => {
    if (timeout) {
      window.clearTimeout(timeout)
    }
    timeout = window.setTimeout(() => {
      timeout = undefined
      func(...args)
    }, wait)
  }
}

const parseBackendErrors = <T extends Record<string, Record<string, string>[]>, K extends keyof T>(
  parsedErrors: T,
  callOnEach?: (element: Record<string, string>) => void
) =>
  Object.entries(parsedErrors).reduce(
    (errorsObject, [field, errorsArray]) => ({
      ...errorsObject,
      [field]: errorsArray.reduce((filedErros: Record<string, string>, filedError) => {
        if (callOnEach) {
          callOnEach(filedError)
        }
        return {
          ...filedErros,
          ...filedError,
        }
      }, {}),
    }),
    {} as Record<K, Record<string, string>>
  )

const toErrorMessage = (data: Record<string, string>) =>
  Object.entries(data).reduce((error, [type, message], _index, arr) => {
    if (type === 'required') {
      arr.splice(1)
      return ' '
    }

    return error ? `${error}. ${message}` : message
  }, '')

const parseErrorsToFormErrors = (errors: Record<string, Record<string, string> | undefined>) =>
  Object.entries(errors).reduce((formErrors, [field, data]) => {
    if (!data) {
      return formErrors
    }
    return {
      ...formErrors,
      [field]: field === 'password' ? JSON.stringify(data) : toErrorMessage(data),
    }
  }, {})

const firstLetterUppercase = (string: string) => string.charAt(0).toUpperCase() + string.slice(1)

const queryToJson = (query: string) => {
  const result: Record<string, string> = {}
  const searchParams = new URLSearchParams(query)

  searchParams.forEach((value: string, key: string) => {
    result[key] = value
  })

  return result
}

const updateQuery = (query: string, data: Record<string, string | undefined>) => {
  const searchParams = new URLSearchParams(query)
  Object.entries(data).forEach(([field, value]) => {
    if (value) {
      searchParams.set(field, value)
    } else {
      searchParams.delete(field)
    }
  })
  return searchParams
}

const localStorageAuthToken = {
  get: () => localStorage.getItem('authToken'),
  set: (token: string) => localStorage.setItem('authToken', token),
  remove: () => localStorage.removeItem('authToken'),
}

const getKeyValue = <T, K extends keyof T>(obj: T, key: K) => obj[key]

const testApi = async <S extends Saga>({
  method,
  response,
  requestFunc,
  requestData,
  isFailed,
  state = initialState,
}: {
  method: 'get' | 'post' | 'put' | 'delete'
  response: unknown
  requestFunc: S
  requestData: Parameters<S>
  isFailed?: boolean
  state?: IAppState
}) => {
  const request = jest.spyOn(customAxios, method)
  if (!isFailed) {
    request.mockImplementation(() => Promise.resolve(response))
  } else {
    request.mockImplementation(() => Promise.reject(response))
  }
  const dispatched = [] as IAction[]
  await runSaga(
    {
      dispatch: (action: IAction) => dispatched.push(action),
      getState: () => state,
    },
    requestFunc,
    ...requestData
  ).toPromise()
  return {
    request,
    dispatched,
  }
}

const handleRequestsForTest = (data: Record<string, unknown>) => (url: string) => {
  const responce: {
    data: unknown
  } = {
    data: {},
  }
  Object.entries({ ...data }).forEach(([key, value]) => {
    const index = url.indexOf(key)
    const lastSlashIndex = url.lastIndexOf('/')
    const isLastNumber = lastSlashIndex !== -1 && !Number.isNaN(+url.slice(lastSlashIndex + 1))
    if (index !== -1 && (url.slice(index) === key || isLastNumber)) {
      responce.data = value
    }
  })
  return Promise.resolve(responce)
}

export {
  debounce,
  queryToJson,
  firstLetterUppercase,
  parseBackendErrors,
  parseErrorsToFormErrors,
  localStorageAuthToken,
  getKeyValue,
  updateQuery,
  testApi,
  handleRequestsForTest,
}
