import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { MessageHandler, ModalHandler } from 'containers'
import styled, { createGlobalStyle } from 'styled-components'
import { ThemeProvider, getFont } from '@rhythm/theme'
import { FlexContainer, initialState } from 'static'
import rootSaga from 'sagas'
import { Helmet } from 'react-helmet'
import configureStore from 'store'
import './index.css'
import { localStorageAuthToken } from 'utils'
import App from './App'
import reportWebVitals from './reportWebVitals'

const GlobalStyle = createGlobalStyle`
  * {
    font-family: ${getFont('sansSerif')};
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`

const AppContainer = styled(FlexContainer)`
  position: relative;
  height: 100vh;
`

const store = configureStore({
  ...initialState,
  user: {
    ...initialState.user,
    authToken: localStorageAuthToken.get(),
  },
})
store.runSaga(rootSaga)

ReactDOM.render(
  <ThemeProvider>
    <Helmet titleTemplate="Cadence Engine - %s" defaultTitle="Cadence Engine" />
    <GlobalStyle />
    <Provider store={store}>
      <BrowserRouter>
        <AppContainer column>
          <App />
          <MessageHandler />
          <ModalHandler />
        </AppContainer>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
