import React, { useCallback } from 'react'
import { WrappedFieldProps } from 'redux-form'
import { Textarea } from '@rhythm/inputs'

interface IProps {
  noSetCustomValidity?: boolean
  minRows?: number
  maxRows?: number
}

const FormTextArea = ({
  meta: { touched, error },
  input: { onBlur, ...input },
  noSetCustomValidity = false,
  minRows = 2,
  maxRows = 4,
  ...props
}: WrappedFieldProps & typeof Textarea & IProps) => {
  const onBlurCallback = useCallback(
    (event) => {
      if (!noSetCustomValidity) {
        event.target.setCustomValidity(error || '')
      }
      onBlur(event)
    },
    [error, onBlur, noSetCustomValidity]
  )
  return (
    <Textarea
      {...props}
      {...input}
      variant={touched && error ? 'danger' : 'primary'}
      onBlur={onBlurCallback}
      minRows={minRows}
      maxRows={maxRows}
    />
  )
}

export default FormTextArea
