import React, { useState } from 'react'
import styled from 'styled-components'
import { getColor, getTransparentizedColor, getFontWeight } from '@rhythm/theme'
import { DragIcon, SubtractCircleIcon, EditIcon } from '@rhythm/svgs'
import { FlexContainer } from 'static'
import { IStep } from 'types'
import { ActionIcon } from 'components'
import { useDispatch } from 'react-redux'
import { cadenceActions, cadenceStepActions, cadenceStepsActions, messageActions } from 'actions'

import CreateStepModal from './modals/CreateStepModal'
import AddStepModal from './modals/AddStepModal'
import DeleteStepModal from './modals/DeleteStepModal'

const StepGroupContainer = styled(FlexContainer)`
  height: 72px;
  border-radius: 2px;
  background-color: ${getColor('white')};
  box-shadow: 0 2px 4px 0 ${getTransparentizedColor('black', 0.92)};
  margin-left: 95px;
  .overflow {
    overflow: hidden;
  }
`

const StepHandle = styled.div`
  height: 16px;
  margin: 28px 27px;
`

const IconsContainer = styled(FlexContainer)`
  gap: 5px;
  margin: 29px 21px;

  svg {
    cursor: pointer;
  }
`

const TitleContainer = styled(FlexContainer)`
  color: ${getColor('greyDarkest')};
  font-size: 14px;
  font-weight: ${getFontWeight('semibold')};
  line-height: 16px;
  margin: 28px 7px 28px 0;
  overflow: hidden;
`

const StepContainer = styled(FlexContainer)`
  width: 100%;
  padding-left: 16px;
  cursor: pointer;

  &:hover .preview-span {
    display: block;
  }
`

const ShortText = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const PreviewSpan = styled.span`
  display: none;
  color: ${getColor('greyDarkest')};
  font-size: 12px;
  font-weight: ${getFontWeight('semibold')};
  margin-left: 11px;
  line-height: 16px;
`

const Step = ({ dragHandleProps, step, day, cadenceId }: IStep) => {
  const [openedModal, setOpenedModal] = useState('')
  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const dispatch = useDispatch()

  const setStepType = (type: string) => {
    const lowerCaseType = type.toLowerCase()

    if (lowerCaseType.includes('email')) {
      setOpenedModal('Email')
    } else if (lowerCaseType.includes('call') || lowerCaseType.includes('phone')) {
      setOpenedModal('Call')
    } else {
      setOpenedModal('Social')
    }
  }

  const renderToastMessage = (action: string) => {
    dispatch(
      messageActions.update({
        message: {
          body: `Step ${action}`,
          type: 'confirm',
        },
      })
    )
  }

  const successFunc = (action: string) => () => {
    dispatch(
      cadenceStepsActions.request({
        data: { id: cadenceId },
        successFunc: () => {
          dispatch(
            cadenceActions.get({
              data: { id: cadenceId },
              successFunc: () => {
                renderToastMessage(action)
              },
            })
          )
        },
      })
    )
  }

  const handleDeleteStep = () => {
    dispatch(
      cadenceStepActions.delete({
        data: {
          stepId: step.id,
          cadenceId,
        },
        successFunc: successFunc('Deleted'),
        errorFunc: () => setDeleteModalOpen(false),
      })
    )
  }

  return (
    <>
      <StepGroupContainer>
        <FlexContainer>
          <div {...dragHandleProps}>
            <StepHandle>
              <DragIcon aria-label="drag icon" />
            </StepHandle>
          </div>
          <FlexContainer alignItems="center" justifyContent="center">
            <ActionIcon type={step.klass} />
          </FlexContainer>
        </FlexContainer>
        <FlexContainer alignItems="center" flexSize={1} column className="overflow">
          <StepContainer onClick={() => setStepType(step.klass)}>
            <TitleContainer flexSize={1} justifyContent="flex-start">
              <ShortText>{step.name}</ShortText>
              <PreviewSpan className="preview-span">Preview</PreviewSpan>
            </TitleContainer>
          </StepContainer>
        </FlexContainer>
        <IconsContainer alignItems="center">
          <EditIcon aria-label="edit icon" onClick={() => setEditModalOpen(true)} />
          <SubtractCircleIcon aria-label="delete icon" onClick={() => setDeleteModalOpen(true)} />
        </IconsContainer>
      </StepGroupContainer>
      <AddStepModal
        isOpen={isEditModalOpen}
        handleCloseModal={() => setEditModalOpen(false)}
        title="Edit Step"
        isEditStep={isEditModalOpen}
        selectedDay={day}
        cadenceId={cadenceId}
        stepId={step.id}
        updateSteps={successFunc('Updated')}
      />
      <CreateStepModal
        isOpen={!!openedModal}
        handleCloseModal={() => setOpenedModal('')}
        type={openedModal}
        name={step.name}
        description={step.klass === 'email' ? step.body : step.description}
        selectedDay={day}
        cadenceId={cadenceId}
        stepId={step.id}
        order={step.order}
        subject={step.subject}
      />
      <DeleteStepModal
        isOpen={isDeleteModalOpen}
        handleCloseModal={() => setDeleteModalOpen(false)}
        handleDeleteStep={handleDeleteStep}
      />
    </>
  )
}

export default Step
