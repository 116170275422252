import React from 'react'
import Highlighter from 'react-highlight-words'

type HighlighterProps = {
  text: string
  isSearchActive: boolean
  searchWord: string
}

const HighlightWord = ({ children }: { children: React.ReactNode }) => (
  <mark className="highlight">{children}</mark>
)

const TextHighlighter = ({ text, isSearchActive, searchWord }: HighlighterProps) => (
  <Highlighter
    searchWords={isSearchActive ? [searchWord] : []}
    autoEscape
    textToHighlight={text}
    highlightTag={HighlightWord}
  />
)

export default TextHighlighter
