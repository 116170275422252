import React from 'react'
import styled from 'styled-components'
import { getColor } from '@rhythm/theme'
import { Button, ButtonGroup } from '@rhythm/buttons'
import { FlexContainer } from 'static'

export interface IProps {
  handleBack(): void
  handleProceed(): void
  isProcceedDisabled: boolean
  loading?: boolean
  proceedName?: string
}

const FooterContainer = styled(FlexContainer)`
  border: 1px solid ${getColor('greyLightest')};
  background-color: ${getColor('white')};
`

const FooterButtonGroup = styled(ButtonGroup)`
  padding: 20px 19px;
  flex-wrap: wrap;
`

const FooterButtons = ({
  proceedName = 'Proceed',
  handleBack,
  handleProceed,
  isProcceedDisabled,
  loading,
}: IProps) => (
  <FooterContainer justifyContent="flex-end">
    <FooterButtonGroup>
      <Button data-testid="btnBack" appearance="line" onClick={handleBack} loading={loading}>
        Back
      </Button>
      <Button
        data-testid="btnProceed"
        onClick={handleProceed}
        disabled={isProcceedDisabled}
        loading={loading}
      >
        {proceedName}
      </Button>
    </FooterButtonGroup>
  </FooterContainer>
)

export default FooterButtons
