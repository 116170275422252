import { ReactText } from 'react'
import { IAction, IActionDefaultPayload, IAppState, ICadenceFilters, ICadenceQuestion } from 'types'
import { all, put, takeLatest, takeEvery, select } from 'redux-saga/effects'
import {
  cadencesActions,
  CADENCES,
  messageActions,
  CADENCE,
  cadenceActions,
  CADENCE_PERSONA,
  cadenceQuestionsActions,
  CADENCE_QUESTIONS,
  cadenceQuestionAnswersActions,
  CADENCE_QUESTION_ANSWERS,
  CADENCE_EXPORT,
  cadenceExportActions,
} from 'actions'
import { customAxios, customAxiosCall } from 'utils'

const endpoints = {
  cadencesList: 'api/v1/cadences',
  cadence: (id: ReactText) => `${endpoints.cadencesList}/${id}`,
  persona: (id: ReactText) => `${endpoints.cadencesList}/${id}/personas`,
  cadenceQuestions: (id: ReactText) => `${endpoints.cadencesList}/${id}/questions`,
  cadenceQuestionProceed: (cadenceId: ReactText, questionId: ReactText) =>
    `${endpoints.cadenceQuestions(cadenceId)}/${questionId}/proceed`,
  cadenceQuestionRetreat: (cadenceId: ReactText, questionId: ReactText) =>
    `${endpoints.cadenceQuestions(cadenceId)}/${questionId}/retreat`,
  cadenceQuestionAnswers: (cadenceId: ReactText, questionId: ReactText) =>
    `${endpoints.cadenceQuestions(cadenceId)}/${questionId}/answers`,
  cadenceQuestionLibAnswers: (cadenceId: ReactText, questionId: ReactText) =>
    `${endpoints.cadenceQuestions(cadenceId)}/${questionId}/library_answers`,
  cadenceDeleteAnswer: (cadenceId: ReactText, questionId: ReactText, answerId: ReactText) =>
    `${endpoints.cadenceQuestions(cadenceId)}/${questionId}/answers/${answerId}`,
  cadenceExportTo: (cadenceId: ReactText) => `${endpoints.cadence(cadenceId)}/export`,
}

export function* getCadencesList({
  body,
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<string>, ICadenceFilters>) {
  try {
    const {
      data: list,
      headers: {
        'current-page': currentPage,
        'page-items': pageItems,
        'total-count': totalCount,
        'total-pages': totalPages,
      },
    } = yield customAxiosCall(customAxios.get, endpoints.cadencesList, {
      params: body,
    })
    const cadencesList = {
      list,
      currentPage,
      pageItems,
      totalCount,
      totalPages,
    }
    yield put(cadencesActions.success(cadencesList))
    if (successFunc) {
      successFunc(cadencesList)
    }
  } catch ({ response: { statusText } }) {
    const text = statusText as string
    yield put(cadencesActions.failure(text))
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: text,
        },
      })
    )
    if (errorFunc) {
      errorFunc(text)
    }
  }
}

export function* updateCadence({
  body: { allowDuplicate, id, ...body },
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<string>, { name: string; allowDuplicate?: boolean; id: string }>) {
  try {
    const { data } = yield customAxiosCall(customAxios.put, endpoints.cadence(id), body, {
      params: {
        allow_duplicate: allowDuplicate,
      },
    })
    yield put(cadenceActions.success(data))
    if (successFunc) {
      successFunc()
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    const text = statusText as string
    const errorMessage = errors as string
    yield put(cadenceActions.failure(errorMessage || text))

    if (status !== 422) {
      yield put(
        messageActions.update({
          message: {
            type: 'danger',
            body: text,
          },
        })
      )
    }
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* deleteCadence({
  body: { id },
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<string>, { id: string }>) {
  try {
    yield customAxiosCall(customAxios.delete, endpoints.cadence(id))
    yield put(cadenceActions.success())
    if (successFunc) {
      successFunc()
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    const text = statusText as string
    const errorMessage = errors as string
    yield put(cadenceActions.failure(errorMessage || text))
    if (status !== 422) {
      yield put(
        messageActions.update({
          message: {
            type: 'danger',
            body: text,
          },
        })
      )
    }
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* addCadence({
  body: { allowDuplicate, ...body },
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<string>, { name: string; allowDuplicate?: boolean }>) {
  try {
    const { data } = yield customAxiosCall(customAxios.post, endpoints.cadencesList, body, {
      params: {
        allow_duplicate: allowDuplicate,
      },
    })
    yield put(cadenceActions.success(data))
    if (successFunc) {
      successFunc({ cadenceName: body.name, allowDuplicate, id: data.id })
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    const text = statusText as string
    const errorMessage = errors as string
    yield put(cadenceActions.failure(errorMessage || text))
    if (status !== 422) {
      yield put(
        messageActions.update({
          message: {
            type: 'danger',
            body: text,
          },
        })
      )
    }
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* getCadence({
  body: { id },
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<string>, { id: number }>) {
  try {
    const { data } = yield customAxiosCall(customAxios.get, endpoints.cadence(id))
    yield put(cadenceActions.success(data))
    if (successFunc) {
      successFunc(data)
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    const text = statusText as string
    const errorMessage = errors as string
    yield put(cadenceActions.failure(errorMessage || text))
    if (status !== 422) {
      yield put(
        messageActions.update({
          message: {
            type: 'danger',
            body: text,
          },
        })
      )
    }
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* updateCadencePersona({
  body,
  successFunc,
  errorFunc,
}: IAction<
  IActionDefaultPayload<string>,
  {
    id: string
    company_headcount: string
    geography: string
    customer_type: string
    industry: string
    job_role: string
  }
>) {
  try {
    yield customAxiosCall(customAxios.post, endpoints.persona(body.id), body)
    if (successFunc) {
      successFunc()
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    const text = statusText as string
    const errorMessage = errors as string
    yield put(cadenceActions.failure(errorMessage || text))
    if (status !== 422) {
      yield put(
        messageActions.update({
          message: {
            type: 'danger',
            body: text,
          },
        })
      )
    }
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* getCadenceQuestions({
  body: { cadenceId },
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<string>, { cadenceId: number }>) {
  try {
    const { data } = yield customAxiosCall(customAxios.get, endpoints.cadenceQuestions(cadenceId))
    yield put(cadenceQuestionsActions.success(data))
    if (successFunc) {
      successFunc(data)
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    const text = statusText as string
    const errorMessage = errors as string
    yield put(cadenceQuestionsActions.failure(errorMessage || text))
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: text,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* questionProceed({
  body: { cadenceId, questionId },
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<string>, { cadenceId: number; questionId: number }>) {
  try {
    const { data } = yield customAxiosCall(
      customAxios.post,
      endpoints.cadenceQuestionProceed(cadenceId, questionId)
    )
    const questions: ICadenceQuestion[] | null = yield select(
      ({ cadenceQuestions }: IAppState) => cadenceQuestions.questions
    )
    if (questions) {
      const replaceQuestionIndex = questions.findIndex((question) => question.id === data.id)
      if (replaceQuestionIndex !== -1) {
        questions[replaceQuestionIndex] = data
      }
    }
    yield put(cadenceQuestionsActions.success(questions || [data]))
    if (successFunc) {
      successFunc(questions || [data])
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    const text = statusText as string
    const errorMessage = errors as string
    yield put(cadenceQuestionsActions.failure(errorMessage || text))
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: text,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* createAnswer({
  body: { cadenceId, questionId, answer, isCreateLib },
  successFunc,
  errorFunc,
}: IAction<
  IActionDefaultPayload<string>,
  {
    cadenceId: number
    questionId: number
    answer: {
      name: string
      link?: string
    }
    isCreateLib: boolean
  }
>) {
  try {
    const newAnswer: {
      name: string
      link?: string
    } = {
      name: answer.name,
    }
    if (answer.link) {
      newAnswer.link = answer.link
    }
    const { data } = yield customAxiosCall(
      customAxios.post,
      endpoints[isCreateLib ? 'cadenceQuestionLibAnswers' : 'cadenceQuestionAnswers'](
        cadenceId,
        questionId
      ),
      newAnswer
    )
    yield put(cadenceQuestionAnswersActions.success({}))
    if (successFunc) {
      successFunc(data)
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    const text = statusText as string
    const errorMessage = errors as string
    yield put(cadenceQuestionAnswersActions.failure(errorMessage || text))
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: text,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* getQuestionAnswers({
  body: { cadenceId, questionId, isLibAnswers },
  successFunc,
  errorFunc,
}: IAction<
  IActionDefaultPayload<string>,
  {
    cadenceId: number
    questionId: number
    isLibAnswers?: boolean
  }
>) {
  try {
    const { data } = yield customAxiosCall(
      customAxios.get,
      endpoints[isLibAnswers ? 'cadenceQuestionLibAnswers' : 'cadenceQuestionAnswers'](
        cadenceId,
        questionId
      )
    )
    yield put(
      cadenceQuestionAnswersActions.success({
        [isLibAnswers ? 'libAnswers' : 'answers']: data,
      })
    )
    if (successFunc) {
      successFunc(data)
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    const text = statusText as string
    const errorMessage = errors as string
    yield put(cadenceQuestionAnswersActions.failure(errorMessage || text))
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: text,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* deleteAnswer({
  body: { cadenceId, questionId, answerId },
  successFunc,
  errorFunc,
}: IAction<
  IActionDefaultPayload<string>,
  {
    cadenceId: number
    questionId: number
    answerId: number
  }
>) {
  try {
    const { data } = yield customAxiosCall(
      customAxios.delete,
      endpoints.cadenceDeleteAnswer(cadenceId, questionId, answerId)
    )
    yield put(cadenceQuestionAnswersActions.success({}))
    if (successFunc) {
      successFunc(data)
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    const text = statusText as string
    const errorMessage = errors as string
    yield put(cadenceQuestionAnswersActions.failure(errorMessage || text))
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: text,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* questionRetreat({
  body: { cadenceId, questionId },
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<string>, { cadenceId: number; questionId: number }>) {
  try {
    const { data } = yield customAxiosCall(
      customAxios.post,
      endpoints.cadenceQuestionRetreat(cadenceId, questionId)
    )
    const questions: ICadenceQuestion[] | null = yield select(
      ({ cadenceQuestions }: IAppState) => cadenceQuestions.questions
    )
    if (questions) {
      const replaceQuestionIndex = questions.findIndex((question) => question.id === data.id)
      if (replaceQuestionIndex !== -1) {
        questions[replaceQuestionIndex] = data
      }
    }
    yield put(cadenceQuestionsActions.success(questions || [data]))
    if (successFunc) {
      successFunc(questions || [data])
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    const text = statusText as string
    const errorMessage = errors as string
    yield put(cadenceQuestionsActions.failure(errorMessage || text))
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: text,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* cadenceExportTo({
  body: { cadenceId },
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<string>, { cadenceId: number }>) {
  try {
    const { data } = yield customAxiosCall(customAxios.get, endpoints.cadenceExportTo(cadenceId))
    yield put(cadenceExportActions.success())
    if (successFunc) {
      successFunc(data)
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    const text = statusText as string
    const errorMessage = errors as string
    yield put(cadenceExportActions.failure(errorMessage || text))
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: text,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

function* cadenceSagas() {
  yield all([
    takeLatest(CADENCES.REQUEST, getCadencesList),
    takeLatest(CADENCE.REQUEST, addCadence),
    takeLatest(CADENCE.DELETE, deleteCadence),
    takeLatest(CADENCE.UPDATE, updateCadence),
    takeLatest(CADENCE.GET, getCadence),
    takeLatest(CADENCE_PERSONA.REQUEST, updateCadencePersona),
    takeLatest(CADENCE_QUESTIONS.GET, getCadenceQuestions),
    takeLatest(CADENCE_QUESTIONS.UPDATE, questionProceed),
    takeLatest(CADENCE_QUESTIONS.DELETE, questionRetreat),
    takeEvery(CADENCE_QUESTION_ANSWERS.REQUEST, createAnswer),
    takeLatest(CADENCE_QUESTION_ANSWERS.DELETE, deleteAnswer),
    takeEvery(CADENCE_QUESTION_ANSWERS.GET, getQuestionAnswers),
    takeEvery(CADENCE_EXPORT.GET, cadenceExportTo),
  ])
}

export default cadenceSagas
