import React, { ReactText, useEffect, useState } from 'react'
import {
  ContainerWithMenu,
  AppLink,
  CadenceNameModal,
  MessageWithCheck,
  StepsExportWarning,
  Loader,
} from 'components'
import { UserMenu } from 'containers'
import styled from 'styled-components'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { FlexContainer } from 'static'
import { getColor, getFontWeight } from '@rhythm/theme'
import { H5 } from '@rhythm/typography'
import { EditIcon } from '@rhythm/svgs'
import {
  cadenceActions,
  cadenceExportActions,
  cadenceStepsActions,
  modalActions,
  messageActions,
  currentUserActions,
} from 'actions'
import { Button } from '@rhythm/buttons'
import { useDispatch, useSelector } from 'react-redux'
import { ICadenceOverviewPage, Day, TContainer } from 'types'
import { Tooltip } from '@rhythm/tooltips'
import { cadenceFetchingSelector, userCurrentUserSelector, userFetchingSelector } from 'selectors'

import StepDay from './StepDay'
import AddStepModal from './modals/AddStepModal'
import CadenceOverviewCounter from './CadenceOverviewCounter'
import reorder from './services/stepsReorder'

const Title = styled(H5)`
  display: flex;
  padding: 1.2rem 0rem 0.95rem 2.25rem;
  color: ${getColor('greyDarkest')};
  line-height: 1.4rem;
  margin: 0;
  box-shadow: inset 0 -1px 0 0 ${getColor('greyLightest')};
`

const CadenceLink = styled(AppLink)`
  color: ${getColor('greyDarkest')};
  font-size: 20px;
  font-weight: ${getFontWeight('medium')};
`

const Slash = styled.div`
  color: ${getColor('grey')};
`

const StyledEditIcon = styled(EditIcon)`
  height: 17px;
  min-width: 17px;
  border-radius: 2px;
  color: ${getColor('green')};
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
`

const Header = styled(FlexContainer)`
  flex-wrap: wrap;
  gap: 34px;
  padding: 11px 33px 11px 36px;
`

const DaysContainer = styled.div`
  background-color: ${getColor('greyLightest')};
  padding-top: 16px;
  padding-bottom: 10px;
  min-height: calc(100vh - 183px);
`

const CadenceName = styled.div`
  max-width: 500px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const CadenceNameContainer = styled.div`
  overflow: hidden;
`

const CadenceOverviewPage = ({ cadence, cadenceSteps }: ICadenceOverviewPage) => {
  const dispatch = useDispatch()
  const user = useSelector(userCurrentUserSelector)
  const userFetching = useSelector(userFetchingSelector)
  const cadenceFetching = useSelector(cadenceFetchingSelector)
  const [isModalOpen, setModalOpen] = useState(false)
  const [showLoginToModal, setShowLoginToModal] = useState(true)
  const [selectedDay, setSelectedDay] = useState(0)

  useEffect(() => {
    dispatch(
      currentUserActions.get({
        successFunc: (isOauthInSalesloft: boolean) => {
          setShowLoginToModal(!isOauthInSalesloft)
        },
      })
    )
  }, [])

  const onUpdateOrder = ({ destination, source }: Partial<DropResult>) => {
    if (!destination) {
      return
    }

    if (destination.droppableId === source?.droppableId && destination.index === source.index) {
      return
    }

    const sourceDay = source?.droppableId.split('-').pop() as string
    const destinationDay = destination.droppableId.split('-').pop() as string
    const startIndex = source?.index as number

    const successFunc = () => dispatch(cadenceStepsActions.request({ data: { id: cadence.id } }))
    dispatch(
      reorder(
        sourceDay,
        destinationDay,
        cadenceSteps,
        startIndex,
        destination.index,
        cadence.id,
        successFunc
      )
    )
  }

  const handleOpenModal = (day: number) => () => {
    setSelectedDay(day)
    setModalOpen(true)
  }

  const renameCadence = (arg: {
    data: {
      id: ReactText
      cadenceName: string
      allowDuplicate: boolean
    }
    successFunc: () => void
  }) =>
    dispatch(
      cadenceActions.update({
        ...arg,
        successFunc: () => {
          arg.successFunc()
        },
      })
    )

  const openLoginSalesLoftModal = () =>
    dispatch(
      modalActions.update({
        modal: {
          title: 'Login to Salesloft',
          width: '32rem',
          body: (bodyProps: { body: TContainer; footer: TContainer; onClose: () => void }) => (
            <MessageWithCheck
              {...bodyProps}
              onApply={() => {
                window.location.replace(`${window.location.origin}/api/v1/user/salesloft`)
              }}
            />
          ),
        },
      })
    )

  const exportSteps = () => {
    if (user && user.show_export_sign_in_modal && showLoginToModal) {
      openLoginSalesLoftModal()
    } else {
      dispatch(
        cadenceExportActions.get({
          cadenceId: +cadence.id,
          successFunc: (link) =>
            dispatch(
              messageActions.update({
                message: {
                  type: 'confirm',
                  title: 'Cadence exported successfully',
                  actionText: 'View Cadence at Salesloft',
                  actionCallback: () => window.open(link, '_blank'),
                  duration: 7000,
                },
              })
            ),
        })
      )
    }
  }

  const openRenameCadenceModal = ({
    ...modalProps
  }: {
    title: string
    id: ReactText
    name?: string
    applyMessage?: string
  }) => () =>
    dispatch(
      modalActions.update({
        modal: {
          title: modalProps.title,
          width: '32rem',
          body: (bodyProps: { body: TContainer; footer: TContainer; onClose: () => void }) => (
            <CadenceNameModal {...bodyProps} {...modalProps} onApply={renameCadence} />
          ),
        },
      })
    )

  const amountOfSteps = cadence.emails + cadence.phones + cadence.socials

  return (
    <ContainerWithMenu menu={UserMenu}>
      {cadenceSteps.length > 0 ? (
        <>
          <Title>
            <CadenceLink to="/cadenceList" useHref>
              Cadences
            </CadenceLink>
            <Slash>&nbsp;/</Slash>
            <CadenceNameContainer>
              <Tooltip maxWidth="100%" title={cadence.name}>
                <CadenceName>&nbsp; {cadence.name}</CadenceName>
              </Tooltip>
            </CadenceNameContainer>
            <StyledEditIcon
              aria-label="rename cadence"
              onClick={openRenameCadenceModal({
                title: 'Rename Cadence',
                applyMessage: 'Cadence Renamed',
                id: cadence.id,
                name: cadence.name,
              })}
            />
          </Title>
          <Header alignItems="center" justifyContent="space-between">
            <CadenceOverviewCounter
              title="Duration"
              value={`${cadenceSteps[cadenceSteps.length - 1].publish_at} Days`}
            />
            <CadenceOverviewCounter title="Steps" value={amountOfSteps} />
            <Button
              aria-label="export button"
              data-testid="btnExportTo"
              disabled={amountOfSteps > 50 || showLoginToModal}
              appearance="line"
              loading={userFetching || cadenceFetching}
              onClick={exportSteps}
              width="174px"
            >
              Export to Salesloft
            </Button>
          </Header>
          <DragDropContext onDragEnd={onUpdateOrder}>
            <DaysContainer>
              <StepsExportWarning limit={amountOfSteps > 50} isSalesloft={showLoginToModal} />
              {cadenceSteps.map((day: Day) => (
                <StepDay
                  key={day.publish_at}
                  cadenceId={Number(cadence.id)}
                  day={day}
                  handleOpenModal={handleOpenModal}
                />
              ))}
            </DaysContainer>
          </DragDropContext>
        </>
      ) : (
        <Loader />
      )}
      <AddStepModal
        isOpen={isModalOpen}
        handleCloseModal={() => setModalOpen(false)}
        title="Choose a Step Type"
        selectedDay={selectedDay}
        cadenceId={Number(cadence.id)}
      />
    </ContainerWithMenu>
  )
}

export default CadenceOverviewPage
