import React, { useEffect, useState } from 'react'
import { messageActions, signinActions } from 'actions'
import { BlockContainer, SigninForm, AppLink } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { emailRegex, FlexContainer } from 'static'
import styled, { css } from 'styled-components'
import { getColor } from '@rhythm/theme'
import { queryToJson } from 'utils'
import { IAppMessageState, IAppState, ISigninForm } from 'types'
import { getFormValues } from 'redux-form'
import { InfoCircleIcon } from '@rhythm/svgs'
import { Tooltip } from '@rhythm/tooltips'
import { Helmet } from 'react-helmet'

const Container = styled(FlexContainer)`
  margin-top: 12rem;
`

const InfoIcon = styled(InfoCircleIcon)`
  color: ${getColor('grey')};
  margin-left: 0.25rem;
  margin-top: 0.5rem;
  font-size: 0.875rem;
`

const Div = styled(FlexContainer)`
  color: ${getColor('grey')};
  width: 100%;
  &:nth-of-type(2) {
    margin-top: 2rem;
  }
`

const BlockContainerStyles = css`
  padding: 2.5rem;
  min-height: 31rem;
`

const Signin = ({ location, history }: RouteComponentProps) => {
  const dispatch = useDispatch()
  const formValue = useSelector<{ state: IAppState }, ISigninForm>(getFormValues('signin'))
  const signin = (value: ISigninForm) => {
    dispatch(
      signinActions.request({
        signinInfo: value,
        errorFunc: (error) => error.indexOf('confirm') !== -1 && history.push('/check'),
      })
    )
  }
  const [isPasswordStep, setPasswordStep] = useState(
    !!formValue?.email && emailRegex.test(formValue.email)
  )
  const changeStep = (isPassword: boolean) => () => setPasswordStep(isPassword)
  useEffect(() => {
    const { confirm, already_confirmed: alreadyConfirmed } = queryToJson(location.search)
    if (confirm || alreadyConfirmed) {
      let message: IAppMessageState = {
        message: {
          body: 'Your account has been successfully created!',
          type: 'confirm',
        },
      }
      if (alreadyConfirmed) {
        message = {
          message: {
            body: 'You have already confirmed your account!',
            type: 'danger',
          },
        }
      }
      dispatch(messageActions.update(message))
    }
  }, [])
  return (
    <Container justifyContent="center">
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <BlockContainer styles={BlockContainerStyles}>
        <FlexContainer column justifyContent="space-between" flexSize={1}>
          <SigninForm
            onSubmit={signin}
            prevStep={changeStep(false)}
            nextStep={changeStep(true)}
            isPasswordStep={isPasswordStep}
          />
          {!isPasswordStep && !location.search.length && (
            <Div alignItems="center">
              <span>
                or&nbsp;&nbsp;
                <AppLink to="/api/v1/user/salesloft" useHref>
                  Sign in with your Salesloft account
                </AppLink>
              </span>
              <Tooltip
                maxWidth="100%"
                title="Cadence export is available only within Salesloft account"
              >
                <InfoIcon />
              </Tooltip>
            </Div>
          )}
          <Div alignItems="flex-start">
            <span>
              Not Yet a Customer?&nbsp;&nbsp;
              <AppLink to="/signup">Sign up</AppLink>
            </span>
          </Div>
        </FlexContainer>
      </BlockContainer>
    </Container>
  )
}

export default Signin
