import { ICadencesState } from 'types'
import { CADENCES, CADENCE } from 'actions'

const initialState: ICadencesState = {
  cadencesList: null,
  fetching: true,
  success: false,
  error: null,
}

export default function cadencesReducer(
  state = initialState,
  { type, payload }: { type: string; payload: Record<string, unknown> }
) {
  switch (type) {
    case CADENCE.FAILURE:
    case CADENCES.REQUEST:
    case CADENCES.SUCCESS:
    case CADENCES.FAILURE: {
      return {
        ...state,
        ...payload,
      }
    }
    default:
      return state
  }
}
