import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { FlexContainer } from 'static'
import { getColor, getFontWeight, getTransparentizedColor } from '@rhythm/theme'
import { ExclamationCircleIcon } from '@rhythm/svgs'

export interface IProps {
  limit?: boolean
  isSalesloft?: boolean
}

const WarningMessage = styled.div`
  margin: 0 33px 6px 36px;
  background-color: ${getColor('white')};
  box-shadow: 0 2px 4px 0 ${getTransparentizedColor('black', 0.9)};
`

const WarningMessageBody = styled.div`
  box-shadow: inset 4px 0px ${getColor('orange')};
  padding-bottom: 1rem;
`

const WarningIcon = styled(ExclamationCircleIcon)`
  color: ${getColor('orangeDark')};
  height: 15px;
  min-width: 15px;
  margin: 16px 0 0 16px;
`

const WarningTitle = styled.div`
  color: ${getColor('greyDarkest')};
  font-size: 14px;
  font-weight: ${getFontWeight('semibold')};
  line-height: 16px;
  margin: 16px 0 0 8px;
`

const WarningDescription = styled.div`
  color: ${getColor('grey')};
  font-size: 14px;
  line-height: 20px;
  margin: 4px 0 0 40px;
`

const StepsExportWarning = ({ limit, isSalesloft }: IProps) => {
  let warningTitle = 'Cadence with more than 50 steps cannot be exported to Salesloft.'
  let warningDesc: ReactElement | string =
    'Please reduce the number of steps to 50 or less to enable exporting to Salesloft.'
  if (isSalesloft) {
    warningTitle = 'Cadence export is available only within Salesloft account.'
    warningDesc = 'Please sign in with your Salesloft accout to create exportable cadences.'
  }
  if (isSalesloft && limit) {
    warningTitle = 'Cadence cannot be exported to Salesloft because of 2 issues:'
    warningDesc = (
      <>
        • Cadence export is available only within Salesloft account. Please sign in with your
        Salesloft accout to create exportable cadences.
        <br />• Please reduce the number of steps to 50 or less to enable exporting to Salesloft.
      </>
    )
  }
  return limit || isSalesloft ? (
    <WarningMessage>
      <WarningMessageBody>
        <FlexContainer>
          <WarningIcon />
          <WarningTitle>{warningTitle}</WarningTitle>
        </FlexContainer>
        <WarningDescription>{warningDesc}</WarningDescription>
      </WarningMessageBody>
    </WarningMessage>
  ) : null
}

export default StepsExportWarning
