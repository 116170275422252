import React from 'react'
import styled from 'styled-components'
import { Droppable, Draggable, DroppableProvided, DraggableProvided } from 'react-beautiful-dnd'
import { Button } from '@rhythm/buttons'
import { AddCircleIcon } from '@rhythm/svgs'
import { Subtitle2 } from '@rhythm/typography'
import { FlexContainer } from 'static'
import { Day, SortedStep } from 'types'
import { getColor } from '@rhythm/theme'

import Step from './Step'

const StepContainer = styled(FlexContainer)`
  padding-top: 10px;
`

const StepWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-right: 33px;
`

const StyledButton = styled(Button)`
  margin: 4px 0 6px 95px;

  &:focus:not([disabled]) {
    color: ${getColor('green')};
  }

  &:focus {
    box-shadow: none;
  }

  &:hover:not([disabled]) {
    color: ${getColor('greenDarkest')};
  }
`

const DayTitle = styled(Subtitle2)`
  position: absolute;
  margin: 0 0 10px 36px;
  width: 70px;
`

const StepDay = ({
  day,
  cadenceId,
  handleOpenModal,
}: {
  day: Day
  cadenceId: number
  handleOpenModal(day: number): void
}) => (
  <Droppable droppableId={`droppable-${day.publish_at}`}>
    {(provided: DroppableProvided) => (
      <div ref={provided.innerRef}>
        {day.steps.map((step: SortedStep, index: number) => (
          <StepContainer key={`step-group-${step.id}`} data-test="qa-step-day">
            <DayTitle>{index === 0 && `Day ${day.publish_at}`}</DayTitle>
            <Draggable draggableId={step.id.toString()} index={index}>
              {(dragProvided: DraggableProvided) => (
                <StepWrapper
                  ref={dragProvided.innerRef}
                  {...dragProvided.draggableProps}
                  style={dragProvided.draggableProps.style}
                >
                  <Step
                    dragHandleProps={dragProvided.dragHandleProps}
                    step={step}
                    day={day.publish_at}
                    cadenceId={cadenceId}
                  />
                </StepWrapper>
              )}
            </Draggable>
          </StepContainer>
        ))}
        {provided.placeholder}
        {day.steps.length !== 0 && (
          <StyledButton
            appearance="ghost"
            iconLeft={<AddCircleIcon />}
            // @ts-expect-error
            onClick={handleOpenModal(day.publish_at)}
          >
            Add Step
          </StyledButton>
        )}
      </div>
    )}
  </Droppable>
)

export default StepDay
