import { ICadence } from 'types'
import { createAction } from 'utils'

import { STEPS } from './types'

const stepsActions = {
  request: ({
    data: { id, action, skip_questions },
    successFunc,
    errorFunc,
  }: {
    data: {
      id: string | number
      action?: string
      skip_questions?: boolean
    }
    successFunc?: (cadence: ICadence) => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      STEPS.REQUEST,
      {
        success: false,
        fetching: true,
        error: null,
      },
      {
        id,
        action,
        skip_questions,
      },
      successFunc,
      errorFunc
    ),
  success: (cadence: ICadence) =>
    createAction(STEPS.SUCCESS, {
      cadence,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error: string) =>
    createAction(STEPS.FAILURE, {
      fetching: false,
      success: false,
      error,
    }),
}

export {
  // eslint-disable-next-line import/prefer-default-export
  stepsActions,
}
