import { IUserState } from 'types'
import { CURRENT_USER, FEEDBACK, SIGNIN, SIGNOUT, SIGNUP, USER } from 'actions'
import { localStorageAuthToken } from 'utils'

const initialState: IUserState = {
  authToken: localStorageAuthToken.get(),
  usersShared: null,
  fetching: false,
  success: false,
  error: null,
  user: null,
}

export default function userReducer(
  state = initialState,
  { type, payload }: { type: string; payload: Record<string, unknown> }
) {
  switch (type) {
    case USER.REQUEST:
    case USER.SUCCESS:
    case USER.FAILURE:
    case SIGNOUT.SUCCESS:
    case SIGNIN.REQUEST:
    case SIGNIN.SUCCESS:
    case SIGNIN.FAILURE:
    case SIGNUP.REQUEST:
    case SIGNUP.SUCCESS:
    case FEEDBACK.REQUEST:
    case FEEDBACK.SUCCESS:
    case FEEDBACK.FAILURE:
    case CURRENT_USER.GET:
    case CURRENT_USER.REQUEST:
    case CURRENT_USER.SUCCESS:
    case CURRENT_USER.UPDATE:
    case CURRENT_USER.FAILURE:
    case SIGNUP.FAILURE: {
      return {
        ...state,
        ...payload,
      }
    }
    default:
      return state
  }
}
