import { createSelector } from 'reselect'
import { IAppState } from 'types'

const cadencesSelector = ({ cadences }: IAppState) => cadences

const cadencesFetchingSelector = createSelector(cadencesSelector, ({ fetching }) => fetching)

const cadencesErrorSelector = createSelector(cadencesSelector, ({ error }) => error)

const cadencesListSelector = createSelector(cadencesSelector, ({ cadencesList }) => cadencesList)

export { cadencesFetchingSelector, cadencesErrorSelector, cadencesListSelector }
