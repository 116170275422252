import { CadenceStep } from 'types'
import { createAction } from 'utils'

import { CADENCE_STEPS, CADENCE_STEP } from './types'

const cadenceStepsActions = {
  request: ({
    data: { id },
    successFunc,
    errorFunc,
  }: {
    data: {
      id: string | number
    }
    successFunc?: () => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE_STEPS.REQUEST,
      {
        cadenceSteps: null,
        success: false,
        fetching: true,
        error: null,
      },
      {
        id,
      },
      successFunc,
      errorFunc
    ),
  success: (cadenceSteps: CadenceStep[]) =>
    createAction(CADENCE_STEPS.SUCCESS, {
      cadenceSteps,
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error: string) =>
    createAction(CADENCE_STEPS.FAILURE, {
      fetching: false,
      success: false,
      error,
    }),
  update: ({
    data: { id, stepId, day, order },
    successFunc,
    errorFunc,
  }: {
    data: {
      id: string | number
      stepId?: number
      day: number
      order?: number
    }
    successFunc?: () => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE_STEPS.UPDATE,
      {
        fetching: false,
        success: true,
        error: null,
      },
      {
        stepId,
        id,
        day,
        order,
      },
      successFunc,
      errorFunc
    ),
}

const cadenceStepActions = {
  request: ({
    data: { id, name, stepable_type, description, subject, body, publish_at },
    successFunc,
    errorFunc,
  }: {
    data: {
      id: number
      name: string
      stepable_type: string
      body?: string
      publish_at: number
      description?: string
      subject?: string
    }
    successFunc?: () => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE_STEP.REQUEST,
      {
        feathing: true,
        success: false,
        error: null,
      },
      {
        id,
        name,
        stepable_type,
        description,
        subject,
        body,
        publish_at,
      },
      successFunc,
      errorFunc
    ),
  update: ({
    data: { id, stepId, name, stepable_type, description, subject, body, publish_at, order },
    successFunc,
    errorFunc,
  }: {
    data: {
      id: number
      stepId?: number
      name?: string
      stepable_type?: string
      body?: string
      publish_at: number
      description?: string
      subject?: string
      order?: number
    }
    successFunc?: () => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE_STEP.UPDATE,
      {
        fetching: false,
        success: true,
        error: null,
      },
      {
        stepId,
        id,
        name,
        stepable_type,
        description,
        subject,
        body,
        publish_at,
        order,
      },
      successFunc,
      errorFunc
    ),
  get: ({
    data: { cadenceId, body },
    successFunc,
    errorFunc,
  }: {
    data: {
      cadenceId: number
      body: string
    }
    successFunc?: (preview: string) => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE_STEP.GET,
      {
        fetching: false,
        success: true,
        error: null,
      },
      {
        body,
        cadenceId,
      },
      successFunc,
      errorFunc
    ),
  delete: ({
    data: { cadenceId, stepId },
    successFunc,
    errorFunc,
  }: {
    data: {
      cadenceId: number
      stepId: number
    }
    successFunc?: () => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      CADENCE_STEP.DELETE,
      {
        fetching: true,
        success: false,
        error: null,
      },
      {
        stepId,
        cadenceId,
      },
      successFunc,
      errorFunc
    ),
  success: () =>
    createAction(CADENCE_STEP.SUCCESS, {
      fetching: false,
      success: true,
      error: null,
    }),
  failure: (error: string) =>
    createAction(CADENCE_STEP.FAILURE, {
      fetching: false,
      success: false,
      error,
    }),
}

export { cadenceStepsActions, cadenceStepActions }
