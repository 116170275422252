import { IAppState } from 'types'

const initialState: IAppState = {
  user: {
    fetching: false,
    success: false,
    error: null,
    authToken: null,
    user: null,
    usersShared: null,
  },
  appMessage: {
    message: null,
  },
  motions: {
    motionsList: null,
    motions: null,
    fetching: false,
    success: false,
    error: null,
    selectedMotion: null,
  },
  cadences: {
    cadencesList: null,
    fetching: true,
    success: false,
    error: null,
  },
  form: {},
  appModal: {
    modal: null,
  },
  cadence: {
    cadence: null,
    fetching: false,
    success: false,
    error: null,
    cadenceSteps: null,
  },
  cadenceQuestions: {
    fetching: false,
    success: false,
    error: null,
    question: null,
    questions: null,
    answers: null,
    libAnswers: null,
  },
}

export default initialState
