const industryOptions = [
  {
    options: [
      { name: 'All/Any' },
      { name: 'Transportation' },
      { name: 'Telecom' },
      { name: 'Retail' },
      { name: 'Pharma' },
      { name: 'Not-for-profit' },
      { name: 'Media & Entertainment' },
      { name: 'Law' },
      { name: 'Healthcare' },
      { name: 'Government' },
      { name: 'Finance & Insurance' },
      { name: 'Accounting' },
      { name: 'Other' },
    ],
  },
  {
    label: 'Education',
    options: [{ name: 'K-12' }, { name: 'Higher Ed' }],
  },
  {
    label: 'Manufacturing',
    options: [{ name: 'Consumer Goods' }, { name: 'Capital Goods' }],
  },
  {
    label: 'IT',
    options: [
      { name: 'Internet Software' },
      { name: 'Hardware' },
      { name: 'Enterprise Software' },
      { name: 'Consulting' },
    ],
  },
]

const jobRoleOptions = [
  {
    options: [
      { name: 'All/Any' },
      { name: 'Corporate Strategy' },
      { name: 'Customer Service' },
      { name: 'CEO' },
      { name: 'Other' },
    ],
  },
  {
    label: 'Sales',
    options: [
      { name: 'CRO' },
      { name: 'Sales Operations' },
      { name: 'Sales Enablement' },
      { name: 'Channel Sales' },
      { name: 'Sales Development' },
      { name: 'Field and Inside Sales' },
      { name: 'Customer Success' },
    ],
  },
  {
    label: 'Product',
    options: [
      { name: 'Software Development' },
      { name: 'Product Management' },
      { name: 'UI/UX Design' },
      { name: 'CPO' },
    ],
  },
  {
    label: 'Marketing',
    options: [
      { name: 'Public Relations' },
      { name: 'Product Marketing' },
      { name: 'Brand Management' },
      { name: 'Digital Marketing' },
      { name: 'Field Marketing' },
      { name: 'CMO' },
    ],
  },
  {
    label: 'Legal',
    options: [{ name: 'General Counsel' }],
  },
  {
    label: 'IT',
    options: [
      { name: 'Information Security' },
      { name: 'Systems & Network Engineering' },
      { name: 'Tech Support' },
      { name: 'CIO' },
    ],
  },
  {
    label: 'HR',
    options: [
      { name: 'Recruiting' },
      { name: 'Employee relations & Compliance' },
      { name: 'HR Systems & Analytics' },
      { name: 'Learning & Development' },
      { name: 'Compensation & Benefits' },
      { name: 'CHRO' },
    ],
  },
  {
    label: 'Finance',
    options: [
      { name: 'Facilities, Maintenance, & Real Estate' },
      { name: 'Corporate Development' },
      { name: 'Treasury' },
      { name: 'Investor Relations' },
      { name: 'FP&A' },
      { name: 'Accounting' },
      { name: 'CFO' },
    ],
  },
]

const companyHeadcountOptions = [
  { name: 'All/Any' },
  { name: '1-10' },
  { name: '11-50' },
  { name: '51-200' },
  { name: '501-1,000' },
  { name: '1001-5,000' },
  { name: '5001-10,000' },
  { name: '10,000+' },
]

const geographyOptions = [
  { name: 'All/Any' },
  { name: 'North America' },
  { name: 'EMEA' },
  { name: 'APAC' },
]

const customerTypeOptions = [{ name: 'All/Any' }, { name: 'Existing Client' }, { name: 'Prospect' }]

export {
  industryOptions,
  jobRoleOptions,
  companyHeadcountOptions,
  geographyOptions,
  customerTypeOptions,
}
