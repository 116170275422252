import React, { ReactText, useEffect, useMemo, useState } from 'react'
import { TableContainer, TableHeaders, TableBody, Pagination } from '@rhythm/tables'
import { IconButton, Button } from '@rhythm/buttons'
import { getColor, getFontWeight } from '@rhythm/theme'
import { Tooltip } from '@rhythm/tooltips'
import { Skeleton } from '@rhythm/loading'
import {
  Menu,
  MenuToggle,
  MenuBody,
  MenuDropdown,
  MenuItem,
  MenuDivider,
  MenuSearchBox,
} from '@rhythm/menu'
import {
  EllipsisHorizontalIcon,
  CaretRightIcon,
  TrashIcon,
  EditIcon,
  RocketIcon,
  ChevronSmDownIcon,
  AddCircleIcon,
  CheckmarkIcon,
  RocketImage,
  ShareIcon,
  EyeIcon,
  CopyIcon,
} from '@rhythm/svgs'
import { EmptyState } from '@rhythm/empty-state'
import { SearchInput } from '@rhythm/inputs'
import { FlexContainer } from 'static'
import { useDispatch, useSelector } from 'react-redux'
import {
  cadenceActions,
  cadencesActions,
  modalActions,
  motionsActions,
  usersSharedActions,
} from 'actions'
import styled from 'styled-components'
import { CadenceNameModal, MenuForTable, DeleteCadenceModal, AppLink } from 'components'
import { RouteComponentProps } from 'react-router-dom'
import { queryToJson, updateQuery } from 'utils'
import {
  cadencesListSelector,
  cadencesFetchingSelector,
  motionsFetchingSelector,
  motionsArraySelector,
  userUsersSharedSelector,
  userFetchingSelector,
} from 'selectors'
import { ICadence, ICadenceFilters, IMotion, ISharedUser, TContainer } from 'types'
import moment from 'moment'

interface IPoprs extends RouteComponentProps {
  isProgressTab?: boolean
}

type TApplyRename = ({
  data,
  successFunc,
}: {
  data: {
    cadenceName: string
    allowDuplicate: boolean
    id: ReactText
  }
  successFunc: () => void
}) => void

type TApplyDelete = ({
  data,
  successFunc,
}: {
  data: {
    id: ReactText
  }
  successFunc: () => void
}) => void

interface ICadenceExtended extends ICadence {
  // eslint-disable-next-line react/no-unused-prop-types
  motionName: string | null
  // eslint-disable-next-line react/no-unused-prop-types
  ownerName: string | null
}

interface IOrder {
  key?: keyof ICadenceExtended
  direction: 'asc' | 'desc'
}

const PaginationBlock = styled.div`
  padding: 0.5rem 1.5rem;
  background-color: ${getColor('white')};
`

const Block = styled(FlexContainer)`
  padding: 0.5rem 2.2rem;
`

const ListContainer = styled(FlexContainer)`
  background-color: ${getColor('greyLightest')};
  & > div:first-of-type {
    overflow-x: hidden;
  }
`

// In future will be used in version 2
// const MenuBodyContainer = styled(FlexContainer)`
//   padding: 0.75rem 0.75rem 1.15rem 0.75rem;
//   & > div {
//     margin-bottom: 1rem;
//   }
//   button {
//     align-self: flex-end;
//   }
// `

const Span = styled.span`
  color: ${getColor('greyDark')};
  font-size: 0.9rem;
`

const Bold = styled(Span)`
  font-weight: 600;
`

const BlockWithBorder = styled(Block)`
  border-top: 0.1rem solid ${getColor('greyLightest')};
`
const Text = styled.span<{
  isBlue?: boolean
  isBold?: boolean
}>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ isBlue }) => getColor(isBlue ? 'greyDarkest' : 'greyDark')};
  font-weight: ${({ isBold }) => (isBold ? getFontWeight('semibold') : 'initial')};
`
// In future will be used in version 2
// const Line = styled.div`
//   border-left: 0.1rem solid ${getColor('greyLightest')};
//   margin-left: 0.5rem;
//   margin-right: 0.45rem;
// `

const FiltersContainer = styled(FlexContainer)`
  .owner {
    &:hover:not(:disabled),
    &.open {
      color: ${getColor('white')};
      background-color: ${getColor('green')};
      border-color: ${getColor('green')};
    }
  }
`

const ellipsesToggle = (id: ReactText) => ({ openMenu }: { openMenu: () => void }) => (
  <IconButton
    appearance="ghost"
    data-testid={`btnOpenMenu${id}`}
    onClick={openMenu}
    icon={<EllipsisHorizontalIcon />}
    aria-label="ellipsis horizontal icon"
  />
)

const motionToggle = (title = 'All Types', loading: boolean) => ({
  openMenu,
}: {
  openMenu: () => void
}) => (
  <Button
    iconLeft={<RocketIcon />}
    iconRight={<ChevronSmDownIcon />}
    onClick={openMenu}
    disabled={loading}
  >
    {`Motion: ${title}`}
  </Button>
)
// In future will be used in version 2
// const ownerToggle = (
//   loading: boolean,
//   onCancel: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void,
//   title?: string
// ) => ({ openMenu, isOpen }: { openMenu: () => void; isOpen: boolean }) => (
//   <Button
//     onClick={openMenu}
//     disabled={loading}
//     lineButton={!(isOpen || title)}
//     variant={isOpen || title ? 'default' : 'neutral'}
//   >
//     <IconButton>
//       <UserIcon fontSize="1.1rem" />
//     </IconButton>
//     Owner{title ? `: ${title}` : ''}
//     <IconButton>
//       {title ? <CloseIcon onClick={onCancel} /> : <ChevronSmDownIcon fontSize="1.1rem" />}
//     </IconButton>
//   </Button>
// )

const ellipsesBody = (
  id: ReactText,
  {
    onDelete,
    ...items
  }: {
    onResume?: () => void
    onRename?: () => void
    onDelete?: () => void
    onView?: () => void
    onShare?: () => void
    onCopy?: () => void
  }
) => () => (
  <MenuBody>
    {Object.entries(items).map(([name, onClick]) => {
      let icon
      switch (name) {
        case 'onResume':
          icon = CaretRightIcon
          break
        case 'onRename':
          icon = EditIcon
          break
        case 'onView':
          icon = EyeIcon
          break
        case 'onShare':
          icon = ShareIcon
          break
        default:
          icon = CopyIcon
          break
      }
      return (
        <MenuItem data-testid={`btn${name}${id}`} autoClose onClick={onClick} icon={icon}>
          {name.replace('on', '')}
        </MenuItem>
      )
    })}
    {onDelete ? (
      <>
        <MenuDivider />
        <MenuItem autoClose onClick={onDelete} variant="destructive" icon={TrashIcon}>
          Delete
        </MenuItem>
      </>
    ) : null}
  </MenuBody>
)

const showLoadingInTable = (
  columns: {
    header: string
    sortKey: string
    width?: string
    sortable?: boolean
    headerTextAlignment?: string
    cellTextAlignment?: string
    renderCell?: unknown
  }[],
  loading: boolean
) => {
  if (!loading) {
    return columns
  }
  return columns.map((column) => ({
    ...column,
    renderCell: () => <Skeleton />,
  }))
}

const fullWidthTooltip = ({
  isBlue,
  isBold,
  disabled,
  timeFormat,
}: {
  isBlue?: boolean
  isBold?: boolean
  disabled?: boolean
  timeFormat?: string
} = {}) => (
  item: ICadenceExtended,
  { sortKey }: { sortKey: keyof ICadenceExtended },
  { NULL_PLACEHOLDER }: { NULL_PLACEHOLDER: null }
) => {
  const value = item[sortKey]
  return value ? (
    <Text isBlue={isBlue} isBold={isBold}>
      <Tooltip maxWidth="100%" position="bottom" disabled={disabled} isInline title={value}>
        {timeFormat && !Number.isNaN(+value) ? moment(+value * 1000).format(timeFormat) : value}
      </Tooltip>
    </Text>
  ) : (
    NULL_PLACEHOLDER
  )
}

const columnsInProgress = ({
  onRename,
  onDelete,
  onResume,
}: {
  onRename: (id: ReactText, name: string) => () => void
  onDelete: (id: ReactText) => () => void
  onResume: (id: ReactText) => () => void
}) => [
  {
    header: '',
    sortKey: 'padding-left',
    width: '1.1rem',
  },
  {
    header: 'CADENCE',
    sortable: true,
    sortKey: 'name',
    headerTextAlignment: 'left',
    cellTextAlignment: 'left',
    width: '33%',
    renderCell: (
      item: ICadenceExtended,
      sort: { sortKey: keyof ICadenceExtended },
      NULL_PLACEHOLDER: { NULL_PLACEHOLDER: null }
    ) => (
      <AppLink to={`/cadences/${item.id}`}>
        {fullWidthTooltip({ isBlue: true, isBold: true })(item, sort, NULL_PLACEHOLDER)}
      </AppLink>
    ),
  },
  {
    header: 'MOTION',
    sortable: true,
    sortKey: 'motionName',
    headerTextAlignment: 'left',
    cellTextAlignment: 'left',
    width: '20%',
    renderCell: fullWidthTooltip(),
  },
  {
    header: 'DATE',
    sortable: true,
    sortKey: 'created_at',
    renderCell: fullWidthTooltip({ disabled: true, timeFormat: 'MM/DD/YYYY' }),
  },
  {
    header: 'UPDATED',
    sortable: true,
    sortKey: 'updated_at',
    renderCell: fullWidthTooltip({ disabled: true, timeFormat: 'MM/DD/YYYY hh:mm a' }),
  },
  {
    header: '',
    sortKey: 'ellipse',
    width: '10%',
    renderCell: ({ id, name }: ICadenceExtended) => (
      <MenuForTable
        toggleView={ellipsesToggle(id)}
        bodyView={ellipsesBody(id, {
          onResume: onResume(id),
          onRename: onRename(id, name),
          onDelete: onDelete(id),
        })}
      />
    ),
  },
  {
    header: '',
    sortKey: 'padding-right',
    width: '1.1rem',
  },
]

const columnsBuild = ({
  onRename,
  onDelete,
  onView,
}: {
  onRename: (id: ReactText, name: string) => () => void
  onDelete: (id: ReactText) => () => void
  onView: (id: ReactText) => () => void
}) => [
  {
    header: '',
    sortKey: 'padding-left',
    width: '1.1rem',
  },
  {
    header: 'CADENCE',
    sortable: true,
    sortKey: 'name',
    headerTextAlignment: 'left',
    cellTextAlignment: 'left',
    width: '20%',
    renderCell: (
      item: ICadenceExtended,
      sort: { sortKey: keyof ICadenceExtended },
      NULL_PLACEHOLDER: { NULL_PLACEHOLDER: null }
    ) => (
      <AppLink to={`/cadences/${item.id}`}>
        {fullWidthTooltip({ isBlue: true, isBold: true })(item, sort, NULL_PLACEHOLDER)}
      </AppLink>
    ),
  },
  {
    header: 'MOTION',
    sortable: true,
    sortKey: 'motionName',
    headerTextAlignment: 'left',
    cellTextAlignment: 'left',
    width: '15%',
    renderCell: fullWidthTooltip(),
  },
  // {
  //   header: 'OWNER',
  //   sortable: true,
  //   sortKey: 'ownerName',
  //   headerTextAlignment: 'left',
  //   cellTextAlignment: 'left',
  //   width: '15%',
  //   renderCell: fullWidthTooltip(),
  // },
  {
    header: 'CREATED',
    sortable: true,
    sortKey: 'created_at',
    renderCell: fullWidthTooltip({ disabled: true, timeFormat: 'MM/DD/YYYY' }),
  },
  {
    header: 'UPDATED',
    sortable: true,
    sortKey: 'updated_at',
    renderCell: fullWidthTooltip({ disabled: true, timeFormat: 'MM/DD/YYYY hh:mm' }),
  },
  // {
  //   header: 'SHARED WITH',
  //   sortKey: 'shared_with',
  //   renderCell: fullWidthTooltip({ disabled: true, isBlue: true }),
  // },
  {
    header: '',
    sortKey: 'ellipse',
    width: '7.5%',
    renderCell: ({ id, name }: ICadenceExtended) => (
      <MenuForTable
        toggleView={ellipsesToggle(id)}
        bodyView={ellipsesBody(id, {
          onView: onView(id),
          onRename: onRename(id, name),
          onDelete: onDelete(id),
        })}
      />
    ),
  },
  {
    header: '',
    sortKey: 'padding-right',
    width: '1.1rem',
  },
]

const CadencesList = ({ location: { search, pathname }, history, isProgressTab }: IPoprs) => {
  const dispatch = useDispatch()
  const { list: cadences = [], totalCount = 0, totalPages = 0 } =
    useSelector(cadencesListSelector) || {}
  const fetchingCadences = useSelector(cadencesFetchingSelector)
  const fetchingMotions = useSelector(motionsFetchingSelector)
  const fetchingUsersShared = useSelector(userFetchingSelector)
  const fetching = fetchingCadences || fetchingMotions || fetchingUsersShared
  const usersShared = useSelector(userUsersSharedSelector) || []
  const motions = useSelector(motionsArraySelector) || []
  const [cadenceSearch, setCadenceSearch] = useState('')
  const { page, motionId, cadenceName, order, ownerId } = useMemo(() => {
    const query = queryToJson(search)
    const newQuery = new URLSearchParams(query)
    let needReplaceQuery = false
    let currentPage = +query.page
    let currentOwnerId = +query.ownerId || undefined
    let currentMotionId = +query.motionId || undefined
    if (Number.isNaN(currentPage) || currentPage <= 0) {
      newQuery.append('page', '1')
      currentPage = 1
      needReplaceQuery = true
    }
    if (currentOwnerId && (Number.isNaN(currentOwnerId) || currentOwnerId < 0)) {
      currentOwnerId = undefined
      newQuery.delete('ownerId')
      needReplaceQuery = true
    }
    if (currentMotionId && (Number.isNaN(currentMotionId) || currentMotionId < 0)) {
      currentMotionId = undefined
      newQuery.delete('motionId')
      needReplaceQuery = true
    }
    if (totalPages !== 0 && currentPage > totalPages) {
      newQuery.set('page', totalPages.toString())
      currentPage = totalPages
    }
    let orderValue: IOrder = {
      direction: 'desc',
      key: 'updated_at',
    }
    try {
      orderValue = JSON.parse(query.order)
    } catch (e) {
      newQuery.set('order', JSON.stringify(orderValue))
      needReplaceQuery = true
    }
    if (query.cadenceName !== cadenceSearch) {
      setCadenceSearch(query.cadenceName || '')
    }
    if (needReplaceQuery) {
      history.replace(`${pathname}?${newQuery}`)
    }
    return {
      page: currentPage,
      motionId: currentMotionId,
      cadenceName: query.cadenceName,
      order: orderValue,
      ownerId: currentOwnerId,
    }
  }, [search])
  const [motionSearch, setMotionSearch] = useState('')
  const [filters, setFilters] = useState<{
    motion?: IMotion
    owner?: ISharedUser | Record<string, never>
  }>({
    motion: motions.find(({ id }) => id === motionId),
    owner: usersShared.find(({ id }) => id === ownerId),
  })
  const list: ICadenceExtended[] = useMemo(
    () =>
      cadences.map((cadence) => ({
        ...cadence,
        motionName: cadence.motion ? cadence.motion.name : null,
        ownerName: cadence.owner ? cadence.owner.name : null,
      })),
    [cadences]
  )

  const updateUrl = (params: Record<string, string | undefined>) => {
    const query = updateQuery(search, params)
    history.replace(`${pathname}?${query}`)
  }

  const changeMotionSearch = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
    setMotionSearch(value.toLowerCase())

  const changeMotion = (motion?: IMotion) => () => {
    updateUrl({ motionId: motion?.id.toString() })
    setFilters((prev) => ({
      ...prev,
      motion,
    }))
  }

  const motionsList = useMemo(
    () =>
      motions.reduce((views, motionElem) => {
        if (
          motionElem.name.toLowerCase().indexOf(motionSearch) !== -1 &&
          motionId !== motionElem.id
        ) {
          views.push(
            <MenuItem
              key={motionElem.id}
              onClick={changeMotion(motionElem)}
              autoClose
              truncate={motionElem.name.length >= 25}
              title={motionElem.name}
            >
              {motionElem.name}
            </MenuItem>
          )
        }
        return views
      }, new Array<JSX.Element>()),
    [motions, motionSearch, motionId]
  )

  const openCadenceModal = ({
    isDelete,
    onApply,
    ...modalProps
  }: {
    title: string
    id: ReactText
    isDelete?: boolean
    onApply: TApplyRename | TApplyDelete
    name?: string
    applyMessage?: string
  }) => () =>
    dispatch(
      modalActions.update({
        modal: {
          title: modalProps.title,
          width: '32rem',
          onClose: () => {
            dispatch(cadenceActions.failure(null))
          },
          body: (bodyProps: { body: TContainer; footer: TContainer; onClose: () => void }) =>
            isDelete ? (
              <DeleteCadenceModal
                {...bodyProps}
                {...modalProps}
                onApply={onApply as TApplyDelete}
              />
            ) : (
              <CadenceNameModal {...bodyProps} {...modalProps} onApply={onApply as TApplyRename} />
            ),
        },
      })
    )

  const getCadences = () => {
    const otherFilters: ICadenceFilters = {
      page,
      filter: {
        search: cadenceName,
        motion: {
          id: motionId,
        },
        owner: {
          id: ownerId,
        },
      },
      order: order.key && {
        [order.key]: order.direction,
      },
    }
    if (isProgressTab) {
      otherFilters.filter.in_progress = 'in_progress'
    } else {
      otherFilters.filter.built_and_archived = 'built_and_archived'
    }
    if (
      otherFilters.order &&
      order.key &&
      (order.key === 'motionName' || order.key === 'ownerName')
    ) {
      delete otherFilters.order[order.key]
      otherFilters.order[order.key.replace('Name', '')] =
        order.key === 'motionName'
          ? {
              name: order.direction,
            }
          : {
              first_name: order.direction,
              last_name: order.direction,
            }
    }
    dispatch(
      cadencesActions.request({
        data: otherFilters,
        successFunc: ({ totalPages: allPages }) => {
          if (allPages < page) {
            updateUrl({ page: allPages.toString() })
          }
        },
      })
    )
  }

  const changeCadenceSearch = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
    setCadenceSearch(value)

  // In future will be used in version 2
  // const changeOwnerFilter = ({ name }: { name: string }) =>
  //   setFilters((prev) => ({
  //     ...prev,
  //     owner: name === 'All Users' ? undefined : {},
  //   }))

  // const changeSpecificFilter = (owner: ISharedUser) =>
  //   setFilters((prev) => ({
  //     ...prev,
  //     owner,
  //   }))

  const changePage = (pageNumber: number) => updateUrl({ page: pageNumber.toString() })

  const addCadence = (arg: {
    data: {
      cadenceName: string
      allowDuplicate: boolean
    }
    successFunc: () => void
  }) =>
    dispatch(
      cadenceActions.request({
        ...arg,
        successFunc: (cadence: ICadence) => {
          arg.successFunc()
          history.push(`/cadences/${cadence.id}`)
        },
      })
    )

  const renameCadence = (arg: {
    data: {
      id: ReactText
      cadenceName: string
      allowDuplicate: boolean
    }
    successFunc: () => void
  }) =>
    dispatch(
      cadenceActions.update({
        ...arg,
        successFunc: () => {
          getCadences()
          arg.successFunc()
        },
      })
    )

  const deleteCadence = (arg: {
    data: {
      id: ReactText
    }
    successFunc: () => void
  }) =>
    dispatch(
      cadenceActions.delete({
        id: arg.data.id,
        successFunc: () => {
          getCadences()
          arg.successFunc()
        },
      })
    )

  // Mockup for now. Will change in task with cadence creation steps.
  const resumeCadence = (cadenceId: ReactText) => () =>
    history.push(`/cadences/${cadenceId.toString()}`)

  const columnsInProgressMemo = useMemo(
    () =>
      showLoadingInTable(
        columnsInProgress({
          onRename: (id: ReactText, name: string) =>
            openCadenceModal({
              title: 'Rename Cadence',
              applyMessage: 'Cadence Renamed',
              onApply: renameCadence,
              id,
              name,
            }),
          onDelete: (id: ReactText) =>
            openCadenceModal({
              title: 'Delete Cadence',
              onApply: deleteCadence,
              id,
              isDelete: true,
            }),
          onResume: (id: ReactText) => resumeCadence(id),
        }),
        fetching
      ),
    [fetching]
  )

  const handleSort = (sortKey: keyof ICadenceExtended) => {
    const object: IOrder = {
      key: sortKey,
      direction: 'desc',
    }
    if (object.key === order.key && order.direction === 'desc') {
      object.direction = 'asc'
    }
    updateUrl({ order: JSON.stringify(object) })
  }

  // In future will be used in version 2
  // const menuOwnersBody = ({ closeMenu }: { closeMenu: () => void }) => (
  //   <MenuBodyContainer column alignItems="center" flexSize={1}>
  //     <Select
  //       getOptionLabel={(o: { name: string }) => o.name}
  //       options={[{ name: 'All Users' }, { name: 'Specific User' }]}
  //       onChange={changeOwnerFilter}
  //       value={filters.owner ? { name: 'Specific User' } : { name: 'All Users' }}
  //     />
  //     {!!filters.owner && (
  //       <Select
  //         getOptionLabel={(o: { name: string }) => o.name}
  //         options={usersShared}
  //         onChange={changeSpecificFilter}
  //         value={filters.owner.name ? filters.owner : undefined}
  //         placeholder="Search of Select"
  //       />
  //     )}
  //     <Button
  //       onClick={() => {
  //         closeMenu()
  //         updateUrl({ ownerId: filters.owner?.id.toString() })
  //       }}
  //       disabled={filters.owner && !filters.owner.id}
  //     >
  //       Apply
  //     </Button>
  //   </MenuBodyContainer>
  // )

  const getMotions = (resolve?: (value: unknown) => void, reject?: () => void) =>
    dispatch(
      motionsActions.get({
        successFunc: (motionsArray) => {
          setFilters((prev) => ({
            ...prev,
            motion: motionsArray.find(({ id }) => id === motionId),
          }))
          if (resolve) {
            resolve(motionsArray)
          }
        },
        errorFunc: () => reject && reject(),
      })
    )

  const getUsersShared = (resolve?: (value: unknown) => void, reject?: () => void) =>
    dispatch(
      usersSharedActions.request({
        successFunc: (usersSharedList) => {
          setFilters((prev) => ({
            ...prev,
            owner: usersSharedList.find(({ id }) => id === ownerId),
          }))
          if (resolve) {
            resolve(usersSharedList)
          }
        },
        errorFunc: () => reject && reject(),
      })
    )

  const columnsBuildMemo = useMemo(
    () =>
      showLoadingInTable(
        columnsBuild({
          onRename: (id: ReactText, name: string) =>
            openCadenceModal({
              title: 'Rename Cadence',
              applyMessage: 'Cadence Renamed',
              onApply: renameCadence,
              id,
              name,
            }),
          onDelete: (id: ReactText) =>
            openCadenceModal({
              title: 'Delete Cadence',
              onApply: deleteCadence,
              id,
              isDelete: true,
            }),
          onView: (id: ReactText) => () => history.push(`/cadences/${id}`),
        }),
        fetching
      ),
    [fetching]
  )

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      updateUrl({ cadenceName: cadenceSearch })
    }
  }

  const resetFilters = () => {
    updateUrl({ ownerId: undefined, motionId: undefined })
  }

  useEffect(() => {
    Promise.all([
      new Promise((resolve, reject) => {
        getMotions(resolve, reject)
      }),
      new Promise((resolve, reject) => {
        getUsersShared(resolve, reject)
      }),
    ])
      .then(() => {
        getCadences()
      })
      .catch(() => {})
  }, [page, isProgressTab, motionId, cadenceName, order.direction, order.key, ownerId])

  const isShowResetFilters = motionId || ownerId
  const data = fetching ? [{}] : list
  const columns = isProgressTab ? columnsInProgressMemo : columnsBuildMemo
  const menuProps = { value: motionSearch }
  return (
    <FlexContainer column flexSize={1}>
      <Block justifyContent="space-between">
        <FiltersContainer>
          <Menu>
            <MenuToggle>
              {motionToggle(motionId ? filters.motion?.name : undefined, fetching)}
            </MenuToggle>
            <MenuDropdown horizontalPosition="left">
              <div
                ref={(input: HTMLDivElement) => input?.getElementsByTagName('input')[0]?.focus()}
              >
                <MenuSearchBox
                  searchLabel="Search Motions"
                  placeholder="Search Motions"
                  onChange={changeMotionSearch}
                  {...menuProps}
                />
              </div>
              <MenuBody>
                <MenuItem
                  key="All Types"
                  icon={!motionId ? CheckmarkIcon : undefined}
                  highlighted={!motionId}
                  onClick={changeMotion()}
                  autoClose
                >
                  All Types
                </MenuItem>
                {!!filters.motion &&
                  filters.motion.name.toLowerCase().indexOf(motionSearch) !== -1 && (
                    <MenuItem
                      key={filters.motion.id}
                      icon={CheckmarkIcon}
                      highlighted
                      truncate={filters.motion.name.length >= 25}
                      title={filters.motion.name}
                    >
                      {filters.motion.name}
                    </MenuItem>
                  )}
                {motionsList}
              </MenuBody>
            </MenuDropdown>
          </Menu>
          {/* In future will be used in version 2 */}
          {/* {!isProgressTab && (
            <>
              <Line />
              <Menu>
                <MenuToggle>
                  {ownerToggle(
                    fetching,
                    (event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      updateUrl({ ownerId: undefined })
                    },
                    ownerId ? filters.owner?.name : ''
                  )}
                </MenuToggle>
                <MenuDropdown horizontalPosition="left">
                  <MenuBody maxWidth="auto">
                    <MenuToggle>{menuOwnersBody}</MenuToggle>
                  </MenuBody>
                </MenuDropdown>
              </Menu>
            </>
          )} */}
          {isShowResetFilters && (
            <Button appearance="ghost" onClick={resetFilters}>
              Reset All Filters
            </Button>
          )}
        </FiltersContainer>
        <SearchInput
          value={cadenceSearch}
          onClickSearch={() => updateUrl({ cadenceName: cadenceSearch })}
          onChange={changeCadenceSearch}
          onClear={() => updateUrl({ cadenceName: undefined })}
          placeholder="Search Cadence Name"
          onKeyDown={handleEnter}
          showClearButton={cadenceSearch === cadenceName}
          maxLength="100"
        />
      </Block>
      <BlockWithBorder justifyContent="space-between" alignItems="center">
        <Span>
          <Bold>{totalCount}</Bold> Cadences
        </Span>
        <Button
          appearance="ghost"
          onClick={openCadenceModal({
            title: 'Add Cadence',
            applyMessage: 'Cadence Added',
            onApply: addCadence,
            id: '',
          })}
          iconLeft={<AddCircleIcon />}
          aria-label="add icon button"
        >
          Add Cadence
        </Button>
      </BlockWithBorder>
      {list.length > 0 || fetching ? (
        <ListContainer column flexSize={1}>
          <TableContainer>
            <TableHeaders
              columns={columns}
              data={data}
              enableMultiSelect={false}
              enableSuperSelect={false}
              selected={new Set([])}
              sortKey={order.key}
              sortDirection={order.direction}
              onSort={handleSort}
            />
            <TableBody columns={columns} data={data} selected={new Set([])} />
          </TableContainer>
          <PaginationBlock>
            <Pagination
              showFirstLast
              currentPage={page}
              goToPage={changePage}
              totalPages={+totalPages}
            />
          </PaginationBlock>
        </ListContainer>
      ) : (
        <EmptyState
          title="Where, oh where is that cadence?"
          text="Let’s tinker with your filters and try again… or if you haven't already, create your first cadence!"
          thumbnail={<RocketImage width="300px" height="300px" />}
        >
          <Button
            onClick={openCadenceModal({
              title: 'Add Cadence',
              applyMessage: 'Cadence Added',
              onApply: addCadence,
              id: '',
            })}
          >
            Create Cadence
          </Button>
        </EmptyState>
      )}
    </FlexContainer>
  )
}

export default CadencesList
