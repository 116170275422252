import { createActionTypes } from 'utils'

const SIGNUP = createActionTypes('SIGNUP')
const SIGNIN = createActionTypes('SIGNIN')
const GLOBAL_MESSAGE = createActionTypes('GLOBAL_MESSAGE')
const MOTIONS = createActionTypes('MOTIONS')
const SIGNOUT = createActionTypes('SIGNOUT')
const FORGOT_PASSWORD = createActionTypes('FORGOT_PASSWORD')
const PASSWORD_RECOVERY = createActionTypes('PASSWORD_RECOVERY')
const SIGNIN_SALESLOFT = createActionTypes('SIGNIN_SALESLOFT')
const CADENCES = createActionTypes('CADENCES')
const CADENCE = createActionTypes('CADENCE')
const GLOBAL_MODAL = createActionTypes('GLOBAL_MODAL')
const OBJECTIVES = createActionTypes('OBJECTIVES')
const USER = createActionTypes('USER')
const DURATION_AND_INTENSITY = createActionTypes('DURATION_AND_INTENSITY')
const CADENCE_QUESTIONS = createActionTypes('CADENCE_QUESTIONS')
const STEPS = createActionTypes('STEPS')
const CADENCE_PERSONA = createActionTypes('CADENCE_PERSONA')
const CADENCE_STEPS = createActionTypes('CADENCE_STEPS')
const CADENCE_STEP = createActionTypes('CADENCE_STEP')
const DYNAMIC_TAGS = createActionTypes('DYNAMIC_TAGS')
const CADENCE_QUESTION_ANSWERS = createActionTypes('CADENCE_QUESTION_ANSWERS')
const CURRENT_USER = createActionTypes('CURRENT_USER')
const CADENCE_EXPORT = createActionTypes('CADENCE_EXPORT')
const FEEDBACK = createActionTypes('FEEDBACK')

export {
  SIGNUP,
  SIGNIN,
  GLOBAL_MESSAGE,
  SIGNOUT,
  FORGOT_PASSWORD,
  PASSWORD_RECOVERY,
  SIGNIN_SALESLOFT,
  CADENCES,
  MOTIONS,
  GLOBAL_MODAL,
  CADENCE,
  OBJECTIVES,
  USER,
  DURATION_AND_INTENSITY,
  CADENCE_QUESTIONS,
  STEPS,
  CADENCE_PERSONA,
  CADENCE_STEPS,
  CADENCE_STEP,
  DYNAMIC_TAGS,
  CADENCE_QUESTION_ANSWERS,
  CURRENT_USER,
  CADENCE_EXPORT,
  FEEDBACK,
}
