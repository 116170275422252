import React, { useMemo, InputHTMLAttributes } from 'react'
import { IPasswordErrors } from 'types'
import styled from 'styled-components'
import { FlexContainer } from 'static'
import { WrappedFieldProps } from 'redux-form'
import { getColor, getFontWeight } from '@rhythm/theme'
import { firstLetterUppercase } from 'utils'
import FormInput from '../FormInput/FormInput'

interface IProps {
  noSetCustomValidity?: boolean
}

const Span = styled.span`
  font-size: 0.75rem;
  color: ${getColor('greyDarkest')};
`
const Step = styled(Span)<{ done: boolean }>`
  color: ${({ done }) => (done ? getColor('red') : 'intial')};
  font-weight: ${getFontWeight('semibold')};
  border-bottom: 0.3rem solid ${({ done }) => (done ? getColor('redLight') : getColor('greyLight'))};
  margin: 0.25rem 0.5rem 0.5rem 0;
  padding-bottom: 0.25rem;
`

const StepContainer = styled(FlexContainer)`
  margin-top: 0.4rem;
`

const parseError = (metaError: string) => {
  try {
    return JSON.parse(metaError)
  } catch {
    return {}
  }
}

const appendToErrorMessage = (currentMessage: string, message: string) => {
  const sentence = firstLetterUppercase(message)
  return currentMessage ? `${currentMessage}. ${sentence}` : sentence
}

const getErrorMessage = (errors: IPasswordErrors) => {
  if (!Object.keys(errors).length) {
    return ''
  }
  if (errors.required) {
    return ' '
  }
  let error = ''
  if (errors.size || errors.lowercase || errors.uppercase || errors.digit) {
    error = appendToErrorMessage(error, 'Invalid  Password')
  }
  if (errors.spaces) {
    error = appendToErrorMessage(error, errors.spaces)
  }
  if (errors.message) {
    error = appendToErrorMessage(error, errors.message)
  }
  return error
}

const PasswordFiledVisualValidation = ({
  meta,
  input,
  noSetCustomValidity = false,
  ...props
}: WrappedFieldProps & InputHTMLAttributes<HTMLInputElement> & IProps) => {
  const errors: IPasswordErrors = useMemo(() => parseError(meta.error), [meta.error])
  const error = useMemo(() => getErrorMessage(errors), [errors])
  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!noSetCustomValidity) {
      event.target.setCustomValidity(error || '')
    }
    input.onBlur(event)
  }

  return (
    <FlexContainer column flexSize={1}>
      <FormInput
        {...props}
        meta={{ ...meta, error }}
        input={{ ...input, onBlur }}
        noSetCustomValidity={noSetCustomValidity}
      />
      <StepContainer column>
        <Span>Password requirements</Span>
        <FlexContainer>
          <Step done={!!errors.size}>8 characters</Step>
          <Step done={!!errors.lowercase}>lowercase letter</Step>
          <Step done={!!errors.uppercase}>uppercase letter</Step>
          <Step done={!!errors.digit}>number</Step>
        </FlexContainer>
      </StepContainer>
    </FlexContainer>
  )
}

export default PasswordFiledVisualValidation
