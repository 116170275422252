import { createSelector } from 'reselect'
import { IAppState } from 'types'

const motionsSelector = ({ motions }: IAppState) => motions

const motionsListSelector = createSelector(motionsSelector, ({ motionsList }) => motionsList)

const motionsArraySelector = createSelector(motionsSelector, ({ motions }) => motions)

const motionsFetchingSelector = createSelector(motionsSelector, ({ fetching }) => fetching)

const durationAndIntensitySelector = createSelector(
  motionsSelector,
  ({ selectedMotion }) => selectedMotion
)

export {
  motionsListSelector,
  durationAndIntensitySelector,
  motionsArraySelector,
  motionsFetchingSelector,
}
