import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const CustomLink = styled(Link)`
  color: inherit;
`

const SignUpAlert = () => (
  <>
    The account with this email already exist. <CustomLink to="/signin">Sign in</CustomLink>
  </>
)

export default SignUpAlert
