import React, { useEffect, useMemo } from 'react'
import { CadenceOverviewPage } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { cadenceStepsActions } from 'actions'
import { cadenceStepsSelector } from 'selectors'
import { CadenceStep, Day, ICadence } from 'types'

interface IProps {
  cadence: ICadence
}

const CadenceOverview = ({ cadence }: IProps) => {
  const dispatch = useDispatch()
  const cadenceSteps = useSelector(cadenceStepsSelector)
  const steps = useMemo(() => {
    const stepsList: Record<number, Day> = {}
    if (cadenceSteps) {
      cadenceSteps.forEach((step: CadenceStep) => {
        if (!stepsList[step.publish_at]) {
          stepsList[step.publish_at] = {
            publish_at: step.publish_at,
            steps: [],
          }
        }
        const element = stepsList[step.publish_at]
        element?.steps.push({ id: step.id, order: step.order, name: step.name, ...step.stepable })
      })
    }
    return Object.values(stepsList)
  }, [cadenceSteps])

  useEffect(() => {
    dispatch(cadenceStepsActions.request({ data: { id: cadence.id } }))
  }, [cadence.id])

  return <CadenceOverviewPage cadence={cadence} cadenceSteps={steps} />
}

export default CadenceOverview
