import React from 'react'
import styled from 'styled-components'
import { getColor, getFontWeight } from '@rhythm/theme'
import { Tooltip } from '@rhythm/tooltips'
import { InfoCircleIcon } from '@rhythm/svgs'
import { FlexContainer } from 'static'

const CounterTitle = styled.div`
  height: 14px;
  color: ${getColor('grey')};
  font-size: 12px;
  font-weight: ${getFontWeight('semibold')};
  line-height: 14px;
`

const Counter = styled.div`
  color: ${getColor('greyDarkest')};
  font-size: 20px;
  line-height: 24px;
`

const InfoIcon = styled(InfoCircleIcon)`
  color: ${getColor('greyLight')};
  height: 15px;
  min-width: 15px;
  margin: 0 0 5px 2px;

  &:hover {
    color: ${getColor('grey')};
  }
`

const TooltipWrapper = styled.div`
  margin-bottom: -15px;
`

const CounterContainer = styled.div<{ isStepsCounter: boolean }>`
  margin-right: ${({ isStepsCounter }) => (isStepsCounter ? 'auto' : '0')};
`

const CadenceOverviewCounter = ({ title, value }: { title: string; value: string | number }) => (
  <CounterContainer isStepsCounter={title === 'Steps'}>
    <FlexContainer>
      <CounterTitle>{title}</CounterTitle>
      {title === 'Steps' && (
        <TooltipWrapper>
          <Tooltip
            maxWidth="100%"
            isInline
            truncate={false}
            title="Only cadence with 50 steps or less can be exported to Salesloft"
          >
            <InfoIcon aria-label="info icon" />
          </Tooltip>
        </TooltipWrapper>
      )}
    </FlexContainer>
    <Counter>{value}</Counter>
  </CounterContainer>
)

export default CadenceOverviewCounter
