import React from 'react'
import { forgotPasswordActions, messageActions } from 'actions'
import { BlockContainer, ForgotPasswordForm } from 'components'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { FlexContainer } from 'static'
import styled, { css } from 'styled-components'
import { getColor } from '@rhythm/theme'
import { IForgotPassword } from 'types'
import { Helmet } from 'react-helmet'
import AppLink from '../../components/Common/AppLink/AppLink'

const Container = styled(FlexContainer)`
  margin-top: 12rem;
`

const LinkStyled = styled(AppLink)`
  margin-top: 0.5rem;
  margin-right: 1rem;
  align-self: flex-end;
`

const Span = styled.span`
  font-size: 0.9rem;
  color: ${getColor('greyDark')};
  margin-bottom: 2rem;
`
const Title = styled(Span)`
  font-size: 1.3rem;
  margin-bottom: 2.5rem;
  text-align: center;
`

const BlockContainerStyles = css`
  padding: 3.5rem 2rem;
  width: 27rem;
`

const ForgotPassword = ({ history }: RouteComponentProps) => {
  const dispatch = useDispatch()
  const forgotPassword = (value: IForgotPassword) => {
    dispatch(
      forgotPasswordActions.request({
        value,
        successFunc: () => {
          dispatch(
            messageActions.update({
              message: {
                type: 'info',
                body: `
                If your email address exists in our database, 
                you will receive a password recovery link at 
                your email address in a few minutes
              `,
              },
            })
          )
          history.push('/signin')
        },
        errorFunc: () => {
          dispatch(
            messageActions.update({
              message: {
                type: 'info',
                body: `
                If your email address exists in our database, 
                you will receive a password recovery link at 
                your email address in a few minutes
              `,
              },
            })
          )
          history.push('/signin')
        },
      })
    )
  }
  return (
    <Container justifyContent="center">
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <BlockContainer styles={BlockContainerStyles}>
        <Title>Password Recovery</Title>
        <Span>
          Please enter your email to recieve a link that will allow you to set a new password.
        </Span>
        <ForgotPasswordForm onSubmit={forgotPassword} />
        <LinkStyled to="/signin">Remembered Password</LinkStyled>
      </BlockContainer>
    </Container>
  )
}

export default ForgotPassword
