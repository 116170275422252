import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, RouteComponentProps, useLocation } from 'react-router-dom'
import { userAuthTokenSelector } from 'selectors'
import { localStorageAuthToken, queryToJson } from 'utils'
import { signinActions } from 'actions'

const AuthHandler = (
  component: (args: RouteComponentProps) => JSX.Element,
  authNoNeed = false,
  resetPassword?: boolean
) => {
  const authToken = useSelector(userAuthTokenSelector)
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { token } = useMemo(() => queryToJson(search), [search])

  useEffect(() => {
    if (token && !authToken && !localStorageAuthToken.get()) {
      const tokenString = `Bearer ${token}`
      localStorageAuthToken.set(tokenString)
      dispatch(signinActions.success(tokenString))
    }
  }, [token])

  if (authToken && resetPassword) {
    return component
  }

  if (authToken && authNoNeed) {
    return () => <Redirect to="/cadenceList" />
  }

  if (!authToken && !authNoNeed) {
    return () => <Redirect to="/signin" />
  }

  return component
}

export default AuthHandler
