import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { IMotion, IObjective } from 'types'
import { getColor, getFontWeight, getTransparentizedColor } from '@rhythm/theme'
import { PeopleEmptyImage } from '@rhythm/svgs'
import { EmptyState } from '@rhythm/empty-state'
import { H5 } from '@rhythm/typography'
import { ContainerWithMenu, TextHighlighter, Loader, FooterButtons } from 'components'
import { UserMenu } from 'containers'
import { FlexContainer } from 'static'

import MotionsFilter from './MotionsFilter'
import MotionsSearch from './MotionsSearch'

type MixinProps = {
  isMotionSelected: boolean
}

const Container = styled(FlexContainer)`
  overflow-y: hidden;
  > div {
    height: 100%;
  }

  .highlight {
    background-color: ${getColor('orangeLightest')};
  }
`

const Background = styled(FlexContainer)`
  background-color: ${getColor('white')};
  box-shadow: inset 0 -1px 0 0 ${getColor('greyLightest')};
  flex-wrap: wrap;
  padding-left: 36px;
  padding-right: 29px;
`

const Title = styled(H5)`
  padding: 1.4rem 0rem 1rem 2.25rem;
  color: ${getColor('greyDarkest')};
  line-height: 1.15rem;
  margin: 0;
  box-shadow: inset 0 -1px 0 0 ${getColor('greyLightest')};
`

const MotionsListWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 271px);
`

const Objective = styled.div`
  padding: 12px 36px;
  color: ${getColor('greyDark')};
  font-size: 16px;
  font-weight: ${getFontWeight('semibold')};
  line-height: 24px;
`

const MotionsWrapper = styled(FlexContainer)`
  flex-wrap: wrap;
  width: 100%;
  background-color: ${getColor('greyLightest')};
  min-height: 272px;
  padding-left: 36px;
`

const motionSelectedMixin = ({ isMotionSelected }: MixinProps) =>
  isMotionSelected &&
  css`
    box-shadow: 0 0 0 2px ${getTransparentizedColor('green', 0.7)};
  `

const MotionContainer = styled.div<{ isMotionSelected: boolean }>`
  box-sizing: border-box;
  height: 182px;
  width: 320px;
  min-width: 320px;
  background-color: ${getColor('white')};
  margin: 20px 30px 10px 0;
  border: 1px solid
    ${({ isMotionSelected }) => (isMotionSelected ? getColor('green') : getColor('greyLight'))};
  border-radius: 2px;
  box-shadow: none;
  cursor: pointer;
  ${motionSelectedMixin}

  &:hover {
    box-shadow: 0 4px 8px 0 ${getTransparentizedColor('black', 0.75)};
    ${motionSelectedMixin}
  }

  &:last-child {
    margin-bottom: 64px;
  }
`

const MotionName = styled(FlexContainer)`
  text-align: center;
  height: 56px;
  font-size: 18px;
  font-weight: ${getFontWeight('semibold')};
  line-height: 22px;
  color: ${getColor('greyDark')};
  padding: 0 24px;
  box-shadow: inset 0 -1px 0 0 ${getColor('greyLight')};
`

const MotionDescription = styled(FlexContainer)`
  color: ${getColor('greyDark')};
  font-size: 14px;
  line-height: 16px;
  height: 126px;
  padding: 0 24px;
`

const CounterContainer = styled(FlexContainer)`
  color: ${getColor('greyDark')};
  font-size: 14px;
  font-weight: ${getFontWeight('medium')};
  line-height: 16px;
  margin-right: auto;
`

const Counter = styled.div`
  font-weight: ${getFontWeight('semibold')};
`

const EmptyStateWrappper = styled.div`
  padding-top: 167px;
`
export interface IProps {
  motionsList: IObjective[] | null
  handleChangeObjective(name?: string): void
  handleSearchMotions(value?: string): void
  defaultSearchValue: string
  handleRedirect(selectedMotionId?: number): () => void
  motionInCadence?: number
}

const MotionsList = ({
  motionsList,
  defaultSearchValue,
  handleChangeObjective,
  handleSearchMotions,
  handleRedirect,
  motionInCadence,
}: IProps) => {
  const [selectedMotion, setSelectedMotion] = useState<number>(motionInCadence || 0)
  const [searchValue, setSearchValue] = useState<string>(defaultSearchValue || '')
  const [isSearchActive, setSearchActive] = useState<boolean>(!!defaultSearchValue)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(motionsList === null)
  }, [motionsList])

  useEffect(() => {
    if (motionInCadence) {
      setSelectedMotion(motionInCadence)
    }
  }, [motionInCadence])

  const handleMotionClick = (motionId: number) => () => {
    setSelectedMotion(motionId)
  }

  const renderMotions = (objective: IObjective) =>
    objective.motions.map((motion: IMotion) => (
      <MotionContainer
        key={motion.id}
        isMotionSelected={motion.id === selectedMotion}
        onClick={handleMotionClick(motion.id)}
      >
        <MotionName alignItems="center" justifyContent="center">
          <TextHighlighter
            text={motion.name}
            isSearchActive={isSearchActive}
            searchWord={searchValue}
          />
        </MotionName>
        <MotionDescription alignItems="center">
          <TextHighlighter
            text={motion.description}
            isSearchActive={isSearchActive}
            searchWord={searchValue}
          />
        </MotionDescription>
      </MotionContainer>
    ))

  const renderObjectives = () =>
    motionsList?.map((objective: IObjective) => (
      <div key={objective.id}>
        <Objective>
          <TextHighlighter
            text={objective.name}
            isSearchActive={isSearchActive}
            searchWord={searchValue}
          />
        </Objective>
        <MotionsWrapper>{renderMotions(objective)}</MotionsWrapper>
      </div>
    ))

  const quantityOfMotions = () =>
    motionsList?.reduce(
      (result: number, objective: IObjective) => result + objective.motions.length,
      0
    )

  return (
    <Container flexSize={1} column>
      <ContainerWithMenu menu={UserMenu}>
        <Title>Choose motion you want to run</Title>
        <Background alignItems="center" justifyContent="space-between">
          <MotionsFilter handleChangeObjective={handleChangeObjective} />
          <CounterContainer>
            <Counter>{quantityOfMotions()}</Counter>&nbsp;Motions
          </CounterContainer>
          <MotionsSearch
            searchValue={searchValue}
            isSearchActive={isSearchActive}
            setSearchValue={setSearchValue}
            setSearchActive={setSearchActive}
            handleSearchMotions={handleSearchMotions}
          />
        </Background>
        <MotionsListWrapper>
          {isLoading && <Loader />}
          {motionsList && motionsList.length > 0 ? (
            renderObjectives()
          ) : (
            <EmptyStateWrappper>
              <EmptyState
                title="Where, oh where is that motion?"
                text="Sorry, no coincidence was found. Let’s tinker with your search and try again…"
                thumbnail={<PeopleEmptyImage width="138px" height="126px" />}
              />
            </EmptyStateWrappper>
          )}
        </MotionsListWrapper>
      </ContainerWithMenu>
      <FooterButtons
        handleBack={handleRedirect()}
        handleProceed={handleRedirect(selectedMotion)}
        isProcceedDisabled={!selectedMotion}
      />
    </Container>
  )
}

export default MotionsList
