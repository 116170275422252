import React, { useEffect } from 'react'
import { DurationAndIntensityPage } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { cadenceActions, durationAndIntensityActions, stepsActions } from 'actions'
import { durationAndIntensitySelector } from 'selectors'
import { ICadence } from 'types'

interface IProps {
  cadence: ICadence
}

const DurationAndIntensity = ({ cadence }: IProps) => {
  const dispatch = useDispatch()
  const selectedMotion = useSelector(durationAndIntensitySelector)

  useEffect(() => {
    dispatch(durationAndIntensityActions.request({ data: { id: Number(cadence.motion.id) } }))
  }, [cadence.motion.id])

  const handleRedirect = (
    numberOfDays?: number,
    intensity?: Record<string, number>,
    isMessagingSelected?: boolean
  ) => () => {
    if (numberOfDays && intensity) {
      dispatch(
        cadenceActions.update({
          data: {
            id: cadence.id,
            cadenceName: cadence.name,
            duration: numberOfDays,
            emails: intensity.emails,
            phones: intensity.phones,
            socials: intensity.socials,
          },
          successFunc: () => {
            dispatch(
              stepsActions.request({
                data: {
                  id: cadence.id,
                  action: 'proceed',
                  skip_questions: !isMessagingSelected,
                },
              })
            )
          },
        })
      )
    } else {
      dispatch(
        stepsActions.request({
          data: { id: cadence.id, action: 'back' },
        })
      )
    }
  }

  return (
    <DurationAndIntensityPage selectedMotion={selectedMotion} handleRedirect={handleRedirect} />
  )
}

export default DurationAndIntensity
