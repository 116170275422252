import React, { useEffect, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { getColor, getFontWeight } from '@rhythm/theme'
import { FlexContainer } from 'static'
import { IButtonApi, ICreateEmailStep, IDynamicTag } from 'types'
import { TinyMce, TinyMcePreview } from '@rhythm/tinymce'
import { cadenceStepActions, dynamicTagsActions } from 'actions'
import { useDispatch } from 'react-redux'

import StepName from './StepName'
import {
  configureDynamicFields,
  addCadenceEndineTagsIcon,
} from '../services/configureDynamicFields'

const EDIT_AREA_ID = 'tinymce-edit-area'

const PLUGINS = 'link image lists paste code'

const TEAM_FONTS = {
  font: 'Arial',
  fontSize: '12pt',
}

const TOOLBAR_BUTTONS =
  'fontselect | fontsizeselect | forecolor | backcolor | bold italic underline | removeformat | numlist bullist | link | emaildynamicfields | cedynamictags'

const EmailEditorWrapper = styled(FlexContainer)`
  flex-direction: column;
  width: 566px;
`

const SubjectInput = styled.input`
  margin-left: 20px;
  border: none;
  width: 470px;
  color: ${getColor('greyDarkest')};
  font-size: 14px;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${getColor('grey')};
  }
`

const BoldTitle = styled.span`
  font-weight: ${getFontWeight('semibold')};
  color: ${getColor('greyDarkest')};
  font-size: 14px;
`

const SubjectContainer = styled.div`
  border-top: 1px solid ${getColor('greyLightest')};
  border-bottom: 1px solid ${getColor('greyLightest')};
  padding: 4px 0 4px 17px;
`

const Wrapper = styled.div`
  height: 401px;
`

const PreviewContainer = styled.div`
  width: 396px;
  border-left: 1px solid ${getColor('greyLightest')};
  padding: 8px 0 8px 8px;
`

const PreviewTitle = styled.div`
  color: ${getColor('greyDarkest')};
  font-size: 16px;
  line-height: 32px;
  margin-left: 8px;
`

const TinyMceStyles = styled(FlexContainer)`
  .tox .tox-edit-area {
    border-top: none;
  }

  .tox-toolbar {
    border-bottom: 1px solid ${getColor('greyLightest')};
  }

  #${EDIT_AREA_ID} .tox .tox-edit-area {
    margin: 10px 0 8px 10px;
  }
`

const TinyMceGlobalStyles = createGlobalStyle`
  .tox .tox-dialog-wrap .tox-dialog[role='dialog'] {
    height: auto;
  }

  .tox .tox-split-button .tox-tbtn[role='presentation'] {
    margin: 4px 1px;
  }
`

const CreateEmailStep = ({
  name,
  type,
  emailSubject,
  emailBody,
  cadenceId,
  handleChangeEmailName,
  handleChangeEmailSubject,
  handleChangeEmailBody,
}: ICreateEmailStep) => {
  const [previewContent, setPreviewContent] = useState<string>('')
  const [isAnswersTagsDisabled, setAnswersTagsDisabled] = useState<boolean>(false)
  const [tagsButton, setTagsButton] = useState<IButtonApi>()
  const dispatch = useDispatch()

  const successFunc = (preview: string) => setPreviewContent(preview)

  const updatePreviewContent = (body: string) => {
    dispatch(
      cadenceStepActions.get({
        data: { cadenceId, body },
        successFunc,
      })
    )
  }

  useEffect(() => {
    if (emailBody) {
      updatePreviewContent(emailBody)
    }
    dispatch(
      dynamicTagsActions.request({
        data: { company: 'cadence_engine', cadenceId },
        successFunc: (tags: IDynamicTag[]) => {
          if (tags.length === 0) {
            setAnswersTagsDisabled(true)
          }
        },
      })
    )
  }, [])

  useEffect(() => {
    if (isAnswersTagsDisabled && tagsButton) {
      tagsButton.setDisabled(true)
    }
  }, [isAnswersTagsDisabled])

  const onSetupCallbacks = [
    addCadenceEndineTagsIcon,
    configureDynamicFields(
      dispatch,
      'emaildynamicfields',
      'bracket',
      'Salesloft Dynamic Tag',
      'salesloft'
    ),
    configureDynamicFields(
      dispatch,
      'cedynamictags',
      'dynamic-tags-icon',
      'Cadence Engine Dynamic Tag',
      'cadence_engine',
      setTagsButton,
      cadenceId
    ),
  ]

  const setBodyIfChanged = (text: string) => {
    if (text !== emailBody) {
      handleChangeEmailBody(text)
      updatePreviewContent(text)
    }
  }

  return (
    <TinyMceStyles>
      <EmailEditorWrapper>
        <StepName
          name={name}
          type={type}
          width="470px"
          handleChangeNameInput={handleChangeEmailName}
        />
        <SubjectContainer>
          <BoldTitle>Subject</BoldTitle>
          <SubjectInput
            placeholder="Subject"
            value={emailSubject}
            onChange={handleChangeEmailSubject}
          />
        </SubjectContainer>
        <Wrapper id={EDIT_AREA_ID}>
          <TinyMce
            plugins={PLUGINS}
            initialContent={emailBody}
            onChange={setBodyIfChanged}
            toolbarButtons={TOOLBAR_BUTTONS}
            onSetupCallbacks={onSetupCallbacks}
            teamDefaults={TEAM_FONTS}
            pasteAsText={false}
            imagesUploadHandler={() => {}}
          />
        </Wrapper>
      </EmailEditorWrapper>
      <PreviewContainer>
        <PreviewTitle>Preview</PreviewTitle>
        <TinyMcePreview content={previewContent} editorHeight="418px" />
      </PreviewContainer>
      <TinyMceGlobalStyles />
    </TinyMceStyles>
  )
}

export default CreateEmailStep
