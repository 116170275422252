import React from 'react'
import {
  Field,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
  submit,
  FormSubmitHandler,
} from 'redux-form'
import { emailRegex, flex, flexCol, FlexContainer } from 'static'
import styled from 'styled-components'
import { Button } from '@rhythm/buttons'
import { ISigninForm } from 'types'
import { getColor } from '@rhythm/theme'
import { AppLink, FormInput } from 'components'

interface IProps {
  fetching?: boolean
  nextStep: () => void
  prevStep: () => void
  isPasswordStep: boolean
}

const Form = styled.form`
  ${flex};
  ${flexCol}
`

const ButtonContainer = styled(FlexContainer)`
  margin-top: 1rem;
  .prev {
    color: ${getColor('green')};
    border-color: ${getColor('green')};
    &:hover {
      color: ${getColor('white')};
      background-color: ${getColor('greenDark')};
      border-color: ${getColor('greenDark')};
    }
  }
  button {
    margin-left: 0.5rem;
    &:first-of-type {
      margin-right: 0.5rem;
      margin-left: 0;
    }
  }
`

const SubmitButton = styled(Button)`
  padding: 1.2rem 4rem;
  width: 100%;
  font-size: 1rem;
  margin-top: 0.5rem;
`

const PasswordContainer = styled(FlexContainer)`
  position: relative;
  a {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`

const validate = (
  isPasswordStep: boolean,
  nextStep: () => void
): FormSubmitHandler<ISigninForm, IProps, string> => ({ email, password }, dispatch) => {
  const error: ISigninForm = {}
  if (!email) {
    error.email = 'This field is required'
  } else if (!emailRegex.test(email)) {
    error.email = 'Invalid email address'
  }
  if (!password && isPasswordStep) {
    error.password = 'This field is required'
  }
  if (Object.keys(error).length > 0) {
    throw new SubmissionError({
      ...error,
      _error: 'Login failed!',
    })
  } else if (!isPasswordStep) {
    nextStep()
  } else {
    dispatch(submit('signin'))
  }
}

const SigninForm = ({
  handleSubmit,
  submitting,
  fetching = false,
  nextStep,
  prevStep,
  isPasswordStep,
}: InjectedFormProps<ISigninForm, IProps> & IProps) => (
  <Form onSubmit={handleSubmit(validate(isPasswordStep, nextStep))}>
    <FlexContainer column>
      <Field
        name="email"
        component={FormInput}
        placeholder="Email"
        type="text"
        maxLength="120"
        disabled={isPasswordStep}
        noSetCustomValidity
      />
      {isPasswordStep && (
        <PasswordContainer>
          <Field
            name="password"
            component={FormInput}
            placeholder="Password"
            type="password"
            maxLength="120"
            noSetCustomValidity
          />
          <AppLink to="/forgotPassword">Forgot Password?</AppLink>
        </PasswordContainer>
      )}
      {isPasswordStep ? (
        <ButtonContainer>
          <SubmitButton
            onClick={prevStep}
            type="button"
            loading={submitting || fetching}
            variant="white"
            className="prev"
          >
            Back
          </SubmitButton>
          <SubmitButton type="submit" loading={submitting || fetching}>
            Sign In
          </SubmitButton>
        </ButtonContainer>
      ) : (
        <SubmitButton type="submit" loading={submitting || fetching}>
          Next
        </SubmitButton>
      )}
    </FlexContainer>
  </Form>
)

export default reduxForm<ISigninForm, IProps>({
  form: 'signin',
  destroyOnUnmount: false,
})(SigninForm)
