import { Day } from 'types'
import { cadenceStepsActions } from 'actions'

export const getIndexByDay = (days: Day[], dayNumber: string) =>
  days.findIndex((e: Day) => e.publish_at === Number(dayNumber))

const reorder = (
  sourceDay: string,
  destinationDay: string,
  list: Day[],
  startIndex: number,
  endIndex: number,
  cadenceId: number | string,
  successFunc: () => void
) => {
  const sortedList = [...list]
  const rDay = getIndexByDay(sortedList, sourceDay)
  const dDay = getIndexByDay(sortedList, destinationDay)
  const result = sortedList[rDay]
  const destination = sortedList[dDay]
  const [removed] = result.steps.splice(startIndex, 1)

  if (rDay === dDay) {
    result.steps.splice(endIndex, 0, removed)

    sortedList[rDay] = result
  } else {
    destination.steps.splice(endIndex, 0, removed)

    sortedList[rDay] = result
    sortedList[dDay] = destination
  }
  let newOrder
  const updatedDay = sortedList.find((e: Day) => e.publish_at === Number(destinationDay))
  const updatedStep = updatedDay?.steps[endIndex]

  if (destinationDay === sourceDay) {
    newOrder = updatedDay?.steps[startIndex > endIndex ? endIndex + 1 : endIndex - 1].order
  } else if (updatedDay?.steps[endIndex + 1]) {
    newOrder =
      destinationDay > sourceDay
        ? updatedDay.steps[endIndex + 1].order - 1
        : updatedDay.steps[endIndex + 1].order
  } else if (updatedDay?.steps[endIndex - 1]) {
    newOrder =
      destinationDay < sourceDay
        ? updatedDay.steps[endIndex - 1].order + 1
        : updatedDay.steps[endIndex - 1].order
  }

  return cadenceStepsActions.update({
    data: {
      id: cadenceId,
      stepId: updatedStep?.id,
      day: Number(destinationDay),
      order: newOrder,
    },
    successFunc,
  })
}

export default reorder
