import { GLOBAL_MODAL } from 'actions'
import { IAppModalState } from 'types'

const initialState: IAppModalState = {
  modal: null,
}

export default function appModalReducer(
  state = initialState,
  { type, payload }: { type: string; payload: Record<string, unknown> }
) {
  switch (type) {
    case GLOBAL_MODAL.UPDATE: {
      return {
        ...state,
        ...payload,
      }
    }
    case GLOBAL_MODAL.DELETE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
