import { messageActions } from 'actions'
import React, { ReactText, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cadenceErrorSelector, cadenceFetchingSelector } from 'selectors/cadence'
import { FlexContainer } from 'static'
import { MinimalAlert } from '@rhythm/notifications'
import { Button, ButtonGroup } from '@rhythm/buttons'
import styled from 'styled-components'
import { TextField } from '@rhythm/inputs'
import { getFontWeight, getColor } from '@rhythm/theme'
import { TContainer } from 'types'
import { cadencesFetchingSelector } from 'selectors'

interface IProps {
  body: TContainer
  footer: TContainer
  onClose: () => void
  title: string
  onApply: ({
    data,
    successFunc,
  }: {
    data: {
      cadenceName: string
      allowDuplicate: boolean
      id: ReactText
    }
    successFunc: () => void
  }) => void
  name?: string
  applyMessage?: string
  id: ReactText
}

const AlertContainer = styled.div`
  margin-bottom: 1.1rem;
`

const Label = styled.div`
  padding-right: 1rem;
  font-size: 0.9rem;
  font-weight: ${getFontWeight('semibold')};
  color: ${getColor('greyDark')};
`

const CadenceNameModal = ({
  body: Body,
  footer: Footer,
  onClose,
  title,
  onApply,
  name = '',
  applyMessage = 'Success',
  id,
}: IProps) => {
  const dispatch = useDispatch()
  const error = useSelector(cadenceErrorSelector)
  const featchingCadences = useSelector(cadencesFetchingSelector)
  const featchingCadence = useSelector(cadenceFetchingSelector)
  const [cadenceName, setCadenceName] = useState(name)
  const [isTouchInput, setIsTouchInput] = useState(false)
  const changeCadenceName = ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    if (!isTouchInput) {
      setIsTouchInput(true)
    }
    setCadenceName(value)
  }
  const allowDuplicateError = !!error && error.indexOf('unique') !== -1
  const onSend = () => {
    setIsTouchInput(false)
    onApply({
      data: { cadenceName, allowDuplicate: allowDuplicateError, id },
      successFunc: () => {
        dispatch(
          messageActions.update({
            message: {
              body: applyMessage,
              type: 'confirm',
            },
          })
        )
        onClose()
      },
    })
  }
  return (
    <>
      <Body>
        <FlexContainer justifyContent="center" column>
          {allowDuplicateError && !isTouchInput ? (
            <AlertContainer>
              <MinimalAlert
                title={`This name is already taken. Click ${title} to proceed`}
                variant="warning"
              />
            </AlertContainer>
          ) : null}
          <FlexContainer alignItems="center" justifyContent="space-between">
            <Label>Cadence Name</Label>
            <TextField
              placeholder="Type Name"
              value={cadenceName}
              onChange={changeCadenceName}
              minLength={3}
              maxLength={100}
              width="23rem"
            />
          </FlexContainer>
        </FlexContainer>
      </Body>
      <Footer>
        <ButtonGroup>
          <Button
            variant="neutral"
            loading={featchingCadence || featchingCadences}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            onClick={onSend}
            loading={featchingCadence || featchingCadences}
            disabled={cadenceName.length < 3 || name === cadenceName}
          >
            {title}
          </Button>
        </ButtonGroup>
      </Footer>
    </>
  )
}

export default CadenceNameModal
