import React, { useEffect, useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { cadenceActions, motionsActions, objectiveActions, stepsActions } from 'actions'
import { motionsListSelector } from 'selectors'
import { queryToJson } from 'utils'
import { MotionsList } from 'components'
import { ICadence } from 'types'

interface IProps extends RouteComponentProps {
  cadence: ICadence
}

const Motions = ({ location: { pathname, search }, history, cadence }: IProps) => {
  const dispatch = useDispatch()
  const motionsList = useSelector(motionsListSelector)
  const { name, searchMotions } = useMemo(() => queryToJson(search), [search])

  useEffect(() => {
    if (name || searchMotions) {
      dispatch(objectiveActions.request({ data: { name, searchMotions } }))
      return
    }
    dispatch(motionsActions.request())
  }, [])

  const handleUpdateFilter = (isFilter: boolean) => {
    const data = isFilter ? { searchMotions } : { name }
    if (Object.values(data).shift()) {
      const query = isFilter
        ? new URLSearchParams({ searchMotions })
        : new URLSearchParams({ name })
      dispatch(objectiveActions.request({ data }))
      history.replace(`${pathname}?${query}`)
      return
    }
    dispatch(motionsActions.request())
    history.replace(pathname)
  }

  const handleChangeObjective = (objectName?: string) => () => {
    if (!objectName) {
      handleUpdateFilter(true)
      return
    }

    dispatch(objectiveActions.request({ data: { name: objectName, searchMotions } }))
    const query = new URLSearchParams({
      name: objectName,
    })

    if (searchMotions) {
      query.append('searchMotions', searchMotions)
    }
    history.replace(`${pathname}?${query}`)
  }

  const handleSearchMotions = (searchValue?: string) => {
    if (!searchValue) {
      handleUpdateFilter(false)
      return
    }

    dispatch(objectiveActions.request({ data: { searchMotions: searchValue, name } }))
    const query = new URLSearchParams({
      searchMotions: searchValue,
    })
    if (name) {
      query.append('name', name)
    }
    history.replace(`${pathname}?${query}`)
  }

  const handleRedirect = (selectedMotionId?: number) => () => {
    if (selectedMotionId) {
      dispatch(
        cadenceActions.update({
          data: {
            id: cadence.id,
            cadenceName: cadence.name,
            motion_id: selectedMotionId,
          },
          successFunc: () => {
            dispatch(stepsActions.request({ data: { id: cadence.id, action: 'proceed' } }))
          },
        })
      )
    } else {
      history.push('/cadenceList')
    }
  }

  return (
    <MotionsList
      motionInCadence={Number(cadence.motion?.id)}
      motionsList={motionsList}
      handleChangeObjective={handleChangeObjective}
      handleSearchMotions={handleSearchMotions}
      defaultSearchValue={searchMotions}
      handleRedirect={handleRedirect}
    />
  )
}

export default Motions
