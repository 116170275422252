import { Error404Image } from '@rhythm/svgs'
import React from 'react'
import { Error501, FlexContainer, ErrorUniversal, AppLogoDark } from 'static'
import styled from 'styled-components'
import { getColor, getFontWeight } from '@rhythm/theme'

export interface IProps {
  errorType?: '404' | '501' | 'universal'
}

const svgSizes = {
  height: '7.5rem',
  width: '8.625rem',
}

const Img = styled.img`
  ${svgSizes};
`

const Logo = styled.img`
  width: 11.875rem;
`

const Block = styled(FlexContainer)`
  background-color: ${getColor('white')};
  width: 35.5rem;
  height: 27rem;
  margin-bottom: 2.5rem;
  margin-top: 6.25rem;
`

const Container = styled(FlexContainer)`
  background-color: ${getColor('greyLightest')};
  color: ${getColor('greyDark')};
`

const Title = styled.h1`
  font-size: 1.5rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  font-weight: ${getFontWeight('light')};
`

const Text = styled.span`
  font-size: 0.9375rem;
  text-align: center;
`

const ErrorPage = ({ errorType = 'universal' }: IProps) => {
  const errorRender = () => {
    let image = <Img src={ErrorUniversal} />
    let text: string | JSX.Element = 'Please go back or reload the page in 30 seconds.'
    switch (errorType) {
      case '404':
        image = <Error404Image {...svgSizes} />
        text = (
          <>
            You have made your way to a page that does not
            <br />
            exist, check your url and try again.
          </>
        )
        break
      case '501':
        image = <Img src={Error501} />
        text = (
          <>
            The server encountered a temporary error
            <br />
            and could not complete your request.
          </>
        )
        break
      default:
        break
    }
    return (
      <>
        {image}
        <Title>Oops! Looks like you’re off course</Title>
        <Text data-testid="txtMessageErrorPage">{text}</Text>
      </>
    )
  }
  return (
    <Container alignItems="center" column flexSize={1} data-testid="divContainerErrorPage">
      <Block column alignItems="center" justifyContent="center">
        {errorRender()}
      </Block>
      <Logo src={AppLogoDark} />
    </Container>
  )
}

export default ErrorPage
