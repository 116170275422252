import { messageActions, passwordRecoveryActions, signoutActions } from 'actions'
import { BlockContainer, PasswordRecoveryForm } from 'components'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { FlexContainer } from 'static'
import styled, { css } from 'styled-components'
import { parseErrorsToFormErrors, queryToJson, localStorageAuthToken } from 'utils'
import { getColor } from '@rhythm/theme'
import { IChangePasswordForm } from 'types'
import { change, stopAsyncValidation } from 'redux-form'
import { Helmet } from 'react-helmet'

const Container = styled(FlexContainer)`
  margin-top: 12rem;
`

const BlockContainerStyles = css`
  padding: 2.5rem;
  min-height: 31rem;
`

const Title = styled.span`
  font-size: 1.25rem;
  margin-bottom: 2.5rem;
  text-align: center;
  color: ${getColor('greyDark')};
`

const PasswordRecovery = ({ location, history }: RouteComponentProps) => {
  const dispatch = useDispatch()
  const [resetPasswordToken, setResetPasswordToken] = useState<string | number | null>(null)
  const recovery = (value: IChangePasswordForm) => {
    if (resetPasswordToken) {
      dispatch(
        passwordRecoveryActions.request({
          value: {
            reset_password_token: resetPasswordToken,
            ...value,
          },
          successFunc: () => {
            if (localStorageAuthToken.get()) {
              dispatch(
                signoutActions.request({
                  successFunc: () => {
                    history.push('/signin')
                  },
                })
              )
              return
            }
            history.push('/signin')
          },
          errorFunc: (errors) => {
            if (errors.reset_password_token) {
              dispatch(
                messageActions.update({
                  message: {
                    type: 'danger',
                    body: 'Wrong Token',
                  },
                })
              )
              history.push('/signin')
              return
            }
            if (errors.password_confirmation || errors.password) {
              dispatch(change('passwordRecovery', 'password_confirmation', ''))
              if (errors.password) {
                dispatch(change('passwordRecovery', 'password', ''))
              }
            }
            dispatch(stopAsyncValidation('passwordRecovery', parseErrorsToFormErrors(errors)))
          },
        })
      )
    } else {
      history.push('/signin')
    }
  }
  useEffect(() => {
    const { reset_password_token: token } = queryToJson(location.search)
    if (token && typeof token !== 'boolean') {
      setResetPasswordToken(token)
    } else {
      history.push('/signin')
    }
  }, [])
  return (
    <Container justifyContent="center">
      <Helmet>
        <title>Password Recovery</title>
      </Helmet>
      <BlockContainer styles={BlockContainerStyles}>
        <Title>Password Recovery</Title>
        <FlexContainer column justifyContent="space-between" flexSize={1}>
          <PasswordRecoveryForm onSubmit={recovery} />
        </FlexContainer>
      </BlockContainer>
    </Container>
  )
}

export default PasswordRecovery
