import { ContainerWithMenu, FeedbackForm } from 'components'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userFetchingSelector } from 'selectors'
import { FlexContainer } from 'static'
import { getColor, getFontWeight } from '@rhythm/theme'
import styled from 'styled-components'
import { IFeedbackForm } from 'types'
import { feedbackActions, messageActions } from 'actions'

const Header = styled(FlexContainer)`
  padding: 1.375rem 2.25rem 1rem 2.25rem;
  border: 0.1rem solid ${getColor('greyLightest')};
  margin-bottom: 1.375rem;
  font-size: 1.25rem;
  color: ${getColor('greyDarkest')};
  font-weight: ${getFontWeight('medium')};
`

const Container = styled(FlexContainer)`
  padding: 0 2.25rem;
`
const Text = styled.span`
  color: ${getColor('greyDark')};
  margin-bottom: 1.25rem;
`

const Feedback = () => {
  const dispatch = useDispatch()
  const userFetching = useSelector(userFetchingSelector)
  const sendFeedback = (data: IFeedbackForm) => {
    dispatch(
      feedbackActions.request({
        data,
        successFunc: () => {
          dispatch(
            messageActions.update({
              message: {
                type: 'confirm',
                body: 'Your Feedback successfully sent!',
              },
            })
          )
        },
      })
    )
  }

  return (
    <ContainerWithMenu isLogoLink={false}>
      <Header>How can we help?</Header>
      <Container column flexSize={1}>
        <Text>
          We’re always working to improve the Cadence Engine experience,
          <br />
          so we’d love to hear what’s working and how we can do better.
        </Text>
        <FeedbackForm onSubmit={sendFeedback} fetching={userFetching} />
      </Container>
    </ContainerWithMenu>
  )
}

export default Feedback
