import React from 'react'
import styled from 'styled-components'
import { IMotionsSearch } from 'types'
import { SearchInput } from '@rhythm/inputs'

const SearchContainer = styled.div`
  padding: 8px 0;
`

const MotionsSearch = ({
  searchValue,
  isSearchActive,
  setSearchValue,
  setSearchActive,
  handleSearchMotions,
}: IMotionsSearch) => {
  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (!value) {
      handleSearchMotions()
    }

    if (value.length <= 100) {
      setSearchActive(false)
      setSearchValue(value)
    }
  }

  const onClickClear = () => {
    setSearchValue('')
    handleSearchMotions()
    setSearchActive(false)
  }

  const onClickSearch = () => {
    if (searchValue.length >= 1) {
      handleSearchMotions(searchValue)
      setSearchActive(true)
    }
  }

  const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') onClickSearch()
  }

  return (
    <SearchContainer>
      <SearchInput
        value={searchValue}
        placeholder="Search by Key Words"
        onChange={onChangeSearch}
        onClear={onClickClear}
        onClickSearch={onClickSearch}
        minActiveSearchLength={1}
        showClearButton={isSearchActive}
        onKeyPress={handleEnter}
      />
    </SearchContainer>
  )
}

export default MotionsSearch
