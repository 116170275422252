import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getColor } from '@rhythm/theme'
import { Modal, ModalBody, ModalFooter } from '@rhythm/modals'
import { ButtonGroup, Button } from '@rhythm/buttons'
import { FlexContainer } from 'static'
import { TextInput } from '@rhythm/inputs'
import { IAddStepModal } from 'types'
import { useDispatch } from 'react-redux'
import { cadenceStepActions } from 'actions'

import StepType from './StepType'
import CreateStepModal from './CreateStepModal'

const STEP_TYPES = ['Email', 'Call', 'Social']

const TypesWrapper = styled(FlexContainer)`
  padding-top: 2px;
  gap: 17px;
`

const DayInput = styled(FlexContainer)<{ isEditing?: boolean }>`
  color: ${getColor('greyDarkest')};
  font-size: 14px;
  line-height: 32px;
  gap: 17px;
  padding: ${({ isEditing }) => (isEditing ? '16px 0' : '31px 0 9px 31px')};
`

const Constraint = styled.div<{ isError: boolean }>`
  color: ${({ isError }) => getColor(isError ? 'red' : 'greyDark')};
  font-size: 12px;
  line-height: 16px;
`

const AddStepModal = ({
  isOpen,
  title,
  selectedDay,
  cadenceId,
  isEditStep,
  stepId,
  updateSteps,
  handleCloseModal,
}: IAddStepModal) => {
  const [stepType, setStepType] = useState('')
  const [day, setDay] = useState<string | number>(selectedDay)
  const [openedModal, setOpenedModal] = useState('')
  const [isEditing, setEditing] = useState(isEditStep)
  const dispatch = useDispatch()

  useEffect(() => {
    setDay(selectedDay)
  }, [selectedDay])

  useEffect(() => {
    if (isOpen) {
      setDay(selectedDay)
      setStepType('')
      setEditing(isEditStep)
    }
  }, [isOpen])

  const handleClickOnType = (type: string) => {
    setStepType(type)
  }

  const handleChangeDayInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(e.target.value)
    const changedValue = e.target.value.length === 0 ? e.target.value : inputValue
    if (inputValue >= 0 && e.target.value.length <= 3) {
      setDay(changedValue)
    }
  }

  const handleEditStepDay = () => {
    if (Number(day) !== selectedDay) {
      dispatch(
        cadenceStepActions.update({
          data: {
            publish_at: Number(day),
            id: cadenceId,
            stepId,
          },
          successFunc: updateSteps,
        })
      )
      return
    }
    handleCloseModal()
  }

  const isDayValid = Number(day) <= 366 && Number(day) !== 0

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={handleCloseModal}
        title={title}
        minWidth={640}
        zIndex={2050}
      >
        {() => (
          <>
            <ModalBody>
              {!isEditing && (
                <TypesWrapper justifyContent="center">
                  {STEP_TYPES.map((type: string) => (
                    <StepType
                      key={type}
                      selectedType={stepType}
                      type={type}
                      handleClickOnType={handleClickOnType}
                    />
                  ))}
                </TypesWrapper>
              )}
              <DayInput alignItems="center" isEditing={isEditing}>
                Occurs on day
                <TextInput
                  width="55px"
                  value={day}
                  onChange={handleChangeDayInput}
                  variant={isDayValid ? 'primary' : 'danger'}
                />
                <Constraint isError={!isDayValid}>
                  *Day number has to be between 1 and 366
                </Constraint>
              </DayInput>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button variant="neutral" onClick={handleCloseModal}>
                  Cancel
                </Button>
                {!isEditing ? (
                  <Button
                    disabled={!isDayValid || stepType.length === 0}
                    onClick={() => setOpenedModal(stepType)}
                  >
                    Next
                  </Button>
                ) : (
                  <Button disabled={!isDayValid} onClick={handleEditStepDay}>
                    Save
                  </Button>
                )}
              </ButtonGroup>
            </ModalFooter>
          </>
        )}
      </Modal>
      <CreateStepModal
        isOpen={!!openedModal}
        handleCloseModal={() => {
          setOpenedModal('')
        }}
        handleCloseAddStepModal={handleCloseModal}
        type={openedModal}
        selectedDay={day}
        cadenceId={cadenceId}
      />
    </>
  )
}

export default AddStepModal
