import { IDynamicTag } from 'types'
import { createAction } from 'utils'

import { DYNAMIC_TAGS } from './types'

const dynamicTagsActions = {
  request: ({
    data: { company, cadenceId },
    successFunc,
    errorFunc,
  }: {
    data: {
      company: string
      cadenceId?: number
    }
    successFunc?: (tags: IDynamicTag[]) => void
    errorFunc?: (error: string) => void
  }) =>
    createAction(
      DYNAMIC_TAGS.REQUEST,
      {
        success: false,
        error: null,
      },
      {
        company,
        cadenceId,
      },
      successFunc,
      errorFunc
    ),
}

export {
  // eslint-disable-next-line import/prefer-default-export
  dynamicTagsActions,
}
