import React from 'react'
import { PersonaPage } from 'components'
import { useDispatch } from 'react-redux'
import { stepsActions, cadencePersonaActions } from 'actions'
import { ICadence, IPersona } from 'types'

import {
  industryOptions,
  jobRoleOptions,
  companyHeadcountOptions,
  geographyOptions,
  customerTypeOptions,
} from './personaSelectsData'

interface IProps {
  cadence: ICadence
}

const Persona = ({ cadence }: IProps) => {
  const dispatch = useDispatch()
  const handleRedirect = (isProceed?: boolean, personInformation?: IPersona) => () => {
    if (isProceed) {
      dispatch(
        cadencePersonaActions.request({
          data: {
            id: cadence.id,
            ...personInformation,
          },
          successFunc: () => {
            dispatch(stepsActions.request({ data: { id: cadence.id, action: 'proceed' } }))
          },
        })
      )
    } else {
      dispatch(stepsActions.request({ data: { id: cadence.id, action: 'back' } }))
    }
  }

  return (
    <PersonaPage
      cadence={cadence}
      industryOptions={industryOptions}
      jobRoleOptions={jobRoleOptions}
      companyHeadcountOptions={companyHeadcountOptions}
      geographyOptions={geographyOptions}
      customerTypeOptions={customerTypeOptions}
      handleRedirect={handleRedirect}
    />
  )
}

export default Persona
