import React, { useState } from 'react'
import styled from 'styled-components'
import { ICadence, IOption, IPersona, ISelectOptions } from 'types'
import { getColor } from '@rhythm/theme'
import { H5, Body } from '@rhythm/typography'
import { ContainerWithMenu } from 'components'
import { UserMenu } from 'containers'

import SelectInput from './SelectInput'

import FooterButtons from '../Common/FooterButtons/FooterButtons'

const DESCRIPTION =
  'Selecting persona attributes below will help us provide better recommendations for cadence messaging and cadence structure.'

const Title = styled(H5)`
  padding: 1.4rem 0rem 1rem 2.25rem;
  color: ${getColor('greyDarkest')};
  line-height: 1.15rem;
  margin: 0;
  box-shadow: inset 0 -1px 0 0 ${getColor('greyLightest')};
`

const Description = styled(Body)`
  padding-top: 18px;
  padding-left: 36px;
  max-width: 476px;
  line-height: 16px;
`

const SelectWrapper = styled.div`
  box-sizing: border-box;
  margin-top: -2px;
  padding-left: 36px;
  max-width: 385px;
`

export interface IProps {
  cadence: ICadence
  industryOptions: ISelectOptions[]
  jobRoleOptions: ISelectOptions[]
  companyHeadcountOptions: IOption[]
  geographyOptions: IOption[]
  customerTypeOptions: IOption[]
  handleRedirect(isProceed?: boolean, personInformation?: IPersona): () => void
}

const PersonaPage = ({
  cadence,
  industryOptions,
  jobRoleOptions,
  companyHeadcountOptions,
  geographyOptions,
  customerTypeOptions,
  handleRedirect,
}: IProps) => {
  const {
    industry,
    job_role: jobRole,
    company_headcount: companyHeadcount,
    geography,
    customer_type: customerType,
  } = cadence.persona || {}

  const [personInformation, setPersonInformation] = useState({
    industry: industry || 'All/Any',
    job_role: jobRole || 'All/Any',
    company_headcount: companyHeadcount || 'All/Any',
    geography: geography || 'All/Any',
    customer_type: customerType || 'All/Any',
  })

  const updatePersonInformation = (type: string) => (value: string) => {
    setPersonInformation((prevState) => ({
      ...prevState,
      [type]: value,
    }))
  }

  return (
    <>
      <ContainerWithMenu menu={UserMenu}>
        <Title>Choose Persona you targeting with this cadence</Title>
        <Description>{DESCRIPTION}</Description>
        <SelectWrapper>
          <SelectInput
            label="Industry"
            selectOptions={industryOptions}
            updatePersonInformation={updatePersonInformation('industry')}
            value={personInformation.industry}
          />
          <SelectInput
            label="Job Role"
            selectOptions={jobRoleOptions}
            updatePersonInformation={updatePersonInformation('job_role')}
            value={personInformation.job_role}
          />
          <SelectInput
            label="Company Headcount"
            selectOptions={companyHeadcountOptions}
            updatePersonInformation={updatePersonInformation('company_headcount')}
            value={personInformation.company_headcount}
          />
          <SelectInput
            label="Geography"
            selectOptions={geographyOptions}
            updatePersonInformation={updatePersonInformation('geography')}
            value={personInformation.geography}
          />
          <SelectInput
            label="Customer Type"
            selectOptions={customerTypeOptions}
            updatePersonInformation={updatePersonInformation('customer_type')}
            value={personInformation.customer_type}
          />
        </SelectWrapper>
      </ContainerWithMenu>
      <FooterButtons
        handleBack={handleRedirect()}
        handleProceed={handleRedirect(true, personInformation)}
        isProcceedDisabled={false}
      />
    </>
  )
}

export default PersonaPage
