import React from 'react'
import { getColor, getFontWeight } from '@rhythm/theme'
import { Link, LinkProps } from 'react-router-dom'
import styled, { css } from 'styled-components'

interface IProps extends LinkProps, React.RefAttributes<HTMLAnchorElement> {
  useHref?: boolean
}

const linkStyles = css`
  text-decoration: none;
  color: ${getColor('greyDarkest')};
  font-weight: ${getFontWeight('semibold')};
  font-size: 0.875rem;
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    outline: none;
    color: ${getColor('greyDarkest')};
    text-decoration: underline;
  }
`

const StyledLink = styled(Link)`
  ${linkStyles}
`

const StyledA = styled.a`
  ${linkStyles}
`

const AppLink = ({ children, useHref, to, ...props }: IProps) =>
  useHref ? (
    <StyledA href={to} {...props}>
      {children}
    </StyledA>
  ) : (
    <StyledLink to={to} {...props}>
      {children}
    </StyledLink>
  )

export default AppLink
