import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getColor, getFontWeight } from '@rhythm/theme'
import { Button } from '@rhythm/buttons'
import { Asterisk, FlexContainer } from 'static'
import { H5 } from '@rhythm/typography'
import { useHistory } from 'react-router-dom'
import { ContainerWithMenu, Loader, FooterButtons } from 'components'
import { UserMenu } from 'containers'
import { TextField } from '@rhythm/inputs'
import { messageActions, forgotPasswordActions, currentUserActions } from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import { IUser } from 'types'
import { userCurrentUserSelector, userErrorSelector, userFetchingSelector } from 'selectors'
import { InfoCircleIcon } from '@rhythm/svgs'
import { Tooltip } from '@rhythm/tooltips'
import { Helmet } from 'react-helmet'

const Title = styled(H5)`
  padding: 1.4rem 0rem 1rem 2.25rem;
  color: ${getColor('greyDarkest')};
  line-height: 1.15rem;
  margin: 0;
  box-shadow: inset 0 -1px 0 0 ${getColor('greyLightest')};
`

const YourInfoTitle = styled.div`
  color: ${getColor('greyDark')};
  font-size: 18px;
  font-weight: ${getFontWeight('semibold')};
  line-height: 24px;
`

const Wrapper = styled.div`
  margin-left: 36px;
  max-width: 339px;
  margin-right: 10px;
`

const Label = styled.div<{ required: boolean }>`
  color: ${getColor('greyDark')};
  font-size: 14px;
  font-weight: ${getFontWeight('semibold')};
  line-height: 24px;
  margin: 20px 0 5px;

  ${({ required }) => Asterisk(required)}
`

const ResetPasswordTitle = styled.div`
  color: ${getColor('greyDark')};
  font-size: 18px;
  font-weight: ${getFontWeight('semibold')};
  line-height: 24px;
  padding-top: 32px;
  padding-bottom: 18px;
`

const InfoIcon = styled(InfoCircleIcon)`
  color: ${getColor('greyLight')};
  height: 15px;
  min-width: 15px;
  margin: 5px 0 0 5px;

  &:hover {
    color: ${getColor('grey')};
  }
`

const InfoWrapper = styled(FlexContainer)`
  padding-top: 20px;
`

const ResetPasswordWrapper = styled.div<{ isDisabled: boolean }>`
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`

const ProfilePage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const currentUser = useSelector(userCurrentUserSelector) || ({} as IUser)
  const currentUserError = useSelector(userErrorSelector)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [fieldValidationError, setFieldValidationError] = useState(false)
  const [isSalesLoftUser, setSalesLoftUser] = useState<boolean>(false)
  const userFetching = useSelector(userFetchingSelector)

  useEffect(() => {
    if (currentUser.name) {
      setFirstName(currentUser.first_name)
      setLastName(currentUser.last_name)
    }
    dispatch(
      currentUserActions.get({
        successFunc: (isOauthInSalesloft: boolean) => {
          setSalesLoftUser(isOauthInSalesloft)
        },
      })
    )
  }, [currentUser])

  useEffect(() => {
    if (currentUserError) {
      history.push('/cadenceList')
    }
  }, [currentUserError])

  const renderNotification = (type: 'info' | 'confirm' | 'warning' | 'danger', body: string) => {
    dispatch(
      messageActions.update({
        message: {
          type,
          body,
        },
      })
    )
  }

  const handleSaveChanges = () => {
    const trimmedFirstName = firstName.trim()
    const trimmedLastName = lastName.trim()
    if (trimmedFirstName.length === 0 || trimmedLastName.length === 0) {
      renderNotification('danger', 'All fields required')
      setFieldValidationError(true)
      return
    }
    dispatch(
      currentUserActions.update({
        data: { firstName: trimmedFirstName, lastName: trimmedLastName },
        successFunc: () => {
          renderNotification('confirm', 'Changes saved')
        },
      })
    )
  }

  const handleResetPassword = () => {
    dispatch(
      forgotPasswordActions.request({
        value: { email: currentUser.email },
        successFunc: () => {
          renderNotification(
            'info',
            `
            Check your email for a link to reset your password. If it doesn’t 
            appear within a few minutes, check your spam
          `
          )
        },
      })
    )
  }

  if (userFetching) {
    return <Loader />
  }

  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <ContainerWithMenu menu={UserMenu}>
        <Title>Profile</Title>
        <Wrapper>
          <InfoWrapper>
            <YourInfoTitle>Your info</YourInfoTitle>
            {isSalesLoftUser && (
              <Tooltip
                maxWidth="100%"
                isInline
                offset="0"
                truncate={false}
                title="You cannot edit your account data while logged in via SalesLoft"
              >
                <InfoIcon aria-label="profile info icon" />
              </Tooltip>
            )}
          </InfoWrapper>
          <Label required={!isSalesLoftUser}>First Name</Label>
          <TextField
            disabled={isSalesLoftUser}
            value={firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
            maxLength={60}
            width="100%"
            hasError={fieldValidationError && firstName.length === 0}
          />
          <Label required={!isSalesLoftUser}>Last Name</Label>
          <TextField
            disabled={isSalesLoftUser}
            value={lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
            maxLength={60}
            width="100%"
            hasError={fieldValidationError && lastName.length === 0}
          />
          <Label required={false}>Email</Label>
          <TextField value={currentUser.email} disabled width="100%" />
          <ResetPasswordWrapper isDisabled={isSalesLoftUser}>
            <ResetPasswordTitle>Password Reset</ResetPasswordTitle>
          </ResetPasswordWrapper>
          <Button disabled={isSalesLoftUser} onClick={handleResetPassword}>
            Reset Password
          </Button>
        </Wrapper>
      </ContainerWithMenu>
      <FooterButtons
        handleBack={() => history.go(-1)}
        handleProceed={handleSaveChanges}
        isProcceedDisabled={
          firstName === currentUser.first_name && lastName === currentUser.last_name
        }
        proceedName="Save Changes"
      />
    </>
  )
}

export default ProfilePage
