import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, useParams } from 'react-router-dom'
import { useCadence } from 'utils'
import { cadenceItemSelector } from 'selectors'
import { Loader } from 'components'
import { CadenceOverview, DurationAndIntensity, Motions, Persona, Questions } from 'containers'
import { cadenceActions } from 'actions'
import { Helmet } from 'react-helmet'

const Cadence = (props: RouteComponentProps) => {
  const { getCadence } = useCadence()
  const dispatch = useDispatch()
  const { id: cadenceId } = useParams<{ id: string }>()
  const cadence = useSelector(cadenceItemSelector)

  useEffect(() => {
    getCadence(cadenceId)
    return () => {
      dispatch(cadenceActions.success(null))
    }
  }, [cadenceId])

  if (!cadence) {
    return <Loader />
  }

  const renderView = () => {
    switch (cadence.state) {
      case 'built':
        return <CadenceOverview {...props} cadence={cadence} />
      case 'persona':
        return <Persona cadence={cadence} />
      case 'intensity':
        return <DurationAndIntensity {...props} cadence={cadence} />
      case 'questions':
        return <Questions {...props} cadenceId={cadence.id} />
      default:
        return <Motions {...props} cadence={cadence} />
    }
  }
  return (
    <>
      <Helmet>
        <title>{cadence.state === 'built' ? 'Overview' : 'Steps'}</title>
      </Helmet>
      {renderView()}
    </>
  )
}

export default Cadence
