import { all, put, takeLatest } from 'redux-saga/effects'
import { customAxios, customAxiosCall } from 'utils'
import {
  MOTIONS,
  OBJECTIVES,
  DURATION_AND_INTENSITY,
  motionsActions,
  messageActions,
  durationAndIntensityActions,
} from 'actions'
import { IAction, IActionDefaultPayload } from 'types'

const endpoints = {
  motionsList: '/api/v1/objectives',
  motions: 'api/v1/motions',
}

const messageBody = 'Something went wrong'

const errorMessageAction = messageActions.update({
  message: {
    type: 'danger',
    body: messageBody,
  },
})

export function* fetchMotions() {
  try {
    const motions = yield customAxiosCall(customAxios.get, endpoints.motionsList)
    yield put(motionsActions.update(motions.data))
  } catch ({ response: { statusText } }) {
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: statusText,
        },
      })
    )
  }
}

export function* getMotions({
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<string>, never>) {
  try {
    const { data } = yield customAxiosCall(customAxios.get, endpoints.motions)
    yield put(motionsActions.success(data))
    if (successFunc) {
      successFunc(data)
    }
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: errors || statusText,
        },
      })
    )
    yield put(motionsActions.failure(errors || statusText))
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* fetchObjectiveWithMotion({
  payload,
}: IAction<{ name: string; searchMotions: string }, Record<string, unknown>>) {
  try {
    const motions = yield customAxiosCall(customAxios.get, endpoints.motionsList, {
      params: {
        filter: {
          name: payload.name,
          search: payload.searchMotions,
        },
      },
    })
    yield put(motionsActions.update(motions.data))
  } catch (e) {
    yield put(errorMessageAction)
  }
}

export function* fetchMotionById({ payload }: IAction<{ id: number }, Record<string, unknown>>) {
  try {
    const endpoint = `${endpoints.motions}/${payload.id}`
    const motions = yield customAxiosCall(customAxios.get, endpoint)
    yield put(durationAndIntensityActions.update(motions.data))
  } catch (e) {
    yield put(errorMessageAction)
  }
}

function* motionsSagas() {
  yield all([
    takeLatest(MOTIONS.REQUEST, fetchMotions),
    takeLatest(OBJECTIVES.REQUEST, fetchObjectiveWithMotion),
    takeLatest(MOTIONS.GET, getMotions),
    takeLatest(DURATION_AND_INTENSITY.REQUEST, fetchMotionById),
  ])
}

export default motionsSagas
