import { IAppMessageState, IAppModalState } from 'types'
import { createAction } from 'utils'
import { GLOBAL_MESSAGE, GLOBAL_MODAL } from './types'

const messageActions = {
  update: (data: IAppMessageState) => createAction(GLOBAL_MESSAGE.UPDATE, data),
  delete: () => createAction(GLOBAL_MESSAGE.DELETE),
}

const modalActions = {
  update: (data: IAppModalState) => createAction(GLOBAL_MODAL.UPDATE, data),
  delete: () => createAction(GLOBAL_MODAL.DELETE),
}

export { modalActions, messageActions }
