import React from 'react'
import styled from 'styled-components'
import { EnvelopeIcon, PhoneIcon, LinkedinLogoImage } from '@rhythm/svgs'
import { getColor, getTransparentizedColor, ThemeColors } from '@rhythm/theme'

const ColorIcon = styled.div<{ background?: ThemeColors; color?: ThemeColors }>`
  background: ${({ background }) =>
    background ? getTransparentizedColor(background, 0.9) : 'auto'};
  padding: 4px;
  border-radius: 100px;
  height: 32px;
  font-size: 16px;
  img {
    height: 1em;
    width: 1em;
    margin: 5px;
  }
  svg {
    color: ${({ color }) => (color ? getColor(color) : 'auto')};
    margin: 4px;
  }
`

const ActionIcon = ({ type }: { type: string }) => {
  const lowerCaseType = type.toLowerCase()
  if (lowerCaseType.includes('email')) {
    return (
      <ColorIcon background="purple" color="purple">
        <EnvelopeIcon aria-label="email icon" />
      </ColorIcon>
    )
  }
  if (lowerCaseType.includes('phone') || lowerCaseType.includes('call')) {
    return (
      <ColorIcon background="teal" color="teal">
        <PhoneIcon aria-label="phone icon" />
      </ColorIcon>
    )
  }
  return (
    <ColorIcon background="grey">
      <LinkedinLogoImage aria-label="social icon" />
    </ColorIcon>
  )
}

export default ActionIcon
