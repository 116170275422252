import { ICadenceQuestion, ICadenceQuestionAnswer, TContainer } from 'types'
import React, { ChangeEvent, useState, useEffect } from 'react'
import styled from 'styled-components'
import { FlexContainer } from 'static'
import { getColor, getFontWeight } from '@rhythm/theme'
import { H5 } from '@rhythm/typography'
import { IconButton, Button } from '@rhythm/buttons'
import { EmptyState } from '@rhythm/empty-state'
import { AddCircleIcon, OpenNewIcon, SubtractCircleIcon, EmailsEmptyImage } from '@rhythm/svgs'
import { useMap } from 'utils'
import { Tooltip } from '@rhythm/tooltips'
import { Radio, Checkbox, SearchInput } from '@rhythm/inputs'
import { cadenceQuestionAnswersActions, messageActions, modalActions } from 'actions'
import { CadenceAnswerAddModal } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { Skeleton } from '@rhythm/loading'
import {
  cadenceQuestionsFetchingSelector,
  cadenceQuestionsAnswersSelector,
  cadenceQuestionsLibAnswersSelector,
  cadenceFetchingSelector,
} from 'selectors'

interface IProps {
  currentQuestion: ICadenceQuestion
  questionIndex: number
  questionsAmount: number
  cadenceId: number
}

const Title = styled(H5)`
  margin: 0;
  color: ${getColor('greyDarkest')};
`

const Header = styled(FlexContainer)`
  padding: 1.4rem 2.25rem 1rem 2.25rem;
  border: 0.1rem solid ${getColor('greyLightest')};
`

const MarginFlexContainer = styled(FlexContainer)`
  margin-top: 4px;
`

const Container = styled(FlexContainer)`
  margin: 0rem 2.25rem;
  width: 60%;
  .textContainer {
    overflow: hidden;
    & > div {
      display: flex;
      width: 100%;
    }
  }
  .option {
    flex: 1;
    width: 100%;
    overflow: hidden;
  }
`

const AnswerContainer = styled(FlexContainer)`
  border: 0.1rem solid ${getColor('greyLightest')};
  border-bottom: none;
  width: 45%;
  overflow: hidden;
  .input {
    border-bottom: 0.1rem solid ${getColor('greyLightest')};
    padding: 0.875rem 1.375rem 0.75rem 1.125rem;
    input {
      min-width: auto;
    }
  }
  .answers {
    position: relative;
    overflow-y: auto;
    padding: 0.25rem 0rem;
    .empty {
      padding: 0rem 1.125rem;
    }
    .list {
      position: absolute;
      width: 100%;
    }
  }
  .answer {
    display: flex;
    margin: 4px 0;
  }
`

const AnswerLink = styled.a`
  width: 0.875rem;
  height: 0.875rem;
  display: block;
  margin-left: 0.5rem;
  color: inherit;
  text-decoration: inherit;
`

const SelectedContainer = styled(FlexContainer)`
  border-radius: 0 0.25rem 0 0;
  background-color: ${getColor('greyLightest')};
  padding: 1.375rem;
  width: 65%;
  overflow: hidden;
  padding-left: 1.5rem;
  .title {
    margin-bottom: 1.25rem;
  }
  .answers {
    position: relative;
    overflow-y: auto;
    padding: 0.25rem 0rem;
    .list {
      position: absolute;
      width: 100%;
      .item {
        border-radius: 2px;
        background-color: ${getColor('white')};
        box-shadow: 0 2px 4px 1px ${getColor('greyLight')}7F;
        width: 100%;
        padding-left: 1rem;
        color: ${getColor('greyDarkest')};
        font-size: 0.875rem;
        margin: 0.125rem 0rem;
      }
    }
  }
`

const Text = styled.span`
  color: ${getColor('greyDark')};
`

const GreyText = styled.span`
  color: ${getColor('grey')};
`

const TextBold = styled(Text)`
  font-weight: ${getFontWeight('semibold')};
`

const LittleText = styled(Text)`
  font-size: 0.875rem;
`

const ShortText = styled(LittleText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const LoadingContainer = styled(FlexContainer)`
  & > div {
    margin-bottom: 0.5rem;
  }
`

const Description = styled(Text)`
  margin-top: 1.625rem;
  margin-bottom: 1.75rem;
`

const EmptyStateText = styled.span`
  margin-top: 1.25rem;
  color: ${getColor('greyLight')};
  font-size: 0.875rem;
`

const Question = ({ currentQuestion, questionIndex, questionsAmount, cadenceId }: IProps) => {
  const dispatch = useDispatch()
  const [answerSearch, setAnswerSearch] = useState('')
  const [answerFilter, setAnswerFilter] = useState('')
  const answers = useSelector(cadenceQuestionsAnswersSelector)
  const libAnswers = useSelector(cadenceQuestionsLibAnswersSelector)
  const cadenceQuestionsFetching = useSelector(cadenceQuestionsFetchingSelector)
  const cadenceFetching = useSelector(cadenceFetchingSelector)
  const isMultiple = currentQuestion.question_type === 'multiple'
  const [
    selectedList,
    { set: setSelectedList, unset: unsetSelectedList, clear: clearSelectedList },
  ] = useMap<number, ICadenceQuestionAnswer>()
  const emptyListRender = (isSelectedList?: boolean) => {
    if (!answerFilter || isSelectedList) {
      return (
        <EmptyStateText className="empty">
          {!isSelectedList ? (
            <>
              Your Answer Library is empty.
              <br />
              Start filling it by adding answers.
            </>
          ) : (
            'Select Answer from Library or add custom Answer.'
          )}
        </EmptyStateText>
      )
    }
    return (
      <EmptyState
        title="No matches"
        text="There is nothing in your Library with this name."
        thumbnail={<EmailsEmptyImage width="8rem" height="7rem" />}
      />
    )
  }

  const getAnswers = ({
    resolve,
    reject,
    isLibAnswers,
  }: {
    resolve?: (value: unknown) => void
    reject?: (error: unknown) => void
    isLibAnswers?: boolean
  }) =>
    dispatch(
      cadenceQuestionAnswersActions.get({
        data: {
          cadenceId,
          questionId: currentQuestion.id,
          isLibAnswers,
        },
        successFunc: (answersArray) => resolve && resolve(answersArray),
        errorFunc: (error) => reject && reject(error),
      })
    )

  const deleteAnswer = (answerId: number) =>
    new Promise((resolve, reject) => {
      const deletedAnswer = selectedList.get(answerId)
      let deletedAnswerId = answerId
      if (deletedAnswer && answerId === deletedAnswer.id) {
        selectedList.forEach((answer, id) => {
          if (answer.id === answerId && answerId !== id) {
            deletedAnswerId = id
          }
        })
      }
      dispatch(
        cadenceQuestionAnswersActions.delete({
          data: {
            cadenceId,
            questionId: currentQuestion.id,
            answerId: deletedAnswerId,
          },
          successFunc: () => {
            if (deletedAnswer) {
              unsetSelectedList(deletedAnswer.id)
            }
            unsetSelectedList(deletedAnswerId)
            getAnswers({})
            resolve(true)
          },
          errorFunc: (error) => reject(error),
        })
      )
    })

  const addedAnswer = async (
    answer: {
      name: string
      link: string | null
      id?: number
    },
    saveToLib?: boolean
  ) => {
    try {
      let saveLib: ICadenceQuestionAnswer | null = null
      if (saveToLib) {
        saveLib = await new Promise<ICadenceQuestionAnswer>((resolve, reject) => {
          dispatch(
            cadenceQuestionAnswersActions.create({
              data: {
                cadenceId,
                questionId: currentQuestion.id,
                answer,
                isCreateLib: true,
              },
              successFunc: (question) => resolve(question),
              errorFunc: (error) => reject(error),
            })
          )
        })
        getAnswers({ isLibAnswers: true })
      }
      if (!isMultiple && answers && answers[0]) {
        await deleteAnswer(answers[0].id)
        clearSelectedList()
      }
      const createdAnswer = await new Promise<ICadenceQuestionAnswer>((resolve, reject) => {
        dispatch(
          cadenceQuestionAnswersActions.create({
            data: {
              cadenceId,
              questionId: currentQuestion.id,
              answer,
            },
            successFunc: (question) => resolve(question),
            errorFunc: (error) => reject(error),
          })
        )
      })
      getAnswers({})
      const answerId = saveLib?.id || answer.id
      if (answerId) {
        setSelectedList(answerId, saveLib || (answer as ICadenceQuestionAnswer))
        setSelectedList(createdAnswer.id, saveLib || (answer as ICadenceQuestionAnswer))
      }
      return null
    } catch (error) {
      const errorsList: string[] = []
      if (typeof error === 'object') {
        if (error === null) return errorsList
        Object.values(error).forEach((value) => {
          if (Array.isArray(value)) {
            errorsList.push(...value)
          }
        })
      }
      let errorMessage = errorsList[0]
      errorsList.forEach((err: string) => {
        if (err.indexOf('unique') !== -1) {
          errorMessage = 'This name is already taken'
        }
      })
      if (errorMessage !== 'This name is already taken') {
        if (errorMessage === 'Question can have at most 20 answers, but 21 were provided') {
          errorMessage =
            'Maximum answer quantity is 20. Please remove an extra answer before adding a new one'
        }
        dispatch(
          messageActions.update({
            message: {
              type: 'warning',
              body: errorMessage,
              duration: 5000,
            },
          })
        )
      }
      throw new Error(errorMessage)
      return errorMessage
    }
  }

  const openModal = (answer?: ICadenceQuestionAnswer) => () => {
    let defaultAnswer: {
      name: string
      link: string | null
    } = {
      name: '',
      link: '',
    }
    if (answer) {
      defaultAnswer = answer
    } else if (!isMultiple && answers && answers[0]) {
      defaultAnswer = {
        name: answers[0].name,
        link: answers[0].link,
      }
    }
    dispatch(
      modalActions.update({
        modal: {
          title: !isMultiple && !!answers && answers.length !== 0 ? 'Replace Answer' : 'Add Answer',
          width: '32rem',
          onClose: () => {},
          body: ({
            body,
            footer,
            onClose,
          }: {
            body: TContainer
            footer: TContainer
            onClose: () => void
          }) => (
            <CadenceAnswerAddModal
              body={body}
              footer={footer}
              onClose={onClose}
              onApply={addedAnswer}
              isMultiple={isMultiple}
              isReplace={!isMultiple && !!answers && answers.length !== 0}
              answer={defaultAnswer}
            />
          ),
        },
      })
    )
  }

  const answerContentRender = (answer: ICadenceQuestionAnswer) => (
    <FlexContainer flexSize={1} className="option" justifyContent="flex-start" alignItems="center">
      <FlexContainer className="textContainer">
        <Tooltip maxWidth="30rem" position="bottomRight" title={answer.name}>
          <ShortText className="text">{answer.name}</ShortText>
        </Tooltip>
      </FlexContainer>
      {answer.link ? (
        <Tooltip maxWidth="30rem" position="bottomRight" title={answer.link}>
          <AnswerLink href={answer.link} target="_blank" rel="noreferrer noopener">
            <OpenNewIcon fontSize="0.875rem" />
          </AnswerLink>
        </Tooltip>
      ) : null}
    </FlexContainer>
  )

  const renderSelectedList = () => {
    if (!answers || answers.length === 0) {
      return answers ? (
        emptyListRender(true)
      ) : (
        <LoadingContainer column>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </LoadingContainer>
      )
    }
    return answers.map((answer) => (
      <FlexContainer
        key={answer.id}
        className="item overflow"
        justifyContent="space-between"
        alignItems="center"
      >
        {answerContentRender(answer)}
        <Tooltip maxWidth="100%" isInline title="Delete">
          <IconButton
            appearance="ghost"
            data-testid={`btnDeleteAnswer${answer.id}`}
            variant="neutral"
            onClick={() => deleteAnswer(answer.id).catch(() => {})}
            disabled={cadenceFetching || cadenceQuestionsFetching}
            icon={<SubtractCircleIcon />}
            aria-label="subtract circle icon button"
          />
        </Tooltip>
      </FlexContainer>
    ))
  }

  const onChangeSearch = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
    setAnswerSearch(value)

  const handleAnswer = (answer: ICadenceQuestionAnswer) => async ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!checked) {
        await deleteAnswer(answer.id)
      } else {
        await addedAnswer({
          name: answer.name,
          link: answer.link,
          id: answer.id,
        })
      }
    } catch ({ message }: any) {
      let errorMessage = message as string
      if (errorMessage === 'This name is already taken') {
        errorMessage = 'The answer with this name is already in Selected.' as const
      }
      const type = 'warning' as const
      dispatch(
        messageActions.update({
          message: {
            type,
            body: errorMessage,
            duration: 5000,
          },
        })
      )
    }
  }

  const handleEnter = ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      setAnswerFilter(answerSearch)
    }
  }

  const answerRender = (answer: ICadenceQuestionAnswer) => {
    const isSelected = selectedList.get(answer.id)?.id === answer.id
    return !isMultiple ? (
      <Radio
        data-testid={`rdAnswer${answer.id}`}
        key={answer.id}
        checked={isSelected}
        disabled={cadenceFetching || cadenceQuestionsFetching}
        onChange={answers && answers.length === 0 ? handleAnswer(answer) : openModal(answer)}
        className="answer"
      >
        {answerContentRender(answer)}
      </Radio>
    ) : (
      <FlexContainer key={answer.id} className={`${isSelected ? 'checked' : ''} answer`}>
        <Checkbox
          data-testid={`ckbAnswer${answer.id}`}
          checked={isSelected}
          onChange={handleAnswer(answer)}
          disabled={cadenceFetching || cadenceQuestionsFetching}
        >
          {answerContentRender(answer)}
        </Checkbox>
      </FlexContainer>
    )
  }

  const renderAnswersList = () => {
    if (!libAnswers) {
      return (
        <LoadingContainer column>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </LoadingContainer>
      )
    }
    const answersList = libAnswers.reduce((answersArray, answer) => {
      if (!answerFilter || (answerFilter && answer.name.indexOf(answerFilter) !== -1)) {
        answersArray.push(answerRender(answer))
      }
      return answersArray
    }, new Array<JSX.Element>())
    return answersList.length === 0 ? emptyListRender() : answersList
  }

  useEffect(() => {
    getAnswers({})
    getAnswers({ isLibAnswers: true })
  }, [currentQuestion.id])

  return (
    <>
      <Header justifyContent="space-between" alignItems="center">
        <Title>{currentQuestion.parameter.title}</Title>
        <LittleText>
          Question {questionIndex}/{questionsAmount}
        </LittleText>
      </Header>
      <Container column flexSize={1}>
        <Description>{currentQuestion.parameter.description}</Description>
        <FlexContainer justifyContent="space-between" alignItems="center">
          <TextBold>Select from Library</TextBold>
          <Button onClick={openModal()} aria-label="plus circle icon" iconLeft={<AddCircleIcon />}>
            Add Custom Answer
          </Button>
        </FlexContainer>
        <MarginFlexContainer flexSize={1}>
          <AnswerContainer column>
            <FlexContainer className="input">
              <SearchInput
                value={answerSearch}
                onChange={onChangeSearch}
                onClear={() => {
                  setAnswerSearch('')
                  setAnswerFilter('')
                }}
                onClickSearch={() => setAnswerFilter(answerSearch)}
                onKeyDown={handleEnter}
                placeholder="Search Answer"
                showClearButton={!!answerFilter}
                maxLength="100"
                width="100%"
              />
            </FlexContainer>
            <FlexContainer flexSize={1} column className="answers">
              <FlexContainer column className="list" data-testid="lstLibAnswers">
                {renderAnswersList()}
              </FlexContainer>
            </FlexContainer>
          </AnswerContainer>
          <SelectedContainer flexSize={1} column>
            <FlexContainer justifyContent="space-between" className="title">
              <LittleText>Selected {isMultiple ? <GreyText>(Max 20)</GreyText> : ''}</LittleText>
              {isMultiple && (
                <LittleText>
                  {answers ? answers.length : 0}
                  <GreyText>/20</GreyText>
                </LittleText>
              )}
            </FlexContainer>
            <FlexContainer flexSize={1} className="answers" column data-testid="lstSelectedAnswers">
              <FlexContainer column className="list">
                {renderSelectedList()}
              </FlexContainer>
            </FlexContainer>
          </SelectedContainer>
        </MarginFlexContainer>
      </Container>
    </>
  )
}

export default Question
