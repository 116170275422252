import React from 'react'
import styled from 'styled-components'
import { getColor, getFontWeight } from '@rhythm/theme'
import { FlexContainer } from 'static'
import { ICreateCallSocialStep } from 'types'

import StepName from './StepName'

const BoldTitle = styled.span`
  font-weight: ${getFontWeight('semibold')};
  color: ${getColor('greyDarkest')};
  font-size: 14px;
`

const InstructionsContainer = styled(FlexContainer)`
  flex-direction: column;
  border-top: 1px solid ${getColor('greyLightest')};
  padding-top: 8px;
  padding-left: 17px;
`

const InstructionsInput = styled.textarea`
  border: none;
  width: auto;
  padding: 0;
  margin-top: 5px;
  padding-right: 15px;
  padding-bottom: 15px;
  height: 192px;
  color: ${getColor('greyDarkest')};
  font-size: 14px;
  resize: none;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${getColor('grey')};
  }
`

const CreateCallSocialStep = ({
  name,
  type,
  instructions,
  handleChangeName,
  handleChangeInstructions,
}: ICreateCallSocialStep) => (
  <>
    <StepName name={name} type={type} width="550px" handleChangeNameInput={handleChangeName} />
    <InstructionsContainer>
      <BoldTitle>Instructions</BoldTitle>
      <InstructionsInput
        placeholder="Enter your instructions"
        value={instructions}
        onChange={handleChangeInstructions}
      />
    </InstructionsContainer>
  </>
)

export default CreateCallSocialStep
