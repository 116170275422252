import { all, put, takeLatest } from 'redux-saga/effects'
import { customAxios, customAxiosCall } from 'utils'
import {
  CADENCE_STEPS,
  CADENCE_STEP,
  messageActions,
  cadenceStepsActions,
  cadenceStepActions,
} from 'actions'
import { IAction, IActionDefaultPayload } from 'types'

const endpoints = {
  cadenceSteps: (id: number) => `/api/v1/cadences/${id}/steps`,
  cadenceStepUpdateOrder: (id: number, stepId: number, day: number, order: number) =>
    `/api/v1/cadences/${id}/steps/${stepId}?publish_at=${day}&order=${order}`,
  cadenceStepUpdate: (id: number, stepId: number) => `/api/v1/cadences/${id}/steps/${stepId}`,
  getStepPreview: (cadenceId: number) => `/api/v1/cadences/${cadenceId}/steps/preview`,
}

export function* getCadenceSteps({
  body: { id },
  successFunc,
  errorFunc,
}: IAction<IActionDefaultPayload<string>, { id: number }>) {
  try {
    const { data } = yield customAxiosCall(customAxios.get, endpoints.cadenceSteps(id))
    yield put(cadenceStepsActions.success(data))
    successFunc?.()
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: statusText,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* updateCadenceSteps({
  body: { id, stepId, day, order },
  successFunc,
  errorFunc,
}: IAction<
  IActionDefaultPayload<string>,
  { id: number; stepId: number; day: number; order: number }
>) {
  try {
    yield customAxiosCall(customAxios.put, endpoints.cadenceStepUpdateOrder(id, stepId, day, order))
    successFunc?.()
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: statusText,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* addCadenceStep({
  body: { id, name, stepable_type, description, subject, body, publish_at },
  successFunc,
  errorFunc,
}: IAction<
  IActionDefaultPayload<string>,
  {
    id: number
    name: string
    stepable_type: string
    body?: string
    publish_at: number
    description?: string
    order: number
    subject?: string
  }
>) {
  try {
    yield customAxiosCall(customAxios.post, endpoints.cadenceSteps(id), {
      name,
      stepable_type,
      description,
      subject,
      body,
      publish_at,
    })
    yield put(cadenceStepActions.success())
    successFunc?.()
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: errors || statusText,
        },
      })
    )
    yield put(cadenceStepActions.failure(errors || statusText))
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* updateCadenceStep({
  body: { id, stepId, name, stepable_type, description, subject, body, publish_at, order },
  successFunc,
  errorFunc,
}: IAction<
  IActionDefaultPayload<string>,
  {
    id: number
    stepId: number
    name: string
    stepable_type: string
    body?: string
    publish_at: number
    description?: string
    subject?: string
    order?: number
  }
>) {
  try {
    yield customAxiosCall(customAxios.put, endpoints.cadenceStepUpdate(id, stepId), {
      name,
      stepId,
      stepable_type,
      description,
      subject,
      body,
      publish_at,
      order,
    })
    yield put(cadenceStepActions.success())
    successFunc?.()
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: statusText,
        },
      })
    )
    yield put(cadenceStepActions.failure(errors || statusText))
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* getCadenceStep({
  body: { cadenceId, body },
  successFunc,
  errorFunc,
}: IAction<
  IActionDefaultPayload<string>,
  {
    cadenceId: number
    body: string
  }
>) {
  try {
    const { data } = yield customAxiosCall(customAxios.get, endpoints.getStepPreview(cadenceId), {
      params: {
        distribution: 'inline',
        body,
      },
    })
    successFunc?.(data.body)
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: statusText,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

export function* deleteCadenceStep({
  body: { cadenceId, stepId },
  successFunc,
  errorFunc,
}: IAction<
  IActionDefaultPayload<string>,
  {
    cadenceId: number
    stepId: number
  }
>) {
  try {
    yield customAxiosCall(customAxios.delete, endpoints.cadenceStepUpdate(cadenceId, stepId))
    successFunc?.()
  } catch ({
    response: {
      data: { errors },
      statusText,
      status,
    },
  }) {
    yield put(
      messageActions.update({
        message: {
          type: 'danger',
          body: errors || statusText,
        },
      })
    )
    if (errorFunc) {
      errorFunc(errors || statusText)
    }
  }
}

function* cadenceStepsSagas() {
  yield all([
    takeLatest(CADENCE_STEPS.REQUEST, getCadenceSteps),
    takeLatest(CADENCE_STEPS.UPDATE, updateCadenceSteps),
    takeLatest(CADENCE_STEP.REQUEST, addCadenceStep),
    takeLatest(CADENCE_STEP.UPDATE, updateCadenceStep),
    takeLatest(CADENCE_STEP.GET, getCadenceStep),
    takeLatest(CADENCE_STEP.DELETE, deleteCadenceStep),
  ])
}

export default cadenceStepsSagas
