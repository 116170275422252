import { messageActions } from 'actions'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { messageDetailsSelector } from 'selectors'
import { Toast } from '@rhythm/notifications'
import styled from 'styled-components'
import { debounce } from 'utils'
import { FlexContainer } from 'static'

const MessageContainer = styled(FlexContainer)`
  position: fixed;
  left: 50%;
  top: 5.75rem;
  transform: translate(-50%, -50%);
  width: fit-content;
`

const MessageHandler = () => {
  const dispatch = useDispatch()
  const message = useSelector(messageDetailsSelector)
  const timerToDeleteMessage = useCallback(
    debounce(() => {
      dispatch(messageActions.delete())
    }, message?.duration || 3000),
    [message]
  )

  useEffect(() => {
    if (message) {
      timerToDeleteMessage()
    }
  }, [message])

  return message ? (
    <MessageContainer>
      <Toast
        title={message.title || ''}
        subtitle={message.body || ''}
        actionText={message.actionText}
        actionCallback={message.actionCallback}
        variant={message.type}
        closeToastCallback={() => dispatch(messageActions.delete())}
      />
    </MessageContainer>
  ) : null
}

export default MessageHandler
